import React from 'react';

import { Flex, HStack, Text } from '@chakra-ui/react';
import { SparklesIcon } from '@heroicons/react/24/solid';
import { Logo } from '@meilleursbiens/ui';

export default function AIPromptHeaderComponent() {
  return (
    <Flex justify={'space-between'} align={'center'} px={2} py={2} boxShadow={'base'}>
      <HStack gap={0}>
        <Logo height={'15px'} />
      </HStack>
      <HStack gap={0}>
        <SparklesIcon strokeWidth={2} color={'#6c2dff'} width={20} />
        <Text color="#6c2dff" fontSize={'12px'} fontWeight={'bold'}>
          TypeFlow
        </Text>
        <Text color="muted" fontSize={'10px'} ml={1} fontWeight={'bold'}>
          BETA
        </Text>
      </HStack>
    </Flex>
  );
}
