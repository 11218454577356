import React from 'react';

import { lazyRetry } from '@meilleursbiens/utils';

const HarpAdminSalariesScreen = React.lazy(() =>
  lazyRetry(() => import('../screens/MBHarp/Admin/Salaries/MBHarpAdminSalariesScreen'), 'MBHarpAdminSalariesScreen')
);

const MBHarpSalariesScreen = React.lazy(() =>
  lazyRetry(() => import('../screens/MBHarp/Account/Salaries/MBHarpSalariesScreen'), 'MBHarpSalariesScreen')
);

const MBHarpLeavesScreen = React.lazy(() =>
  lazyRetry(() => import('../screens/MBHarp/Account/Leaves/MBHarpLeavesScreen'), 'MBHarpLeavesScreen')
);

export default {
  HARP_ADMIN_SALARIES_SCREEN: {
    url: '/admin/harp/salaries',
    component: HarpAdminSalariesScreen,
    routeType: 'admin',
  },
  MB_HARP_SALARIES_SCREEN: {
    url: '/harp/salaries',
    component: MBHarpSalariesScreen,
    routeType: 'private.validated',
  },
  MB_HARP_LEAVES_SCREEN: {
    url: '/harp/leaves',
    component: MBHarpLeavesScreen,
    routeType: 'private.validated',
  },
};
