import { MBProUser, MBProUserNotes } from '@meilleursbiens/types';

import MBApi from '../../../../MBApi';

export default class MBProAdminUserAPI {
  static toggleDemo() {
    return MBApi.url('/v1/mbpro/admin/agent/demo').needAuth().post<boolean>();
  }

  static getUser(id: number) {
    return MBApi.url(`/v1/mbpro/admin/agent/${id}`).needAuth().get<{
      user: MBProUser;
    }>();
  }

  static getStatForUser(id: number) {
    return MBApi.url(`/v1/mbpro/admin/agent/stats/${id}`).needAuth().get<{
      average_payments: number;
      total_payments: number;
      ca_transactions: number;
      lifetime: string;
    }>();
  }

  static listNotes(id: number) {
    return MBApi.url('/v1/mbpro/admin/agent/notes/' + id)
      .needAuth()
      .get<{
        notes: MBProUserNotes[];
      }>();
  }

  static switchAgencyMode(id: number) {
    return MBApi.url('/v1/mbpro/admin/agent/agency-mode/' + id)
      .needAuth()
      .patch();
  }
}
