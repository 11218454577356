import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

import { sidebarAnatomy } from '../../anatomy';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(sidebarAnatomy.keys);

const baseStyle = definePartsStyle((props) => {
  const { colorScheme: c } = props;

  return {
    container: {
      position: 'relative',
      zIndex: '5',
      bg: c ? `${c}.500` : 'rgba(255, 255, 255, 0.7)',
      bgOpacity: 0.6,
      backdropFilter: 'blur(10px)',
      display: 'flex',
      flexDirection: 'row',
      px: 4,
      maxH: '58px',
      alignItems: 'center',
      borderBottomWidth: 1,
      borderColor: 'gray.100',
      _dark: {
        bg: c ? `${c}.500` : 'rgba(30, 32, 34, 0.7)',
        borderColor: `gray.700`,
      },
      overflowY: 'none',
    },
    overlay: {
      bg: 'blackAlpha.200',
    },
  };
});

const variantDefault = definePartsStyle((props) => {
  return {
    container: {
      width: '100%',
      maxWidth: '100vw',
      minWidth: '220px',
      py: 3,
      '&[data-collapsible]': {
        pt: 14,
      },
    },
    inner: {
      px: 3,
    },
  };
});

export const toolbarTheme = defineMultiStyleConfig({
  defaultProps: {
    variant: 'default',
  },
  baseStyle,
  variants: {
    default: variantDefault,
  },
});
