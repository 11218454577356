import React, { useEffect, useState } from 'react';

import { MBCoreTeamAPI } from '@meilleursbiens/api';
import { MBProTeam } from '@meilleursbiens/types';

import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Checkbox,
  Flex, HStack,
  Image,
  Skeleton,
  Slider, SliderFilledTrack, SliderThumb, SliderTrack,
  Spacer,
  Text
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import { ToastUtils } from '@meilleursbiens/utils';
import { Button, FileInput, Logo, ModalConfirm, Section, TextInput } from '@meilleursbiens/ui';
import { ArrowPathRoundedSquareIcon } from '@heroicons/react/24/solid';
import { CheckBadgeIcon, PhotoIcon } from '@heroicons/react/24/outline';
import { ViewIcon } from '@chakra-ui/icons';

interface TeamsEditWatermarkFormProps {
  team: MBProTeam;
  onTeamUpdated: () => void;
}

export default function({ team, onTeamUpdated }: TeamsEditWatermarkFormProps) {
  const [isLoading, setLoading] = useState(false);
  const [currentTeam, setCurrentTeam] = useState<MBProTeam | undefined>(team);

  const formik = useFormik({
    initialValues: {
      watermark_logo: null,
      watermark_opacity: currentTeam?.watermark_opacity || 0.5,
      watermark_position: currentTeam?.watermark_position || 'center'
    },
    onSubmit: async (values) => {
      if (!values.watermark_logo) return ToastUtils.showError('Vous devez choisir un logo pour le filigrane !');

      setLoading(true);
      MBCoreTeamAPI.updateWatermark(team.id, values.watermark_logo, values.watermark_opacity, values.watermark_position).then(() => {
        onTeamUpdated();
        setLoading(false);
        ToastUtils.showSuccess('Le filigrane a bien été mis à jour, un délai allant jusqu\'à 24h est nécessaire pour le voir apparaître sur les portails de diffusion !');
      }).catch((e) => {
        ToastUtils.showError(e);
        setLoading(false);
      });
    }
  });

  useEffect(() => {
    if (team !== currentTeam) {
      setCurrentTeam(undefined);
      setTimeout(() => {
        setCurrentTeam(team);
      }, 500);
    }
  }, [team]);

  const _resetWatermark = () => {
    formik.setFieldValue('watermark_logo', null);
    formik.setFieldValue('watermark_opacity', currentTeam?.watermark_opacity || 0.5);
    formik.setFieldValue('watermark_position', currentTeam?.watermark_position || 'center');

    setLoading(true);
    MBCoreTeamAPI.deleteWatermark(team.id).then(() => {
      onTeamUpdated();
    }).catch((e) => {
      ToastUtils.showError(e);
    }).finally(() => {
      setLoading(false);
    });
  };

  if (currentTeam === undefined) {
    return <Skeleton height={'300px'} width={'100%'} borderRadius={4} />;
  }

  return (
    <Box w={'100%'}>

      <Alert status='warning' mb={3}>
        <AlertIcon />
        <AlertDescription>Veillez à bien envoyer un logo transparent pour un meilleur rendu !</AlertDescription>
      </Alert>

      <form onSubmit={formik.handleSubmit}>
        <HStack>
          <FileInput
            isRequired
            maxFileSize={4}
            accept={{
              'image/*': ['.png']
            }}
            setFieldValue={formik.setFieldValue}
            label={'Filigrane'}
            helperText={"L'image doit être au format carré et transparent (exemple: 500x500)"}
            name={'watermark_logo'}
            values={formik.values}
            errors={formik.errors}
            touched={formik.touched}
            handleChange={formik.handleChange}
            handleBlur={formik.handleBlur}
            dropZoneProps={{
              height: '150px'
            }}
          />
          {team.watermark_logo && (
            <Box p={3} h={'100%'} w={'40%'} bg={'black'} borderRadius={'md'}>
              <Text textAlign={'center'} fontSize={'xs'} color={'white'}>Mon filigrane actuel</Text>
              <Image src={team.watermark_logo}/>
            </Box>
          )}

        </HStack>

        <Spacer my={3}/>

        <TextInput
          type="hidden"
          label={'Opacité du filigrane'}
          name={'watermark_opacity'}
          helperText={'Nous vous recommandons pour des raisons de sécurité de mettre votre filigrane à l\'opacité 50%'}
          handleChange={formik.handleChange}
          handleBlur={formik.handleBlur}
          values={formik.values}
          errors={formik.errors}
          touched={formik.touched}
          customInput={(
            <HStack>
              <Box position={'relative'} w={'220px'} h={'150px'}>
                <Image position={'absolute'} top={0} bottom={0} right={0} left={0}
                       src={'https://mb-neo.s3.eu-west-3.amazonaws.com/watermark/interior.jpg'} borderRadius={'md'} />
                <Flex h={'100%'} w={'100%'} direction={'column'} justify={'center'} alignItems={'center'}>
                  <Logo color={'dark'} w={'120px'} opacity={formik.values.watermark_opacity}/>
                </Flex>
              </Box>
              <Slider orientation='vertical'  minH='140px' aria-label='slider-ex-4' value={formik.values.watermark_opacity * 100} onChange={v => formik.setFieldValue('watermark_opacity', v/100)}>
                <SliderTrack bg='red.100'>
                  <SliderFilledTrack bg='red.500' />
                </SliderTrack>
                <SliderThumb boxSize={6}>
                  <Box color='red' opacity={formik.values.watermark_opacity} as={ViewIcon} />
                </SliderThumb>
              </Slider>
            </HStack>
          )}
        />

        <Spacer my={3}/>

        <TextInput
          type="hidden"
          label={'Position du filigrane'}
          name={'watermark_position'}
          helperText={'Nous vous recommandons pour des raisons de sécurité de mettre votre filigrane au centre de vos images'}
          handleChange={formik.handleChange}
          handleBlur={formik.handleBlur}
          values={formik.values}
          errors={formik.errors}
          touched={formik.touched}
          customInput={(
            <Box position={'relative'} w={'220px'} h={'150px'}>
              <Image position={'absolute'} top={0} bottom={0} right={0} left={0}
                     src={'https://mb-neo.s3.eu-west-3.amazonaws.com/watermark/interior.jpg'} borderRadius={'md'} />
              <Flex h={'100%'} w={'100%'} direction={'column'} justify={'space-between'}>
                <Flex p={3} w={'100%'} direction={'row'} align={'center'} justify={'space-between'}>
                  <Checkbox colorScheme="red"
                            size={'lg'}
                            isChecked={formik.values.watermark_position == 'top-left'}
                            onChange={() => formik.setFieldValue('watermark_position', 'top-left')} />
                  <Checkbox colorScheme="red"
                            size={'lg'}
                            isChecked={formik.values.watermark_position == 'top-right'}
                            onChange={() => formik.setFieldValue('watermark_position', 'top-right')}/>
                </Flex>
                <Flex p={3} w={'100%'} direction={'row'} align={'center'} justify={'center'}>
                  <Checkbox colorScheme="red"
                            size={'lg'}
                            isChecked={formik.values.watermark_position == 'center'}
                            onChange={() => formik.setFieldValue('watermark_position', 'center')}/>
                </Flex>
                <Flex p={3} w={'100%'} direction={'row'} align={'center'} justify={'space-between'}>
                  <Checkbox colorScheme="red"
                            size={'lg'}
                            isChecked={formik.values.watermark_position == 'bottom-left'}
                            onChange={() => formik.setFieldValue('watermark_position', 'bottom-left')} />
                  <Checkbox colorScheme="red"
                            size={'lg'}
                            isChecked={formik.values.watermark_position == 'bottom-right'}
                            onChange={() => formik.setFieldValue('watermark_position', 'bottom-right')}/>
                </Flex>
              </Flex>
            </Box>
          )}
        />
      </form>

      <Box mt={3} bg={'red.50'} p={4} borderRadius={'md'}>
        <Text fontSize={'sm'} color={'red.500'}>Attention, cette action est réalisable qu'une seule fois par heure,
          dès que vous avez cliqué, une heure au maximum sera nécéssaire pour mettre à jour vos filigranes, ils
          apparaitront ensuite sur les portails de diffusions dans un délai de 24h maximum</Text>

        <Flex mt={3} align={'center'} justify={'space-between'}>
          <ModalConfirm onConfirm={_resetWatermark} title={'Réinitialiser le filigrane'}
                        description={'Êtes-vous sûr de vouloir réinitialiser le filigrane ?'}>
            {(onClick) => (
              <Button leftIcon={ArrowPathRoundedSquareIcon} variant={'primary'} onClick={onClick}
                      isLoading={isLoading} colorScheme={'red'}>Réinitialiser le filigrane</Button>
            )}
          </ModalConfirm>

          <Button onClick={formik.submitForm} isLoading={isLoading} isDisabled={formik.isSubmitting} leftIcon={CheckBadgeIcon}>
            Modifier mon filigrane
          </Button>
        </Flex>
      </Box>
    </Box>
  );
}
