import { useEffect } from 'react';
import { useLocation } from 'react-router';

import GAUtils from '../../utils/GAUtils';

export default function GoogleAnalyticsRouter() {
  const location = useLocation();

  useEffect(() => {
    GAUtils.pageView(location.pathname, document.title);

    // @ts-ignore
    if (window.$crisp === undefined) return;
    try {
      if (location.pathname.includes('admin')) {
        // @ts-ignore
        window.$crisp.push(['do', 'chat:hide']);
      } else {
        // @ts-ignore
        window.$crisp.push(['do', 'chat:show']);
      }
    } catch (e) {
      console.error(e);
    }
  }, [location]);

  return null;
}
