import React, { useEffect } from 'react';

import moment from 'moment';
import { format, register } from 'timeago.js';

import { Text, TextProps } from '@chakra-ui/react';

export interface TextDateProps extends TextProps {
  date: Date;
  defaultFormat?: 'ago' | 'date';
  dateFormat?: string;
  renderDate?: (date: Date) => string;
}

function localeFunc(number: number, index: number): [string, string] {
  return [
    ["à l'instant", 'dans un instant'],
    ['il y a %s secondes', 'dans %s secondes'],
    ['il y a 1 minute', 'dans 1 minute'],
    ['il y a %s minutes', 'dans %s minutes'],
    ['il y a 1 heure', 'dans 1 heure'],
    ['il y a %s heures', 'dans %s heures'],
    ['il y a 1 jour', 'dans 1 jour'],
    ['il y a %s jours', 'dans %s jours'],
    ['il y a 1 semaine', 'dans 1 semaine'],
    ['il y a %s semaines', 'dans %s semaines'],
    ['il y a 1 mois', 'dans 1 mois'],
    ['il y a %s mois', 'dans %s mois'],
    ['il y a 1 an', 'dans 1 an'],
    ['il y a %s ans', 'dans %s ans'],
  ][index] as [string, string];
}

register('fr', localeFunc);

const TextDate = (props: TextDateProps) => {
  const _dateToAgo = () => format(props.date.getTime(), 'fr');

  const _dateToText = props.renderDate
    ? props.renderDate(props.date)
    : moment(props.date).format(props.dateFormat ? props.dateFormat : 'DD/MM/YYYY');

  const [isAgoFormat, setIsAgoFormat] = React.useState(props.defaultFormat ? props.defaultFormat === 'ago' : false);
  const [timeAgo, setTimeAgo] = React.useState(_dateToAgo());

  useEffect(() => {
    const id = setInterval(() => {
      setTimeAgo(_dateToAgo());
    }, 1000 * 10);

    return () => clearInterval(id);
  }, []);

  if (isAgoFormat) {
    return (
      <Text cursor={'pointer'} onClick={() => setIsAgoFormat(!isAgoFormat)} {...props}>
        {timeAgo}
      </Text>
    );
  }

  return (
    <Text cursor={'pointer'} onClick={() => setIsAgoFormat(!isAgoFormat)} {...props}>
      {_dateToText}
    </Text>
  );
};

export default TextDate;
