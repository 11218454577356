import React from 'react';
import { Navigate } from 'react-router';

import { AuthConstant } from '@meilleursbiens/constants';

import Store from '../Store';
import { Routes } from '../routes/routes';
import LocalStorageUtils from '../utils/LocalStorageUtils';

export default ({ children }) => {
  const api_token = localStorage.getItem(AuthConstant.JWT_TOKEN_KEY);
  if (!api_token) {
    return (
      <Navigate
        to={{
          pathname: Routes.AUTH_LOGIN.url,
        }}
      />
    );
  }

  const isAuthenticated = Store.getState().auth.isAuthenticated;
  if (!isAuthenticated) {
    return (
      <Navigate
        to={{
          pathname: Routes.AUTH_REFRESH.url,
        }}
        state={{
          from: window.location.href.replace(window.location.origin, ''),
        }}
        replace={true}
      />
    );
  }

  const user = Store.getState().auth.user;
  let isImpersonate = localStorage.getItem(AuthConstant.BACKUP_KEY + AuthConstant.IMPERSONATE_USER_SAVE);
  const isAuthorized =
    (user.status === 1 && user.sms_validated === 1 && user.email_validation === 1) || isImpersonate !== null;

  if (user.email_validation === 0 && isImpersonate === null) {
    return <Navigate to={Routes.AUTH_REGISTER_VALIDATE.url.replace(':code', user.email_validation_code)} />;
  }
  if (user.sms_validated === 0 && isImpersonate === null) {
    return <Navigate to={Routes.AUTH_REGISTER_VALIDATE.url.replace(':code', user.email_validation_code)} />;
  }
  if (user.status === 4 && isImpersonate === null) {
    LocalStorageUtils.clear();
    return <Navigate to={Routes.AUTH_LOGIN.url} />;
  }

  if (!isAuthorized) {
    LocalStorageUtils.clear();
  }

  if (isAuthorized) {
    return children;
  }

  return <Navigate to={Routes.AUTH_LOGIN.url} />;
};
