import React from 'react';

import { lazyRetry } from '@meilleursbiens/utils';

const MBProAdminLogsScreen = React.lazy(() =>
  lazyRetry(() => import('../screens/MBPro/admin/Logs/MBProAdminLogsScreen'), 'MBProAdminLogsScreen')
);
const AdminAgentViewScreen = React.lazy(() =>
  lazyRetry(() => import('../screens/MBPro/admin/Agents/View/AdminAgentViewScreen'), 'AdminAgentViewScreen')
);
const MBProAdminDocumentsScreen = React.lazy(() =>
  lazyRetry(() => import('../screens/MBPro/admin/Documents/MBProAdminDocumentsScreen'), 'MBProAdminDocumentsScreen')
);
const AdminDocumentsRCPScreen = React.lazy(() =>
  lazyRetry(() => import('../screens/MBPro/admin/Documents/AdminDocumentsRCPScreen'), 'AdminDocumentsRCPScreen')
);
const AdminMarketplaceScreen = React.lazy(() =>
  lazyRetry(() => import('../screens/MBPro/admin/AdminMarketplaceScreen'), 'AdminMarketplaceScreen')
);
const AdminContactsScreen = React.lazy(() =>
  lazyRetry(() => import('../screens/MBPro/admin/Transactions/Contacts/AdminContactsScreen'), 'AdminContactsScreen')
);
const AdminReplaysScreen = React.lazy(() =>
  lazyRetry(() => import('../screens/MBPro/admin/Formations/AdminReplaysScreen'), 'AdminReplaysScreen')
);
const AdminFormationsScreen = React.lazy(() =>
  lazyRetry(() => import('../screens/MBPro/admin/Formations/AdminFormationsScreen'), 'AdminFormationsScreen')
);
const AdminSubscriptionsScreen = React.lazy(() =>
  lazyRetry(() => import('../screens/MBPro/admin/Subscriptions/AdminSubscriptionsScreen'), 'AdminSubscriptionsScreen')
);
const AdminTransactionsDashboardScreen = React.lazy(() =>
  lazyRetry(() => import('../screens/MBPro/admin/AdminTransactionsDashboardScreen'), 'AdminTransactionsDashboardScreen')
);
const AdminTransactionsLocationScreen = React.lazy(() =>
  lazyRetry(() => import('../screens/MBPro/admin/AdminTransactionsLocationScreen'), 'AdminTransactionsLocationScreen')
);
const AdminTransactionScreen = React.lazy(() =>
  lazyRetry(() => import('../screens/MBPro/admin/Transactions/AdminTransactionScreen'), 'AdminTransactionScreen')
);
const AdminTransactionLocationViewScreen = React.lazy(() =>
  lazyRetry(
    () => import('../screens/MBPro/admin/Transactions/AdminTransactionLocationViewScreen'),
    'AdminTransactionLocationViewScreen'
  )
);
const AdminTransactionsVirementScreen = React.lazy(() =>
  lazyRetry(
    () => import('../screens/MBPro/admin/Transactions/Virements/AdminTransactionsVirementScreen'),
    'AdminTransactionsVirementScreen'
  )
);
const AdminCreateExternalInvoiceScreen = React.lazy(() =>
  lazyRetry(
    () => import('../screens/MBPro/admin/Comptabilite/AdminCreateExternalInvoiceScreen'),
    'AdminCreateExternalInvoiceScreen'
  )
);
const AdminContratScreen = React.lazy(() =>
  lazyRetry(() => import('../screens/MBPro/admin/Documents/AdminContratScreen'), 'AdminContratScreen')
);
const AdminMandatsScreen = React.lazy(() =>
  lazyRetry(() => import('../screens/MBPro/admin/Mandats/AdminMandatsScreen'), 'AdminMandatsScreen')
);
const ImpersonateScreen = React.lazy(() =>
  lazyRetry(() => import('../screens/ImpersonateScreen'), 'ImpersonateScreen')
);
const MBSaaSAdminFormSchemeScreen = React.lazy(() =>
  lazyRetry(() => import('../screens/SaaS/Admin/MBSaaSAdminFormSchemeScreen'), 'MBSaaSAdminFormSchemeScreen')
);
const MBSaaSToolsScreen = React.lazy(() =>
  lazyRetry(() => import('../screens/SaaS/Admin/MBSaaSToolsScreen'), 'MBSaaSToolsScreen')
);
const MBProAdminDashboardScreen = React.lazy(() =>
  lazyRetry(() => import('../screens/MBPro/admin/MBProAdminDashboardScreen'), 'MBProAdminDashboardScreen')
);
const MBProAdminAgentsScreen = React.lazy(() =>
  lazyRetry(() => import('../screens/MBPro/admin/Agents/MBProAdminAgentsScreen'), 'MBProAdminAgentsScreen')
);
const MBProAdminAgentsOverdueScreen = React.lazy(() =>
  lazyRetry(
    () => import('../screens/MBPro/admin/Agents/Overdue/MBProAdminAgentsOverdueScreen'),
    'MBProAdminAgentsOverdueScreen'
  )
);
const MBProAdminInvoicesScreen = React.lazy(() =>
  lazyRetry(() => import('../screens/MBPro/admin/Comptabilite/MBProAdminInvoicesScreen'), 'MBProAdminInvoicesScreen')
);
const MBProAdminTransactionsScreen = React.lazy(() =>
  lazyRetry(
    () => import('../screens/MBPro/admin/Transactions/MBProAdminTransactionsScreen'),
    'MBProAdminTransactionsScreen'
  )
);
const MBProAdminProAbonoInvoicesScreen = React.lazy(() =>
  lazyRetry(
    () => import('../screens/MBPro/admin/Subscriptions/MBProAdminProAbonoInvoicesScreen'),
    'MBProAdminProAbonoInvoicesScreen'
  )
);
const MBProAdminProAbonoFeaturesScreen = React.lazy(() =>
  lazyRetry(
    () => import('../screens/MBPro/admin/Subscriptions/MBProAdminProAbonoFeaturesScreen'),
    'MBProAdminProAbonoFeaturesScreen'
  )
);
const MBCorePermissionsRolesScreen = React.lazy(() =>
  lazyRetry(
    () => import('../screens/Core/Permissions/Roles/MBCorePermissionsRolesScreen'),
    'MBCorePermissionsRolesScreen'
  )
);
const MBCoreAdminTeamsScreen = React.lazy(() =>
  lazyRetry(() => import('../screens/Core/Teams/MBCoreAdminTeamsScreen'), 'MBCoreAdminTeamsScreen')
);
const MBHarpAdminSalariesVirementsScreen = React.lazy(() =>
  lazyRetry(
    () => import('../screens/MBHarp/Admin/Salaries/MBHarpAdminSalariesVirementsScreen'),
    'MBHarpAdminSalariesVirementsScreen'
  )
);
const MBCoreAdminSMSScreen = React.lazy(() =>
  lazyRetry(() => import('../screens/Core/SMS/MBCoreAdminSMSScreen'), 'MBCoreAdminSMSScreen')
);

export default {
  ADMIN_DASHBOARD: {
    url: '/admin/dashboard',
    component: MBProAdminDashboardScreen,
    routeType: 'admin',
  },
  ADMIN_LOGS: {
    url: '/admin/logs',
    component: MBProAdminLogsScreen,
    routeType: 'admin',
  },
  ADMIN_AGENTS: {
    url: '/admin/agents',
    component: MBProAdminAgentsScreen,
    routeType: 'admin',
  },
  ADMIN_AGENTS_OVERDUES: {
    url: '/admin/overdues',
    component: MBProAdminAgentsOverdueScreen,
    routeType: 'admin',
  },
  ADMIN_AGENTS_VIEW: {
    url: '/admin/agent/:id',
    component: AdminAgentViewScreen,
    routeType: 'admin',
  },
  ADMIN_DOCUMENTS: {
    url: '/admin/documents',
    component: MBProAdminDocumentsScreen,
    routeType: 'admin',
  },
  ADMIN_DOCUMENTS_RCP: {
    url: '/admin/documents/rcp',
    component: AdminDocumentsRCPScreen,
    routeType: 'admin',
  },
  ADMIN_MARKETPLACE: {
    url: '/admin/marketplace',
    component: AdminMarketplaceScreen,
    routeType: 'admin',
  },
  ADMIN_INVOICES: {
    url: '/admin/invoices',
    component: MBProAdminInvoicesScreen,
    routeType: 'admin',
  },
  ADMIN_CONTACTS: {
    url: '/admin/contacts',
    component: AdminContactsScreen,
    routeType: 'admin',
  },
  ADMIN_FORMATIONS: {
    url: '/admin/formations',
    component: AdminFormationsScreen,
    routeType: 'admin',
  },
  ADMIN_REPLAYS: {
    url: '/admin/replays',
    component: AdminReplaysScreen,
    routeType: 'admin',
  },
  ADMIN_SUBSCRIPTIONS: {
    url: '/admin/subscriptions',
    component: AdminSubscriptionsScreen,
    routeType: 'admin',
  },
  ADMIN_PROABONO_INVOICES: {
    url: '/admin/subscriptions/invoices',
    component: MBProAdminProAbonoInvoicesScreen,
    routeType: 'admin',
  },
  ADMIN_PROABONO_FEATURES: {
    url: '/admin/subscriptions/features',
    component: MBProAdminProAbonoFeaturesScreen,
    routeType: 'admin',
  },
  ADMIN_TRANSACTIONS_DASHBOARD: {
    url: '/admin/transactions/dashboard',
    component: AdminTransactionsDashboardScreen,
    routeType: 'admin',
  },
  ADMIN_TRANSACTIONS: {
    url: '/admin/transactions',
    component: MBProAdminTransactionsScreen,
    routeType: 'admin',
  },
  ADMIN_TRANSACTIONS_LOCATION: {
    url: '/admin/transactions-location',
    component: AdminTransactionsLocationScreen,
    routeType: 'admin',
  },
  ADMIN_TRANSACTIONS_VIEW: {
    url: '/admin/transaction/:id',
    component: AdminTransactionScreen,
    routeType: 'admin',
  },
  ADMIN_TRANSACTIONS_LOCATION_VIEW: {
    url: '/admin/transaction-location/:id',
    component: AdminTransactionLocationViewScreen,
    routeType: 'admin',
  },
  ADMIN_TRANSACTIONS_VIREMENTS_STEP_1: {
    url: '/admin/transactions/virements/step-1',
    component: AdminTransactionsVirementScreen,
    routeType: 'admin',
  },
  ADMIN_TRANSACTIONS_VIREMENTS_STEP_2: {
    url: '/admin/transactions/virements/step-2/:token',
    component: AdminTransactionsVirementScreen,
    routeType: 'admin',
  },
  ADMIN_INVOICE_EXTERN_CREATE: {
    url: '/admin/invoice-extern/create',
    component: AdminCreateExternalInvoiceScreen,
    routeType: 'admin',
  },
  ADMIN_CONTRATS: {
    url: '/admin/contrats',
    component: AdminContratScreen,
    routeType: 'admin',
  },
  ADMIN_MANDATS: {
    url: '/admin/mandats',
    component: AdminMandatsScreen,
    routeType: 'admin',
  },
  ADMIN_AGENT_IMPERSONATE: {
    url: '/admin/agent/impersonate/:id',
    component: ImpersonateScreen,
    routeType: 'admin',
  },
  ADMIN_SAAS_FORM_SCHEMAS: {
    url: '/admin/saas/form-schemas',
    component: MBSaaSAdminFormSchemeScreen,
    routeType: 'admin',
  },
  ADMIN_SAAS_TOOLS: {
    url: '/admin/saas/tools',
    component: MBSaaSToolsScreen,
    routeType: 'admin',
  },
  ADMIN_CORE_PERMISSIONS_ROLES: {
    url: '/admin/core/permissions/roles',
    component: MBCorePermissionsRolesScreen,
    routeType: 'admin',
  },
  ADMIN_TEAMS: {
    url: '/admin/core/teams',
    component: MBCoreAdminTeamsScreen,
    routeType: 'admin',
  },
  ADMIN_HARP_SALARIES_VIREMENTS_STEP_1: {
    url: '/admin/harp/salaries/virements/step-1',
    component: MBHarpAdminSalariesVirementsScreen,
    routeType: 'admin',
  },
  ADMIN_HARP_SALARIES_VIREMENTS_STEP_2: {
    url: '/admin/harp/salaries/step-2/:token',
    component: MBHarpAdminSalariesVirementsScreen,
    routeType: 'admin',
  },
  ADMIN_CORE_SMS: {
    url: '/admin/core/sms',
    component: MBCoreAdminSMSScreen,
    routeType: 'admin',
  },
};
