import React, { useCallback } from 'react';

// @ts-ignore
import FileSaver from 'file-saver';
import { useFormik } from 'formik';
import { Bar, ComposedChart, LabelList, ReferenceLine, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import { useGenerateImage } from 'recharts-to-png';
import * as Yup from 'yup';

import { Box, SimpleGrid, VStack, useColorModeValue, useToken } from '@chakra-ui/react';
import { ArrowDownTrayIcon } from '@heroicons/react/24/outline';
import { PriceUtils } from '@meilleursbiens/utils';

import { Button, Card, Section, StatBox } from '../../Components';
import RangeInput from '../../Forms/Inputs/RangeInput/RangeInput';

export interface CashflowCalculatorProps {
  defaultBuyPrice?: number;
}

const ValidationSchema = Yup.object().shape({
  buyPrice: Yup.number().required('Ce champ est obligatoire'),
  notaryFees: Yup.number().required('Ce champ est obligatoire'),
  workCosts: Yup.number().required('Ce champ est obligatoire'),
  personalContribution: Yup.number().required('Ce champ est obligatoire'),
  loanRate: Yup.number().required('Ce champ est obligatoire'),
  loanDuration: Yup.number().required('Ce champ est obligatoire'),
  rentPrice: Yup.number().required('Ce champ est obligatoire'),
  taxRate: Yup.number().required('Ce champ est obligatoire'),
  chargesAmount: Yup.number().required('Ce champ est obligatoire'),
});

// @ts-ignore
const renderRentCustomizedLabel = (props) => {
  const { x, y, width, height, value } = props;

  return (
    <g>
      <text x={x + width / 2} y={y} fill="#fff" dy={height / 2 - 5} textAnchor="middle" fontSize={10} fontWeight={600}>
        Loyer
      </text>
      <text x={x + width / 2} y={y} fill="#fff" dy={height / 2 + 8} textAnchor="middle" fontSize={10} fontWeight={400}>
        {PriceUtils.formatPrice(value)}/mois
      </text>
    </g>
  );
};

// @ts-ignore
const renderCashflowCustomizedLabel = (props) => {
  const { x, y, width, height, value } = props;

  return (
    <g>
      <text x={x + width / 2} y={y} fill="#fff" dy={height / 2 - 5} textAnchor="middle" fontSize={10} fontWeight={600}>
        Cashflow
      </text>
      <text x={x + width / 2} y={y} fill="#fff" dy={height / 2 + 8} textAnchor="middle" fontSize={10} fontWeight={400}>
        {PriceUtils.formatPrice(value)}/mois
      </text>
    </g>
  );
};

// @ts-ignore
const renderChargesCustomizedLabel = (props) => {
  const { x, y, width, height, value } = props;

  return (
    <g>
      <text x={x + width / 2} y={y} fill="#fff" dy={height / 2 - 5} textAnchor="middle" fontSize={10} fontWeight={600}>
        Prêt
      </text>
      <text x={x + width / 2} y={y} fill="#fff" dy={height / 2 + 8} textAnchor="middle" fontSize={10} fontWeight={400}>
        {PriceUtils.formatPrice(value)}/mois
      </text>
    </g>
  );
};

// @ts-ignore
const renderTaxCustomizedLabel = (props) => {
  const { x, y, width, height, value } = props;

  if (value < 42) {
    return null;
  }

  return (
    <g>
      <text x={x + width / 2} y={y} fill="#fff" dy={height / 2 - 7} textAnchor="middle" fontSize={7} fontWeight={600}>
        Taxe
      </text>
      <text x={x + width / 2} y={y} fill="#fff" dy={height / 2} textAnchor="middle" fontSize={7} fontWeight={600}>
        foncière
      </text>
      <text x={x + width / 2} y={y} fill="#fff" dy={height / 2 + 10} textAnchor="middle" fontSize={7} fontWeight={400}>
        {PriceUtils.formatPrice(value)}/mois
      </text>
    </g>
  );
};

// @ts-ignore
const renderExtraChargesCustomizedLabel = (props) => {
  const { x, y, width, height, value } = props;

  return (
    <g>
      <text x={x + width / 2} y={y} fill="#fff" dy={height / 2 - 3} textAnchor="middle" fontSize={7} fontWeight={600}>
        Charges
      </text>
      <text x={x + width / 2} y={y} fill="#fff" dy={height / 2 + 7} textAnchor="middle" fontSize={7} fontWeight={400}>
        {PriceUtils.formatPrice(value)}/mois
      </text>
    </g>
  );
};

const CashflowCalculator = (props: CashflowCalculatorProps) => {
  const formik = useFormik({
    initialValues: {
      buyPrice: 100000,
      notaryFees: 8000,
      workCosts: 10000,
      personalContribution: 0,
      loanRate: 3.87,
      loanDuration: 20,
      rentPrice: 650,
      taxRate: 1000,
      chargesAmount: 150,
    },
    validationSchema: ValidationSchema,
    onSubmit: (values) => {
      console.log(values);
    },
  });

  const [getDivJpeg, { ref }] = useGenerateImage<HTMLDivElement>({
    quality: 1,
    type: 'image/png',
  });

  const [cashflow, setCashflow] = React.useState<number>(0);
  const [grossProfit, setGrossProfit] = React.useState<number>(0);
  const [loanPayment, setLoanPayment] = React.useState<number>(0);
  const [taxRate, setTaxRate] = React.useState<number>(0);

  const handleDivDownload = useCallback(async () => {
    const jpeg = await getDivJpeg();
    if (jpeg) {
      const date = new Date();
      FileSaver.saveAs(jpeg, 'simulation_locatif_' + date.getTime() + '.png');
    }
  }, []);

  const _calculateCashflow = () => {
    const {
      buyPrice,
      notaryFees,
      workCosts,
      personalContribution,
      loanRate,
      loanDuration,
      rentPrice,
      taxRate,
      chargesAmount,
    } = formik.values;

    const totalCost = buyPrice + notaryFees + workCosts;
    const loanAmount = totalCost - personalContribution;
    const monthlyLoanPayment =
      (loanAmount * (loanRate / 100)) / 12 / (1 - Math.pow(1 + loanRate / 100 / 12, -loanDuration * 12));
    const monthlyCashflow = rentPrice - (monthlyLoanPayment + taxRate / 12 + chargesAmount);

    return monthlyCashflow;
  };

  const _calculRentabiliteBrutePourcentage = () => {
    const { buyPrice, rentPrice } = formik.values;
    return ((rentPrice * 12) / buyPrice) * 100;
  };

  const _calculateLoanPayment = () => {
    const { buyPrice, notaryFees, workCosts, personalContribution, loanRate, loanDuration } = formik.values;

    const totalCost = buyPrice + notaryFees + workCosts;
    const loanAmount = totalCost - personalContribution;
    return (loanAmount * (loanRate / 100)) / 12 / (1 - Math.pow(1 + loanRate / 100 / 12, -loanDuration * 12));
  };

  const _calculateTaxRate = () => {
    const { taxRate } = formik.values;
    return taxRate / 12;
  };

  React.useEffect(() => {
    setCashflow(_calculateCashflow());
    setGrossProfit(_calculRentabiliteBrutePourcentage());
    setLoanPayment(_calculateLoanPayment());
    setTaxRate(_calculateTaxRate());
  }, [formik.values]);

  React.useEffect(() => {
    if (props.defaultBuyPrice) {
      formik.setFieldValue('buyPrice', props.defaultBuyPrice);
      formik.setFieldValue('notaryFees', props.defaultBuyPrice * 0.08);
      formik.setFieldValue('workCosts', props.defaultBuyPrice * 0.1);
    }
  }, [props.defaultBuyPrice]);

  const dataRent = [
    {
      name: 'Résultat',
      rent: formik.values.rentPrice,
      cashflow: cashflow,
      loanPayment: loanPayment,
      taxRate: taxRate,
      chargesAmount: formik.values.chargesAmount,
    },
  ];

  const grayLight = useToken('colors', 'gray.200');
  const grayDark = useToken('colors', 'gray.700');
  const grayBorder = useColorModeValue(grayLight, grayDark);

  const purpleExtraExtraLight = useToken('colors', 'indigo.200');
  const purpleExtraExtraDark = useToken('colors', 'indigo.400');
  const purpleExtraExtraChart = useColorModeValue(purpleExtraExtraLight, purpleExtraExtraDark);

  const purpleExtraLight = useToken('colors', 'indigo.400');
  const purpleExtraDark = useToken('colors', 'indigo.600');
  const purpleExtraChart = useColorModeValue(purpleExtraLight, purpleExtraDark);

  const purpleLight = useToken('colors', 'indigo.600');
  const purpleDark = useToken('colors', 'indigo.800');
  const purpleChart = useColorModeValue(purpleLight, purpleDark);

  const orangeLight = useToken('colors', 'orange.400');
  const orangeDark = useToken('colors', 'orange.800');
  const orangeChart = useColorModeValue(orangeLight, orangeDark);

  const greenLight = useToken('colors', 'green.400');
  const greenDark = useToken('colors', 'green.800');
  const greenChart = useColorModeValue(greenLight, greenDark);

  const redLight = useToken('colors', 'red.400');
  const redDark = useToken('colors', 'red.800');
  const redChart = useColorModeValue(redLight, redDark);

  const maxVal = Math.max(formik.values.rentPrice, cashflow, loanPayment + taxRate + formik.values.chargesAmount);
  const isPositive = cashflow >= 0;

  return (
    <>
      <Section
        size={'xs'}
        title={"Simulation d'investissement locatif"}
        description={
          'Calculez le cashflow de votre investissement locatif en fonction de vos critères de financement et de locatif'
        }
        actions={
          <Button onClick={handleDivDownload} leftIcon={ArrowDownTrayIcon} colorScheme={'primary'} size={'sm'}>
            Télécharger la simulation
          </Button>
        }
      />
      <Box w={'100%'} mt={3} ref={ref}>
        <Card p={0} w={'full'} h={'240px'} mb={3} position={'relative'}>
          <ResponsiveContainer width="100%" height="100%">
            <ComposedChart margin={{ top: 0, left: 15, right: 15, bottom: 0 }} layout="vertical" data={dataRent}>
              <defs>
                <linearGradient id="chargesExtra" x1="0" y1="0.5" x2="1" y2="0">
                  <stop offset="30%" stopColor={purpleExtraExtraChart} stopOpacity={1} />
                  <stop offset="95%" stopColor={purpleExtraExtraChart} stopOpacity={0.7} />
                </linearGradient>
              </defs>
              <defs>
                <linearGradient id="charges" x1="0" y1="0.5" x2="1" y2="0">
                  <stop offset="30%" stopColor={purpleChart} stopOpacity={1} />
                  <stop offset="95%" stopColor={purpleChart} stopOpacity={0.7} />
                </linearGradient>
              </defs>
              <defs>
                <linearGradient id="tax" x1="0" y1="0.5" x2="1" y2="0">
                  <stop offset="30%" stopColor={purpleExtraChart} stopOpacity={1} />
                  <stop offset="95%" stopColor={purpleExtraChart} stopOpacity={0.7} />
                </linearGradient>
              </defs>
              <defs>
                <linearGradient id="rent" x1="0" y1="0.5" x2="1" y2="0">
                  <stop offset="30%" stopColor={orangeChart} stopOpacity={1} />
                  <stop offset="95%" stopColor={orangeChart} stopOpacity={0.4} />
                </linearGradient>
              </defs>
              <defs>
                <linearGradient id="cashflowNegative" x1="1" y1="0.5" x2="0" y2="0">
                  <stop offset="30%" stopColor={redChart} stopOpacity={1} />
                  <stop offset="95%" stopColor={redChart} stopOpacity={0.4} />
                </linearGradient>
              </defs>
              <defs>
                <linearGradient id="cashflowPositive" x1="0" y1="0.5" x2="1" y2="0">
                  <stop offset="0%" stopColor={greenChart} stopOpacity={1} />
                  <stop offset="100%" stopColor={greenChart} stopOpacity={0.4} />
                </linearGradient>
              </defs>
              <Bar radius={[0, 6, 6, 0]} dataKey="rent" fill="url(#rent)">
                <LabelList dataKey="rent" content={renderRentCustomizedLabel} />
              </Bar>
              <Bar dataKey="loanPayment" stackId="a" fill="url(#charges)">
                <LabelList dataKey="loanPayment" content={renderChargesCustomizedLabel} />
              </Bar>
              <Bar dataKey="taxRate" stackId="a" fill="url(#tax)">
                <LabelList dataKey="taxRate" content={renderTaxCustomizedLabel} />
              </Bar>
              <Bar dataKey="chargesAmount" stackId="a" fill="url(#chargesExtra)" radius={[0, 6, 6, 0]}>
                <LabelList dataKey="chargesAmount" content={renderExtraChargesCustomizedLabel} />
              </Bar>

              <Bar
                dataKey="cashflow"
                radius={[0, 6, 6, 0]}
                fill={cashflow >= 0 ? 'url(#cashflowPositive)' : 'url(#cashflowNegative)'}
              >
                <LabelList dataKey="cashflow" content={renderCashflowCustomizedLabel} />
              </Bar>
              <ReferenceLine x={0} stroke={grayBorder} strokeWidth={1.5} strokeOpacity={0.65} />
              <XAxis
                hide
                axisLine={true}
                tickLine={false}
                domain={[!isPositive ? -maxVal : 0, maxVal]}
                dataKey={'rent'}
                type="number"
                tick={false}
              />
              <YAxis hide axisLine={true} orientation="left" dataKey="name" type={'category'} scale="band" />
            </ComposedChart>
          </ResponsiveContainer>
        </Card>
      </Box>
      <SimpleGrid minChildWidth={240} spacing={3} mb={5}>
        <StatBox value={PriceUtils.formatPrice(cashflow)} label={'Cashflow mensuel'} />
        <StatBox value={grossProfit.toFixed(2) + '%'} label={'Rentabilité brut'} />
      </SimpleGrid>
      <form onSubmit={formik.handleSubmit}>
        <VStack spacing={3}>
          <Section
            size={'xs'}
            title={'Bien Immobilier'}
            description={"Les informations sur le prix d'achat, les frais de notaires et les travaux prévus"}
            isCollapsible
          >
            <VStack spacing={4}>
              <RangeInput
                label={"Prix d'achat FAI"}
                name={'buyPrice'}
                min={10000}
                max={1000000}
                step={1000}
                formatValue={(value) => PriceUtils.formatPrice(value)}
                values={formik.values}
                errors={formik.errors}
                touched={formik.touched}
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
              />

              <RangeInput
                label={'Frais de Notaire'}
                name={'notaryFees'}
                min={0}
                max={100000}
                step={50}
                formatValue={(value) => PriceUtils.formatPrice(value)}
                values={formik.values}
                errors={formik.errors}
                touched={formik.touched}
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
              />

              <RangeInput
                label={'Travaux'}
                name={'workCosts'}
                min={0}
                max={100000}
                step={500}
                formatValue={(value) => PriceUtils.formatPrice(value)}
                values={formik.values}
                errors={formik.errors}
                touched={formik.touched}
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
              />
            </VStack>
          </Section>

          <Section
            mt={4}
            size={'xs'}
            title={'Financement'}
            description={'Réglez vos détails de financement pour simuler le cashflow du bien'}
            isCollapsible
          >
            <VStack spacing={4}>
              <RangeInput
                label={'Apport personnel'}
                name={'personalContribution'}
                min={0}
                max={1000000}
                step={1000}
                formatValue={(value) => PriceUtils.formatPrice(value)}
                values={formik.values}
                errors={formik.errors}
                touched={formik.touched}
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
              />

              <RangeInput
                label={"Taux d'emprunt"}
                name={'loanRate'}
                min={0.01}
                max={10}
                step={0.01}
                formatValue={(value) => value + '%'}
                values={formik.values}
                errors={formik.errors}
                touched={formik.touched}
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
              />

              <RangeInput
                label={"Durée de l'emprunt en mois"}
                name={'loanDuration'}
                min={5}
                max={25}
                step={1}
                formatValue={(value) => value + ' années'}
                values={formik.values}
                errors={formik.errors}
                touched={formik.touched}
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
              />
            </VStack>
          </Section>

          <Section
            mt={4}
            size={'xs'}
            title={'Locatif'}
            description={'Réglez le loyer, la taxe foncière et les charges mensuelles'}
          >
            <VStack spacing={4}>
              <RangeInput
                label={'Loyer mensuel'}
                name={'rentPrice'}
                min={10}
                max={10000}
                step={10}
                formatValue={(value) => PriceUtils.formatPrice(value) + '/mois'}
                values={formik.values}
                errors={formik.errors}
                touched={formik.touched}
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
              />

              <RangeInput
                label={'Taxe foncière'}
                name={'taxRate'}
                min={0}
                max={5000}
                step={1}
                formatValue={(value) => PriceUtils.formatPrice(value)}
                values={formik.values}
                errors={formik.errors}
                touched={formik.touched}
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
              />

              <RangeInput
                label={'Charges mensuelles'}
                name={'chargesAmount'}
                min={0}
                max={5000}
                step={1}
                formatValue={(value) => PriceUtils.formatPrice(value)}
                values={formik.values}
                errors={formik.errors}
                touched={formik.touched}
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
              />
            </VStack>
          </Section>
        </VStack>
      </form>
    </>
  );
};

export default CashflowCalculator;
