import * as React from 'react';

import { HTMLChakraProps, StackProps, chakra, useStyleConfig } from '@chakra-ui/react';
import { cx } from '@chakra-ui/utils';

export interface ToolbarGroupProps extends HTMLChakraProps<'div'> {}

export const ToolbarGroup: React.FC<ToolbarGroupProps> = (props) => {
  const styles = useStyleConfig('ToolbarGroup', props);

  const { children } = props;

  return (
    <chakra.div __css={styles} {...props} className={cx('mb_ui_layouts_nav_group', props.className)}>
      {children}
    </chakra.div>
  );
};

ToolbarGroup.defaultProps = {};

ToolbarGroup.displayName = 'ToolbarGroup';
