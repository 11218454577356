import * as types from '../../actions/ActionTypes';

const initialState = {
  isAuthenticated: false,
  user: undefined,
  registerData: undefined,
  secretData: false,
};

export default function auth(state = initialState, action = {}) {
  switch (action.type) {
    case types.AUTH_LOGIN_USER:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload,
      };
    case types.AUTH_LOGOUT_USER:
      return {
        ...state,
        isAuthenticated: false,
        user: undefined,
      };
    case types.AUTH_REGISTER_USER:
      return {
        ...state,
        registerData: action.payload,
      };
    case types.AUTH_CLEAR_REGISTER_USER:
      return {
        ...state,
        registerData: undefined,
      };
    case types.AUTH_SECRET_DATA:
      return {
        ...state,
        secretData: action.payload,
      };
    default:
      return state;
  }
}
