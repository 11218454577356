import React, { useEffect, useState } from 'react';

import { MBCoreTeamAPI } from '@meilleursbiens/api';
import { MBProTeam } from '@meilleursbiens/types';
import { Card, EditableApiInput, FeatureDate, Persona, Section, UploadFileComponent } from '@meilleursbiens/ui';

import {
  Badge,
  Box,
  Button,
  HStack,
  Skeleton,
  Spacer,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text
} from '@chakra-ui/react';
import { ChatBubbleBottomCenterIcon, LockClosedIcon, MapPinIcon, PhoneIcon, TagIcon } from '@heroicons/react/20/solid';
import { SwatchIcon } from '@heroicons/react/24/solid';
import TeamsEditWatermarkForm from '../TeamsEditWatermarkForm/TeamsEditWatermarkForm';
import { useSessionStorage } from '@meilleursbiens/hooks';

interface TeamsEditInfoFormProps {
  team: MBProTeam;
  onTeamUpdated: () => void;
}

export default function TeamsEditInfoForm({ team, onTeamUpdated }: TeamsEditInfoFormProps) {
  const [tabPanel, setTabPanel] = useSessionStorage('mb_teams_edit_info_tab', 0);
  const [currentTeam, setCurrentTeam] = useState<MBProTeam | undefined>(team);

  useEffect(() => {
    if (team !== currentTeam) {
      setCurrentTeam(undefined);
      setTimeout(() => {
        setCurrentTeam(team);
      }, 500);
    }
  }, [team]);

  if (currentTeam === undefined) {
    return <Skeleton height={'300px'} width={'100%'} borderRadius={4} />;
  }

  return (
    <Box w={'100%'}>
      <Tabs variant={'header'} size={'sm'} onChange={setTabPanel} index={tabPanel}>
        <TabList>
          <Tab>Personnalisation</Tab>
          <Tab>Coordonnées</Tab>
          <FeatureDate day={24} month={7} year={2024}>
            <Tab>
              <HStack>
                <Text fontSize={'xs'}>Filigrane</Text>
                <FeatureDate day={24} month={9} year={2024} isBefore>
                  <Badge fontSize={'10px'} colorScheme={'purple'}>Nouveau</Badge>
                </FeatureDate>*
              </HStack>
            </Tab>
          </FeatureDate>
          <Tab>Paramètres</Tab>
        </TabList>

        <TabPanels>
          <TabPanel p={0} pt={1}>
            <UploadFileComponent
              accept={{
                'image/*': ['.jpeg', '.png', '.jpg']
              }}
              isSquareImage={true}
              multiple={false}
              maxSize={4}
              maxFiles={1}
              onUpload={(files) => MBCoreTeamAPI.uploadLogo(team.id, files[0])}
              onUploadComplete={onTeamUpdated}
              renderButtonComponent={({ isOpen, onOpen, onClose }) => (
                <Card
                  p={2}
                  w={'100%'}
                  onClick={onOpen}
                  alignItems={'center'}
                  justifyContent={'space-between'}
                  display={'flex'}
                  flexDirection={'row'}
                >
                  <Persona
                    name={'Logo (format carré)'}
                    nameProps={{
                      fontSize: 'md'
                    }}
                    size={'md'}
                    avatarUrl={
                      team.logo ||
                      'https://mbpro.s3.eu-west-3.amazonaws.com/2f61f2d9-ccfa-4fab-9415-c17f19211e59.png/j2AogneCLjDsLRTtgF7mw8OAyHCoHbEZlY0A0pMV.png'
                    }
                    secondaryText={'Taille min. recommandé : 500x500 - Format: .png'}
                    secondaryTextProps={{
                      fontSize: 'xs'
                    }}
                  />
                  <Button size={'sm'} variant={'primary'} mr={2}>
                    Modifier mon logo
                  </Button>
                </Card>
              )}
            />

            <Spacer my={2} />

            <UploadFileComponent
              accept={{
                'image/*': ['.jpeg', '.png', '.jpg']
              }}
              multiple={false}
              maxSize={4}
              maxFiles={1}
              onUpload={(files) => MBCoreTeamAPI.uploadProfilePicture(team.id, files[0])}
              onUploadComplete={onTeamUpdated}
              renderButtonComponent={({ isOpen, onOpen, onClose }) => (
                <Card
                  p={2}
                  w={'100%'}
                  onClick={onOpen}
                  alignItems={'center'}
                  justifyContent={'space-between'}
                  display={'flex'}
                  flexDirection={'row'}
                >
                  <Persona
                    name={'Logo (format rectangulaire)'}
                    nameProps={{
                      fontSize: 'md'
                    }}
                    size={'md'}
                    avatarUrl={
                      team.image ||
                      'https://mbpro.s3.eu-west-3.amazonaws.com/2f61f2d9-ccfa-4fab-9415-c17f19211e59.png/j2AogneCLjDsLRTtgF7mw8OAyHCoHbEZlY0A0pMV.png'
                    }
                    secondaryText={'Format: .png - Facultatif'}
                    secondaryTextProps={{
                      fontSize: 'xs'
                    }}
                  />
                  <Button size={'sm'} variant={'primary'} mr={2}>
                    Modifier mon logo
                  </Button>
                </Card>
              )}
            />

            <Section size={'xs'} mt={5} title={'Information de l\'équipe / l\'agence'}
                     description={'Ces informations seront affichés sur vos supports : documents, mini-site et annonces. N\'hésitez pas à personnaliser cette section pour la mettre à vos couleurs'}>
              <EditableApiInput
                label={'Nom de l\'équipe'}
                defaultValue={team.name}
                inputType={'text'}
                helperText={'Ce nom sera affiché sur votre espace personnel et sur votre mini-site d\'équipe'}
                editAPI={(element, value) => MBCoreTeamAPI.editTeam(team.id, element, value)}
                icon={(width, color) => <TagIcon width={width} color={color} />}
                element={'name'}
              />

              <EditableApiInput
                label={'Description de l\'équipe'}
                defaultValue={team.description}
                inputType={'textarea'}
                helperText={'Cette adresse sera affichée sur votre mini-site d\'équipe'}
                editAPI={(element, value) => MBCoreTeamAPI.editTeam(team.id, element, value)}
                icon={(width, color) => <ChatBubbleBottomCenterIcon width={width} color={color} />}
                element={'description'}
              />

              <EditableApiInput
                label={'Couleur de l\'équipe'}
                defaultValue={team.color}
                inputType={'color'}
                helperText={'Cette couleur sera affichée sur votre espace personnel et sur votre mini-site d\'équipe'}
                editAPI={(element, value) => MBCoreTeamAPI.editTeam(team.id, element, value)}
                icon={(width, color) => <SwatchIcon width={width} color={color} />}
                element={'color'}
              />
            </Section>
          </TabPanel>
          <TabPanel p={0} pt={1}>
            <EditableApiInput
              label={'Statut juridique'}
              defaultValue={team.society_type}
              inputType={'select'}
              options={[
                { value: 15, label: 'Workspace (équipe)' },
                { value: 3, label: 'Auto-entreprise' },
                { value: 4, label: 'SARL' },
                { value: 5, label: 'SAS' },
                { value: 8, label: 'SCI' },
                { value: 9, label: 'EURL' },
                { value: 10, label: 'EI' },
                { value: 11, label: 'SCA' },
                { value: 12, label: 'SCOP' },
                { value: 13, label: 'SCS' },
                { value: 14, label: 'SASU' }
              ]}
              helperText={'Ce statut sera affiché sur votre espace personnel et sur vos factures'}
              editAPI={(element, value) => MBCoreTeamAPI.editTeam(team.id, element, value)}
              icon={(width, color) => <TagIcon width={width} color={color} />}
              element={'society_type'}
            />

            <EditableApiInput
              label={'Téléphone de l\'équipe'}
              defaultValue={team.phone}
              inputType={'tel'}
              helperText={'Ce numéro sera affiché dans l\'annuaire du portail et permettra aux utilisateurs de vous contacter'}
              editAPI={(element, value) => MBCoreTeamAPI.editTeam(team.id, element, value)}
              icon={(width, color) => <PhoneIcon width={width} color={color} />}
              element={'phone'}
            />

            <Section mt={5} size={'xs'} title={'Adresse du siège social'}
                     description={'Merci de bien renseigner cette adresse pour que nous puissions personnaliser vos informations sur les supports de communication'}>
              <EditableApiInput
                label={'Adresse du siège social'}
                defaultValue={team.address}
                inputType={'text'}
                helperText={'Cette adresse restera privée, elle nous permettra de créeer vos factures'}
                editAPI={(element, value) => MBCoreTeamAPI.editTeam(team.id, element, value)}
                icon={(width, color) => <MapPinIcon width={width} color={color} />}
                element={'address'}
              />

              <EditableApiInput
                label={'Ville du siège social'}
                defaultValue={team.city}
                inputType={'text'}
                helperText={'Cette adresse restera privée, elle nous permettra de créeer vos factures'}
                editAPI={(element, value) => MBCoreTeamAPI.editTeam(team.id, element, value)}
                icon={(width, color) => <MapPinIcon width={width} color={color} />}
                element={'city'}
              />

              <EditableApiInput
                label={'Code postal du siège social'}
                defaultValue={team.postal_code}
                inputType={'text'}
                helperText={'Cette adresse restera privée, elle nous permettra de créeer vos factures'}
                editAPI={(element, value) => MBCoreTeamAPI.editTeam(team.id, element, value)}
                icon={(width, color) => <MapPinIcon width={width} color={color} />}
                element={'postal_code'}
              />

              <EditableApiInput
                label={'Pays du siège social'}
                defaultValue={team.country}
                inputType={'text'}
                helperText={'Cette adresse restera privée, elle nous permettra de créeer vos factures'}
                editAPI={(element, value) => MBCoreTeamAPI.editTeam(team.id, element, value)}
                icon={(width, color) => <MapPinIcon width={width} color={color} />}
                element={'country'}
              />
            </Section>
          </TabPanel>
          <FeatureDate day={24} month={7} year={2024}>
            <TabPanel p={0} pt={1}>
              <TeamsEditWatermarkForm team={team} onTeamUpdated={onTeamUpdated} />
            </TabPanel>
          </FeatureDate>
          <TabPanel p={0} pt={1}>
            <EditableApiInput
              label={'Garder mon équipe privée'}
              defaultValue={team.is_private}
              inputType={'onoff'}
              helperText={'Vous pouvez rendre votre équipe privée pour qu\'elle n\'apparaisse pas dans l\'annuaire du portail'}
              editAPI={(element, value) => MBCoreTeamAPI.editTeam(team.id, element, value)}
              icon={(width, color) => <LockClosedIcon width={width} color={color} />}
              element={'is_private'}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
}
