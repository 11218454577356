import { LegacyRef, MutableRefObject, useCallback, useEffect, useState } from 'react';

const useSelection = (ref: LegacyRef<HTMLTextAreaElement> | MutableRefObject<any>) => {
  const [isInitialized, setIsInitialized] = useState(false);
  const [isInSelection, setIsInSelection] = useState(false);
  const [currentSelection, setCurrentSelection] = useState('');
  const [range, setRange] = useState<Range | null>(null);

  useEffect(() => {
    if (isInitialized) return;
    if (!ref) return;

    // @ts-ignore
    ref.current.addEventListener('select', () => {
      const selection = window.getSelection()?.toString() ?? '';
      console.log('selection', selection);
      setCurrentSelection(selection);
      setIsInSelection(selection.length > 0);
      setRange(window.getSelection()?.getRangeAt(0) ?? null);
    });

    // @ts-ignore
    ref.current.addEventListener('pointerup', () => {
      setIsInSelection(false);
    });

    // @ts-ignore
    ref.current.addEventListener('keyup', () => {
      if (isInSelection) {
        setIsInSelection(false);
        setCurrentSelection('');
      }
    });

    setIsInitialized(true);
  }, [ref]);

  return {
    currentSelection,
    setCurrentSelection,
    isTextSelected: isInSelection,
    range,
  };
};

export default useSelection;
