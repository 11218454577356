export default {
  baseStyle: {
    borderRadius: 5,
    px: 2,
    paddingTop: 0.5,
    paddingBottom: 0.5,
    fontWeight: 600,
    textTransform: 'none',
  },
  variants: {},
};
