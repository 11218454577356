import React from 'react';

import {
  ButtonProps,
  Button as ChakraButton,
  Divider,
  HStack,
  forwardRef,
  useColorModeValue,
  useToken,
} from '@chakra-ui/react';
import { useTeamPermission } from '@meilleursbiens/permissions';

export interface IButtonProps extends Omit<Omit<ButtonProps, 'rightIcon'>, 'leftIcon'> {
  cooldown?: number;
  customLeftIcon?: React.ReactElement;
  customRightIcon?: React.ReactElement;
  leftIcon?: React.ForwardRefExoticComponent<
    React.PropsWithoutRef<React.SVGProps<SVGSVGElement>> & {
      title?: string;
      titleId?: string;
    } & React.RefAttributes<SVGSVGElement>
  >;
  rightIcon?: React.ForwardRefExoticComponent<
    React.PropsWithoutRef<React.SVGProps<SVGSVGElement>> & {
      title?: string;
      titleId?: string;
    } & React.RefAttributes<SVGSVGElement>
  >;
  permissions?: string[];
  iconColor?: string;
}

const Button = forwardRef<IButtonProps, 'button'>((props: IButtonProps, ref) => {
  const [isCooldown, setIsCooldown] = React.useState(false);

  const [gray600, gray50] = useToken('colors', ['gray.700', 'gray.200']);

  const { hasPermissions } = useTeamPermission(props.permissions != undefined);

  const iconColor = props.iconColor ? props.iconColor : useColorModeValue(gray600, gray50);
  const sizeToPx = () => {
    switch (props.size) {
      case 'xs':
        return 12;
      case 'sm':
        return 15;
      case 'md':
        return 17;
      case 'lg':
        return 19;
      case 'xl':
        return 21;
      default:
        return 15;
    }
  };

  const pxSize = sizeToPx();

  const isDisabledPermissions = props.permissions ? !hasPermissions(props.permissions) : false;
  const isDisabled = props.isDisabled || isDisabledPermissions;

  if (props.cooldown) {
    return (
      <>
        {/* @ts-ignore */}
        <ChakraButton
          ref={ref}
          {...props}
          isDisabled={isCooldown || isDisabled}
          leftIcon={
            props.leftIcon ? (
              React.createElement(props.leftIcon, {
                color: iconColor,
                width: pxSize,
                height: pxSize,
                strokeWidth: 2,
              })
            ) : props.customLeftIcon ? (
              props.customLeftIcon
            ) : (
              <></>
            )
          }
          rightIcon={
            props.rightIcon ? (
              React.createElement(props.rightIcon, {
                color: iconColor,
                width: pxSize,
                height: pxSize,
                strokeWidth: 2,
              })
            ) : props.customRightIcon ? (
              props.customRightIcon
            ) : (
              <></>
            )
          }
          onClick={(e) => {
            setIsCooldown(true);
            if (typeof props.onClick === 'function') props.onClick(e);
            setTimeout(() => {
              setIsCooldown(false);
            }, (props.cooldown || 5) * 1000);
          }}
        >
          {props.children}
        </ChakraButton>
      </>
    );
  }

  return (
    <ChakraButton
      ref={ref}
      {...props}
      isDisabled={isDisabled}
      leftIcon={
        props.leftIcon ? (
          React.createElement(props.leftIcon, {
            color: props.iconColor ? props.iconColor : 'inherit',
            width: pxSize,
            height: pxSize,
            strokeWidth: 2,
          })
        ) : props.customLeftIcon ? (
          props.customLeftIcon
        ) : (
          <></>
        )
      }
      rightIcon={
        props.rightIcon ? (
          <HStack>
            <Divider orientation="vertical" />
            {React.createElement(props.rightIcon, {
              color: props.iconColor ? props.iconColor : 'inherit',
              width: pxSize,
              height: pxSize,
              strokeWidth: 2,
            })}
          </HStack>
        ) : props.customRightIcon ? (
          props.customRightIcon
        ) : (
          <></>
        )
      }
      {...(props.rightIcon && {
        pl: 2,
      })}
    >
      {props.children}
    </ChakraButton>
  );
});

export default Button;
