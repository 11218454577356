import React from 'react';

import { Box, SlideFade, Text, useColorModeValue } from '@chakra-ui/react';
import { CloudIcon, XMarkIcon } from '@heroicons/react/24/solid';

export interface NetworkStatusIndicatorProps {}

const getOnLineStatus = () =>
  typeof navigator !== 'undefined' && typeof navigator.onLine === 'boolean' ? navigator.onLine : true;

const NetworkStatusIndicator = (props: NetworkStatusIndicatorProps) => {
  const [status, setStatus] = React.useState(getOnLineStatus());

  const setOnline = () => setStatus(true);
  const setOffline = () => setStatus(false);

  React.useEffect(() => {
    window.addEventListener('online', setOnline);
    window.addEventListener('offline', setOffline);

    return () => {
      window.removeEventListener('online', setOnline);
      window.removeEventListener('offline', setOffline);
    };
  }, []);

  return (
    <Box
      as={SlideFade}
      in={!status}
      position={'fixed'}
      zIndex={!status ? 9999 : -1}
      right={3}
      top={3}
      bg={useColorModeValue('white', 'gray.800')}
      display={'flex'}
      justifyContent={'space-between'}
      alignItems={'center'}
      px={3}
      py={2}
      shadow={'base'}
      borderRadius={'md'}
      maxW={'290px'}
      border={'1px solid'}
      borderColor={useColorModeValue('red.200', 'red.700')}
    >
      <Box position={'relative'} mr={3}>
        <CloudIcon fill={'#BB2030'} width={20} />
        <XMarkIcon
          style={{
            position: 'absolute',
            top: 5,
            left: 4,
          }}
          strokeWidth={3}
          fill={'#fff'}
          width={12}
        />
      </Box>

      <Text fontWeight={500} fontSize={'sm'}>
        Votre connexion internet est instable
      </Text>
    </Box>
  );
};

export default NetworkStatusIndicator;
