import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';

import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay } from '@chakra-ui/react';

import { closeMandatModal } from '../../../../redux/actions/modals/ModalActions';
import TransactionRetourMandatFormComponent from '../../../Forms/Transactions/TransactionRetourMandatFormComponent';

class RetourMandatModalComponent extends Component {
  render() {
    return (
      <Modal isOpen={this.props.modalMandat} size={'2xl'} onClose={() => this.props.closeMandatModal()}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Retourner un mandat</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <article className="message">
              <div className="message-body">
                <p className="has-text-primary">Toutes les pages du mandat sont a scanner de façon lisibles.</p>
                <br />
                <p className="has-text-primary has-text-weight-bold">
                  Vous disposez d’un délais de 15jours après la réservation du numéro de mandat pour télécharger votre
                  mandat, passé ce delais votre acces a la reservation de mandat sera bloqué.
                </p>
                <br />
                <p>
                  Pour suivre l’avancée de la validation de vos mandats, rendez-vous sur PROTEXA, onglet STATISTIQUES.
                </p>
              </div>
            </article>

            <TransactionRetourMandatFormComponent
              onClose={() => {
                this.props.closeMandatModal();
              }}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    closeMandatModal: (data) => {
      dispatch(closeMandatModal(data));
    },
  };
};

const mapStateToProps = (state) => ({
  modalMandat: state.modals.modalMandat,
});

export default connect(mapStateToProps, mapDispatchToProps)(RetourMandatModalComponent);
