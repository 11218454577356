import { useEffect, useState } from 'react';

import { MBCoreTeamAPI } from '@meilleursbiens/api';
import { MBProTeam } from '@meilleursbiens/types';

export default function useTeam() {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [teams, setTeams] = useState<MBProTeam[]>([]);
  const [selectedTeam, setSelectedTeam] = useState<Partial<MBProTeam>>({
    name: 'Chargement...',
    description: '',
  });
  const [membersNb, setMembersNb] = useState<number>(0);

  const _fetchTeams = async () => {
    MBCoreTeamAPI.getTeams()
      .then((response) => {
        setTeams(response.teams);
        if (response.current_team) setSelectedTeam(response.current_team);
        setMembersNb(response.current_team_members);
      })
      .catch((error) => console.log(error))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    _fetchTeams();
  }, []);

  return {
    isLoading,
    teams,
    currentTeam: selectedTeam,
    membersNb,
    _fetchTeams,
  };
}
