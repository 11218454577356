import { useEffect, useState } from 'react';

import { MBCoreTenantsAPI } from '@meilleursbiens/api';
import { MBCoreTenantFeatureAPIResponse } from '@meilleursbiens/types';

export default function useTenantFeature() {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [tenantFeatures, setTenantFeatures] = useState<MBCoreTenantFeatureAPIResponse>({});

  const fetchTenantFeatures = async () => {
    setIsLoading(true);
    MBCoreTenantsAPI.listFeatures()
      .then((response) => {
        setTenantFeatures(response);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchTenantFeatures();
  }, []);

  const isFeatureEnabled = (feature: string): boolean => {
    if (isLoading) return true;
    return tenantFeatures[feature];
  };

  const isOneOfFeatureEnabled = (featureA: string, featureB: string): boolean => {
    return isFeatureEnabled(featureA) || isFeatureEnabled(featureB);
  };

  const isAllFeatureEnabled = (featureA: string, featureB: string): boolean => {
    return isFeatureEnabled(featureA) && isFeatureEnabled(featureB);
  };

  return {
    isLoading,
    fetchTenantFeatures,
    isFeatureEnabled,
    isOneOfFeatureEnabled,
    isAllFeatureEnabled,
  };
}
