import React from 'react';

import { Badge as ChakraBadge, BadgeProps as ChakraBadgeProps, forwardRef } from '@chakra-ui/react';

export interface BadgeProps extends ChakraBadgeProps {}

const Badge = forwardRef<ChakraBadgeProps, 'span'>((props: BadgeProps, ref) => {
  return <ChakraBadge ref={ref} data-tag="allowRowEvents" {...props} style={{ display: 'inline-block' }} />;
});

export default Badge;
