import { useEffect } from 'react';

import { MBCoreTenantAPI } from '@meilleursbiens/api';
import { useSessionStorage } from '@meilleursbiens/hooks';

export const useLogo = () => {
  const [logo, setLogo] = useSessionStorage<string>('mb_core_custom_logo', null);

  const _fetchAPILogo = async () => {
    try {
      // @ts-ignore
      const { logo, name } = await MBCoreTenantAPI.current();
      setLogo(logo);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (logo == null) _fetchAPILogo();
  }, []);

  return logo;
};
