import * as React from 'react';

import { ExternalLinkIcon } from '@chakra-ui/icons';
import { Link } from '@chakra-ui/react';

export default (decoratedHref: string, decoratedText: string, key: number): React.ReactNode => {
  return (
    <Link color="red.500" href={decoratedHref} key={key} isExternal>
      {decoratedText} <ExternalLinkIcon mx="2px" />
    </Link>
  );
};
