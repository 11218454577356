import React, { useEffect, useState } from 'react';

import { useDebounce } from 'use-debounce';

import { CloseIcon } from '@chakra-ui/icons';
import {
  Avatar,
  Box,
  Button,
  ButtonGroup,
  Flex,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { ChevronRightIcon, EnvelopeIcon as MailIcon, UserIcon } from '@heroicons/react/24/outline';
import { MBCoreSearchAPI } from '@meilleursbiens/api';
import { MBProUser } from '@meilleursbiens/types';
import { Card } from '@meilleursbiens/ui';
import { ToastUtils } from '@meilleursbiens/utils';

interface TeamsSearchInviteAgentInputProps {
  defaultAgentSelected?: MBProUser | null;
  onSelectUser: (user: MBProUser | null) => void;
  onConfirm: (user: MBProUser) => void;
  isLoadingProp?: boolean;
}

export default function TeamsSearchInviteAgentInput({
  onSelectUser,
  defaultAgentSelected,
  onConfirm,
  isLoadingProp = false,
}: TeamsSearchInviteAgentInputProps) {
  const [isOpen, setIsOpen] = useState(false);

  const [isLoading, setLoading] = useState(true);
  const [agents, setAgents] = useState<MBProUser[]>([]);

  const [searchValue, setSearchValue] = useState('');
  const [searchValueDebounce] = useDebounce(searchValue, 200);

  const [selectedAgent, setSelectedAgent] = useState<MBProUser | undefined>(undefined);

  const _search = (value: string) => {
    MBCoreSearchAPI.searchAgent(value)
      .then((response) => {
        setAgents(response.users);
        if (response.users.length > 0) {
          setIsOpen(true);
        }
      })
      .catch((e) => ToastUtils.showError(e));
  };

  useEffect(() => {
    if (searchValueDebounce == '') {
      setIsOpen(false);
      return;
    }
    _search(searchValueDebounce);
  }, [searchValueDebounce]);

  useEffect(() => {
    if (defaultAgentSelected) setSelectedAgent(defaultAgentSelected);
  }, []);

  if (selectedAgent) {
    return (
      <Card w={'100%'} px={2} py={2}>
        <Flex align={'center'} justify={'space-between'}>
          <HStack>
            <Avatar
              size={'sm'}
              name={selectedAgent.firstName + ' ' + selectedAgent.name}
              src={selectedAgent.profile_picture}
            />
            <Text fontSize={12} fontWeight={'bold'}>
              {selectedAgent.firstName + ' ' + selectedAgent.name}
            </Text>
          </HStack>
          <ButtonGroup size={'sm'}>
            <IconButton
              aria-label={'Supprimer'}
              variant={'ghost'}
              icon={<CloseIcon />}
              onClick={() => {
                setSelectedAgent(undefined);
                setIsOpen(false);
              }}
            />
            <Button
              leftIcon={<MailIcon width={17} />}
              onClick={() => onConfirm(selectedAgent)}
              isLoading={isLoadingProp}
            >
              Inviter l'agent
            </Button>
          </ButtonGroup>
        </Flex>
      </Card>
    );
  }

  return (
    <>
      <Popover
        returnFocusOnClose={false}
        autoFocus={false}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        placement="bottom"
        closeOnBlur={true}
      >
        <PopoverTrigger>
          <InputGroup size={'sm'}>
            <InputLeftElement
              pointerEvents="none"
              children={<UserIcon color={useColorModeValue('#000', '#fff')} width={15} />}
            />
            <Input type="text" placeholder="Rechercher un agent..." onChange={(e) => setSearchValue(e.target.value)} />
          </InputGroup>
        </PopoverTrigger>
        <PopoverContent>
          <PopoverBody style={{ padding: 0 }}>
            {agents.map((agent, index) => {
              if (index < 3) {
                return (
                  <Box
                    key={index}
                    className={'agent-search-box-item'}
                    onClick={() => {
                      onSelectUser(agent);
                      setSelectedAgent(agent);
                      setIsOpen(false);
                    }}
                  >
                    <Flex w={'100%'} justify={'space-between'}>
                      <HStack>
                        <Avatar size={'sm'} name={agent.firstName + ' ' + agent.name} src={agent.profile_picture} />
                        <Text fontSize={12} fontWeight={'bold'}>
                          {agent.firstName + ' ' + agent.name}
                        </Text>
                      </HStack>
                      <ChevronRightIcon color={'#e7e7e7'} width={15} />
                    </Flex>
                  </Box>
                );
              }
            })}
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </>
  );
}
