import React, { useEffect } from 'react';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import {
  Badge,
  Flex,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
  useDisclosure,
} from '@chakra-ui/react';
import { PaperAirplaneIcon } from '@heroicons/react/24/solid';
import { MBProAdminSignatureAPI, MBProSignatureAPI } from '@meilleursbiens/api';
import { MBProUserSignature } from '@meilleursbiens/types';
import { Button, FileInput, TextAreaInput, TextInput } from '@meilleursbiens/ui';
import { ToastUtils } from '@meilleursbiens/utils';

import { MBProUsersSignatureCardComponent } from './index';

interface MBProAdminUsersSignatureUICreateModalProps {
  disclosure: ReturnType<typeof useDisclosure>;
  userId: number;
}

const ValidationSchema = Yup.object().shape({
  title: Yup.string().required('Le titre est requis'),
  message: Yup.string().required('Le message est requis'),
  file_name: Yup.string().required('Le nom du fichier est requis'),
});

export default function MBProAdminUsersSignatureUICreateDocumentModal(
  props: MBProAdminUsersSignatureUICreateModalProps
) {
  const { isOpen, onOpen, onClose } = props.disclosure;

  const [isLoading, setIsLoading] = React.useState(false);

  const formik = useFormik({
    initialValues: {
      title: '',
      message: '',
      file_name: '',
    },
    validationSchema: ValidationSchema,
    onSubmit: (values) => {
      setIsLoading(true);
      // @ts-ignore
      MBProAdminSignatureAPI.askDocument(props.userId, values.title, values.message, values.file_name)
        .then((response) => {
          ToastUtils.showSuccess("La demande a été envoyée avec succès à l'agent");
        })
        .finally(() => {
          setIsLoading(false);
          onClose();
        })
        .catch((error) => {
          ToastUtils.showError(error);
        });
    },
  });

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <HStack>
            <Badge colorScheme={'purple'}>DOCUMENT</Badge>
            <Text>Demander un document</Text>
          </HStack>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text mb={4} color={'muted'} fontSize={'sm'}>
            Vous pouvez maintenant demander à l'agent un document que vous souhaitez qu'il envoi, une fenêtre
            s'affichera sur son espace le sollicitant à envoyer le document rapidement
          </Text>

          <form onSubmit={formik.handleSubmit}>
            <VStack spacing={3}>
              <TextInput
                label={'Titre de la demande'}
                name={'title'}
                isRequired
                placeholder={'ex: Signature de votre avenant 70%'}
                helperText={"Il sera affiché à l'agent comme titre de la demande"}
                errors={formik.errors}
                values={formik.values}
                touched={formik.touched}
                handleBlur={formik.handleBlur}
                handleChange={formik.handleChange}
              />
              <TextAreaInput
                label={'Description de la demande'}
                name={'message'}
                isRequired
                placeholder={'ex: Pour pouvoir passer à 70% de commission, veuillez signer cet avenant'}
                helperText={"Il sera affiché à l'agent comme description de la demande"}
                errors={formik.errors}
                values={formik.values}
                touched={formik.touched}
                handleBlur={formik.handleBlur}
                handleChange={formik.handleChange}
              />
              <TextInput
                label={'Nom du document à faire signer'}
                name={'file_name'}
                isRequired
                placeholder={'ex: Avenant 70% de commission'}
                helperText={"Il sera affiché ensuite dans les documents de l'agent"}
                helperTextProps={{ color: 'red.500' }}
                errors={formik.errors}
                values={formik.values}
                touched={formik.touched}
                handleBlur={formik.handleBlur}
                handleChange={formik.handleChange}
              />
            </VStack>
          </form>
        </ModalBody>

        <ModalFooter>
          <Flex justify={'flex-end'}>
            <Button size={'sm'} variant={'outline'} onClick={onClose}>
              Annuler
            </Button>
            <Button
              size={'sm'}
              variant={'primary'}
              ml={2}
              onClick={() => formik.handleSubmit()}
              rightIcon={PaperAirplaneIcon}
              isLoading={isLoading}
            >
              Envoyer la demande
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
