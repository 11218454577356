import * as React from 'react';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';

import { useTeamPermission } from '@meilleursbiens/permissions';
import { TeamsSwitchMenu, useTeam } from '@meilleursbiens/teams';
import { useTenant, useTenantFeature } from '@meilleursbiens/tenants';
import { MBProUser } from '@meilleursbiens/types';
import {
  IconButton,
  NavGroup,
  NavItem,
  Persona,
  Sidebar,
  SidebarOverlay,
  SidebarSection,
  SidebarToggleButton,
  Toolbar,
  useSidebarToggleButton,
} from '@meilleursbiens/ui';

import {
  Box,
  Divider,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Portal,
  SimpleGrid,
  Spacer,
  useColorMode,
  useColorModeValue,
} from '@chakra-ui/react';
import {
  ArrowPathIcon,
  ArrowRightOnRectangleIcon,
  ArrowUturnLeftIcon,
  ArrowsPointingInIcon,
  ArrowsPointingOutIcon,
  Bars4Icon,
  ClipboardIcon,
  EllipsisHorizontalCircleIcon,
  ArrowsPointingOutIcon as LogoutIcon,
  MoonIcon,
  SunIcon,
} from '@heroicons/react/24/outline';

import { ACCOUNT_NAVIGATION_V2 } from '../../../../constants/ACCOUNT_NAVIGATION';
import { Routes } from '../../../../routes/routes';
import LocalStorageUtils from '../../../../utils/LocalStorageUtils';
import AccountSearchBarComponent from '../AccountSearchBarComponent';
import IconSidebarComponent from './Icons/IconSidebarComponent';

export interface AccountSidebarLayoutComponentProps {
  onDeviceChange: (isMobile: boolean) => void;
  user?: MBProUser;
  onOpenUsefullLinksModal: () => void;
  onSearch: () => void;
}

function AccountSidebarLayoutComponent(props: AccountSidebarLayoutComponentProps) {
  const location = useLocation();

  const { tenant } = useTenant();
  const { currentTeam } = useTeam();
  const { isFeatureEnabled } = useTenantFeature();

  const { hasPermission, isLoadingPermissions } = useTeamPermission();

  const { colorMode, toggleColorMode } = useColorMode();

  const { isMobile, isOpen, toggleSidebar } = useSidebarToggleButton();

  useEffect(() => {
    props.onDeviceChange(isMobile);
  }, []);

  useEffect(() => {
    props.onDeviceChange(isMobile);
  }, [isMobile]);

  return (
    <Sidebar
      variant={isOpen ? 'default' : isMobile ? 'default' : 'default'}
      transition="width"
      transitionDuration="normal"
      minWidth="auto"
      pt={0}
      pb={0}
    >
      {!isOpen && isMobile ? (
        <SidebarToggleButton isRound={true} icon={<Bars4Icon width={20} strokeWidth={2} />} />
      ) : null}

      <SidebarSection flex="1" overflowY="auto" overflowX="hidden" style={{ marginTop: 0 }} px={0}>
        <Toolbar h={'58px'} position={'sticky'} top={0}>
          <Persona
            size={'sm'}
            name={currentTeam.is_digital == 0 ? tenant.name : currentTeam.name}
            secondaryText={currentTeam.is_digital == 0 ? tenant.domain : currentTeam.name}
            avatarUrl={currentTeam.is_digital == 0 ? tenant.logo_square : currentTeam.logo}
            avatarProps={{
              borderRadius: 'md',
              boxShadow: 'base',
              borderWidth: '1px',
              borderColor: useColorModeValue('gray.50', 'gray.600'),
            }}
          />

          <Spacer />

          <Menu strategy={'fixed'} closeOnSelect={false} isLazy={true}>
            <MenuButton
              autoFocus={false}
              as={IconButton}
              icon={EllipsisHorizontalCircleIcon}
              variant={'ghost'}
              size={'md'}
            />
            <Portal>
              <MenuList>
                <MenuItem
                  icon={
                    colorMode === 'dark' ? (
                      <MoonIcon width={17} strokeWidth={2} />
                    ) : (
                      <SunIcon width={17} strokeWidth={2} />
                    )
                  }
                  onClick={toggleColorMode}
                >
                  Mode {colorMode === 'dark' ? 'sombre' : 'clair'}
                </MenuItem>
                <MenuDivider />
                <MenuItem
                  icon={
                    isOpen ? (
                      <ArrowsPointingInIcon width={17} strokeWidth={2} />
                    ) : (
                      <ArrowsPointingOutIcon width={17} strokeWidth={2} />
                    )
                  }
                  onClick={toggleSidebar}
                >
                  {isOpen ? 'Réduire' : 'Agrandir'} le menu
                </MenuItem>
                <MenuItem icon={<ArrowPathIcon width={17} strokeWidth={2} />} onClick={() => window.location.reload()}>
                  Recharger la page
                </MenuItem>
                <MenuDivider />
                <MenuItem
                  as={Link}
                  to={Routes.PRO_DASHBOARD.url}
                  icon={<ArrowUturnLeftIcon width={17} strokeWidth={2} />}
                >
                  Retour à mon espace
                </MenuItem>
                <MenuItem
                  icon={<ArrowRightOnRectangleIcon width={17} strokeWidth={2} />}
                  onClick={() => {
                    LocalStorageUtils.clear();
                    window.location.reload();
                  }}
                >
                  Se déconnecter
                </MenuItem>
              </MenuList>
            </Portal>
          </Menu>
        </Toolbar>
        <Box px={3} py={2} pb={5}>
          <AccountSearchBarComponent onSearch={props.onSearch} isSidebar />
          {ACCOUNT_NAVIGATION_V2.map((l, i) => {
            if (l.isAdmin && props.user.admin !== 1) return null;
            if (l.children) {
              return (
                <NavGroup
                  key={'sidebar-group-' + i}
                  title={l.label}
                  isCollapsible={l.hasTitle}
                  isTitleHidden={!l.hasTitle}
                >
                  {l.children.map((c, i) => {
                    if (c.isAdmin && props.user.admin !== 1) return null;
                    if (c.isBetaTester && props.user.is_beta_tester !== 1) return null;
                    if (c.feature) {
                      if (!isFeatureEnabled(c.feature)) return null;
                    }
                    let hasPermissionLink = false;
                    if (c.permission) {
                      hasPermissionLink = hasPermission(c.permission);
                    } else {
                      hasPermissionLink = true;
                    }
                    return (
                      <NavItem
                        key={'sidebar-item-' + i}
                        id={c.id.replace('.', '-')}
                        icon={<IconSidebarComponent icon={c.id} isActive={location.pathname === c.href} />}
                        badge={c.badge}
                        badgeProps={{
                          bg: 'transparent',
                          variant: 'subtle',
                          fontSize: '9px',
                          color: 'red.500',
                          letterSpacing: 'wider',
                          textTransform: 'uppercase',
                        }}
                        isActive={location.pathname === c.href}
                        as={Link}
                        isDisabled={!hasPermissionLink}
                        to={hasPermissionLink ? c.href : '#'}
                      >
                        {c.label}
                      </NavItem>
                    );
                  })}
                </NavGroup>
              );
            }
          })}
        </Box>
      </SidebarSection>
      <SidebarSection
        py={2}
        borderTop={'1px solid'}
        borderTopColor={useColorModeValue('gray.100', 'gray.700')}
        style={{ marginTop: 0 }}
        boxShadow={'0px -10px 18px -8px rgba(0,0,0,0.05)'}
      >
        <SimpleGrid columns={[1, 2]} spacing={2}>
          <NavItem
            as={Box}
            cursor={'pointer'}
            icon={<IconSidebarComponent icon={'menu.utils_links'} isActive={true} />}
            borderWidth={1}
            boxShadow={'sm'}
            bg={useColorModeValue('red.50', 'red.800')}
            onClick={props.onOpenUsefullLinksModal}
            color={useColorModeValue('red.500', 'white')}
          >
            Liens utiles
          </NavItem>
          {props.user.admin === 1 && (
            <NavItem
              icon={<IconSidebarComponent icon={'menu.back_office'} isActive={false} />}
              as={Link}
              to={Routes.ADMIN_DASHBOARD.url}
            >
              Back-office
            </NavItem>
          )}
          {props.user.admin === 0 && (
            <NavItem
              icon={
                <IconSidebarComponent
                  icon={'menu.support'}
                  isActive={location.pathname.includes(Routes.SUPPORT_PRO_LIST.url)}
                />
              }
              isActive={location.pathname.includes(Routes.SUPPORT_PRO_LIST.url)}
              as={Link}
              to={Routes.SUPPORT_PRO_LIST.url}
            >
              Support client
            </NavItem>
          )}
        </SimpleGrid>

        <Divider borderColor={'transparent'} my={1} />
        <TeamsSwitchMenu
          user={props.user}
          isSquare={false}
          redirectTo={Routes.PRO_DASHBOARD.url}
          subItems={
            <>
              <MenuItem
                icon={<ClipboardIcon width={20} />}
                px={2}
                onClick={() => {
                  LocalStorageUtils.clear();
                  setTimeout(() => {
                    window.location.reload();
                  }, 500);
                }}
              >
                Identifiant : #{props.user.id_proabono}
              </MenuItem>
              <MenuDivider />
              <MenuItem
                icon={<LogoutIcon width={20} />}
                px={2}
                onClick={() => {
                  LocalStorageUtils.clear();
                  setTimeout(() => {
                    window.location.reload();
                  }, 500);
                }}
              >
                Se déconnecter
              </MenuItem>
            </>
          }
        />
      </SidebarSection>
      <SidebarOverlay zIndex="1" />
    </Sidebar>
  );
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps, null)(AccountSidebarLayoutComponent);
