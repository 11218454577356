import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { MBProDocumentsRCPAPI } from '@meilleursbiens/api';
import { MBProUser } from '@meilleursbiens/types';
import { Button, FileInput } from '@meilleursbiens/ui';
import { ToastUtils } from '@meilleursbiens/utils';

import { ExternalLinkIcon } from '@chakra-ui/icons';
import {
  Alert,
  AlertIcon,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react';

import { useFormik } from 'formik';

const YEAR = new Date().getFullYear();

function RCPModalComponent({ user }: { user: Partial<MBProUser> }) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [isLoading, setIsLoading] = React.useState(false);
  const [isSendAfterLocked, setIsSendAfterLocked] = React.useState(true);

  const formik = useFormik({
    initialValues: {
      file: null,
    },
    onSubmit: async (values) => {
      setIsLoading(true);
      MBProDocumentsRCPAPI.upload(values.file)
        .then(() => {
          onClose();
        })
        .catch((e) => {
          ToastUtils.showError(e);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
  });

  useEffect(() => {
    if (user?.is_rcp_locked === 1) onOpen();
  }, [user]);

  useEffect(() => {
    const currentYear = new Date().getFullYear();
    const dateLimit = new Date(currentYear + '-01-15');
    const now = new Date();

    if (now < dateLimit) {
      setIsSendAfterLocked(false);
    } else {
      setIsSendAfterLocked(true);
    }
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        if (!isSendAfterLocked) onClose();
      }}
      closeOnOverlayClick={!isSendAfterLocked}
      closeOnEsc={!isSendAfterLocked}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Demande RCP {YEAR}</ModalHeader>
        {!isSendAfterLocked && <ModalCloseButton />}
        <ModalBody>
          <Alert status="warning" mb={4}>
            <AlertIcon />
            Vous avez jusqu’au 15/01 pour télécharger votre RCP {YEAR}, envoyez votre attestation ci-dessous
          </Alert>

          <Alert status="info" mb={4}>
            <AlertIcon />
            <Text color={'muted'}>
              Si vous n'avez pas souscrit à une RCP cliquez sur{' '}
              <Link
                color={'blue.700'}
                fontWeight={600}
                href={'https://www.assurup.com/partners/meilleurs-biens'}
                target={'_blank'}
              >
                Je souscris ma RCP
              </Link>
            </Text>
          </Alert>

          <form onSubmit={formik.handleSubmit}>
            <FileInput
              label={'Ma RCP ' + YEAR}
              name={'file'}
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
              values={formik.values}
              errors={formik.errors}
              touched={formik.touched}
              maxFileSize={5}
              accept={{
                'application/pdf': ['.pdf'],
                'image/png': ['.png'],
                'image/jpeg': ['.jpeg', '.jpg'],
              }}
              setFieldValue={formik.setFieldValue}
            />
          </form>
        </ModalBody>

        <ModalFooter>
          <Link
            fontSize={'sm'}
            fontWeight={500}
            variant="ghost"
            mr={3}
            as={'a'}
            href={'https://www.assurup.com/partners/meilleurs-biens'}
            target={'_blank'}
          >
            Je souscris ma RCP <ExternalLinkIcon w={'4'} />
          </Link>
          {formik.values.file == null ? (
            <Button variant={'outline'} isDisabled={isSendAfterLocked} onClick={onClose}>
              Envoyer plus tard
            </Button>
          ) : (
            <Button variant={'primary'} onClick={formik.submitForm}>
              Valider et envoyer
            </Button>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps, null)(RCPModalComponent);
