import React, { ReactNode } from 'react';

import { MBEstimation, MBProUser } from '@meilleursbiens/types';
import { DotBadge } from '@meilleursbiens/ui';

import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  ShieldCheckIcon,
  ShieldExclamationIcon,
  UserPlusIcon as UserAddIcon,
  XCircleIcon,
} from '@heroicons/react/24/outline';

interface IProAbonoInvoiceStatus {
  text: string;
  color: string;
  textColor: string;
}

interface IUserStatus {
  name: string;
  color: string;
  text: string;
  colorScheme: string;
  icon: ReactNode;
}

export default class StatusUtils {
  /**
   * Permet de récupérer le badge de status d'une transaction
   * @param status JSX Badge
   */
  static transactionStatus(status: number) {
    switch (status) {
      case 0:
        return <DotBadge colorScheme={'green'} text={'En-cours'} size={'xs'} />;
      case 1:
        return <DotBadge colorScheme={'yellow.300'} text={'Facturé'} size={'xs'} />;
      case 2:
        return <DotBadge colorScheme={'orange'} text={'Signé'} size={'xs'} />;
      case 3:
        return <DotBadge colorScheme={'gray'} text={'Annulé'} size={'xs'} />;
    }
  }

  static transactionLocationStatusBar(transaction) {
    if (transaction == undefined) return 0;

    switch (transaction.status) {
      case 0:
        return 1;
      case 1:
        return 1;
      case 2:
        return 3;
      case 3:
        return -1;
    }
  }

  static documentStatus(status: number): string {
    switch (status) {
      case 0:
        return 'Privé';
      case 1:
        return 'Privé';
      case 2:
        return 'Public';
      case 3:
        return 'Privé';
      case 4:
        return 'Refusé';
      default:
        return 'Public';
    }
  }

  static taskImportance(status: number): string {
    switch (status) {
      case 0:
        return 'Peu Important';
      case 1:
        return 'Important';
      case 2:
        return 'Urgent';
      default:
        return 'Peu Important';
    }
  }

  static userPack(status: number): string {
    switch (status) {
      case 10:
        return 'Pack Initial';
      case 12:
        return 'Pack+ | Province';
      case 13:
        return 'All inclusive Paris/IDF';
      case 14:
        return 'Agent externe';
      case 15:
        return 'Pack + | Ile de France';
      case 16:
        return 'Pack Light';
      default:
        return 'Pack Initial';
    }
  }

  static proAbonoInvoiceStatus(status: string): IProAbonoInvoiceStatus | undefined {
    switch (status) {
      case 'Draft':
        return {
          text: 'Brouillon',
          color: 'gray.500',
          textColor: 'white',
        };
      case 'PaymentExpected':
        return {
          text: 'En Attente',
          color: 'teal.300',
          textColor: 'white',
        };
      case 'PaymentDue':
        return {
          text: 'En Attente',
          color: 'teal.300',
          textColor: 'white',
        };
      case 'PaymentDuePending':
        return {
          text: 'En Attente',
          color: 'teal.300',
          textColor: 'white',
        };
      case 'PaymentAutoFailed':
        return {
          text: 'Erreur',
          color: 'red.200',
          textColor: 'red.800',
        };
      case 'Overdue':
        return {
          text: 'Retard',
          color: 'red.400',
          textColor: 'red.800',
        };
      case 'Disputed':
        return {
          text: 'Litige',
          color: 'yellow.300',
          textColor: 'yellow.900',
        };
      case 'Paid':
        return {
          text: 'Payé',
          color: 'green.300',
          textColor: 'green.800',
        };
      case 'Refunded':
        return {
          text: 'Remboursé',
          color: 'gray.500',
          textColor: 'white',
        };
      case 'Cancelled':
        return {
          text: 'Annulé',
          color: 'gray.900',
          textColor: 'white',
        };
      case 'MarkedDeleted':
        return {
          text: 'Supprimé',
          color: 'gray.200',
          textColor: 'gray.800',
        };
      case 'Deleted':
        return {
          text: 'Supprimé',
          color: 'gray.200',
          textColor: 'gray.800',
        };
      default:
        return {
          text: 'Inconnu',
          color: 'gray.200',
          textColor: 'gray.800',
        };
    }
  }

  static userStatus(user: MBProUser): IUserStatus {
    if (user.admin == 1) {
      return {
        name: 'Employé',
        color: '#000000',
        text: '#fff',
        colorScheme: 'purple.300',
        icon: <ShieldCheckIcon width={17} />,
      };
    }

    if (user.status == 1) {
      return {
        name: 'Actif',
        color: '#b8ffd8',
        text: '#000',
        colorScheme: 'green.300',
        icon: <CheckCircleIcon width={17} />,
      };
    }

    if (user.status == 2) {
      return {
        name: 'Paiement refusé',
        color: '#fff3b8',
        text: '#875103',
        colorScheme: 'orange.300',
        icon: <ExclamationCircleIcon width={17} />,
      };
    }

    if (user.status == 3) {
      return {
        name: 'Bloqué',
        color: '#de829e',
        text: '#48002f',
        colorScheme: 'red.300',
        icon: <ShieldExclamationIcon width={17} />,
      };
    }

    if (user.status == 4) {
      return {
        name: 'Résilié',
        color: '#de8282',
        text: '#480000',
        colorScheme: 'gray.500',
        icon: <XCircleIcon width={17} />,
      };
    }

    return {
      name: 'Inscription',
      color: '#c4c4c4',
      text: '#000000',
      colorScheme: 'blue.300',
      icon: <UserAddIcon width={17} />,
    };
  }

  static estimationType(estimation: MBEstimation): string {
    switch (estimation.type) {
      case 1:
        return 'Maison';
      default:
        return 'Appartement';
    }
  }

  static estimationSurface(estimation: MBEstimation): string {
    switch (estimation.type) {
      case 1:
        return estimation.surface_habitable + 'm²';
      default:
        return estimation.surface_carrez + 'm²';
    }
  }
}
