import { transparentize } from '@chakra-ui/theme-tools';

import colors from './colors';

const shadows = {
  xs: 'rgb(0 0 0 / 7%) 0px 1px 1px',
  'dark-xs': 'rgb(0 0 0 / 7%) 0px 1px 1px',
  outline: `0 0 0 2px ${transparentize(colors.primary[500], 0.6)({ colors })}`,
  '3d': 'rgba(50, 50, 93, 0.10) 0px 50px 100px -20px, rgba(0, 0, 0, 0.1) 0px 30px 60px -30px, rgba(10, 37, 64, 0.2) 0px -2px 6px 0px inset',
};

export default shadows;
