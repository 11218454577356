import React from 'react';
// @ts-ignore
import { useSpeechRecognition } from 'react-speech-kit';
import ResizeTextarea from 'react-textarea-autosize';

import {
  FormControl,
  FormHelperText,
  FormLabel,
  IconButton,
  Stack,
  Text,
  Textarea,
  Tooltip,
  forwardRef,
  useColorModeValue,
} from '@chakra-ui/react';
import { MicrophoneIcon } from '@heroicons/react/24/outline';
import { XMarkIcon as XIcon } from '@heroicons/react/24/solid';

import TextInput, { TextInputProps } from '../TextInput/TextInput';

export interface TextAreaInputProps extends Omit<Omit<TextInputProps, 'handleChange'>, 'handleBlur'> {
  handleChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  handleBlur: (e: React.FocusEvent<HTMLTextAreaElement>) => void;
}

const TextAreaInput = forwardRef<TextAreaInputProps, 'textarea'>((props: TextAreaInputProps, ref) => {
  return (
    <TextInput
      type="hidden"
      customInput={
        <>
          {/** @ts-ignore */}
          <Textarea
            {...props}
            ref={ref}
            name={props.name}
            focusBorderColor="red.100"
            bg={useColorModeValue('white', 'gray.800')}
            shadow={'base'}
            placeholder={props.placeholder || ''}
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            value={props.values[props.name]}
            as={ResizeTextarea}
          ></Textarea>
        </>
      }
      helperText={props.helperText || ''}
      name={props.name + '_hidden'}
      placeholder={''}
      value={props.values[props.name]}
      errors={props.errors}
      touched={props.touched}
      label={props.label}
      isHorizontal={props.isHorizontal}
      values={props.values}
      handleChange={() => {}}
      handleBlur={() => {}}
    />
  );
});

export default TextAreaInput;
