import React, { useEffect } from 'react';

import { Button, Text, useColorMode } from '@chakra-ui/react';

import Logo from '../components/Logo';

export default function ErrorScreen({ error, version }) {
  const { colorMode } = useColorMode();

  useEffect(() => {
    sessionStorage.clear();
  }, []);

  return (
    <div className="error-screen">
      <Logo isModernWhite={colorMode === 'dark'} isModern={colorMode === 'light'} height={24} />
      <Text fontWeight={600} fontSize={22}>
        Une erreur s'est produite !
      </Text>
      <Text fontSize={12} style={{ maxWidth: 450 }} textAlign={'center'} color={'muted'}>
        Il semble qu'une erreur se soit produite, notre équipe va analyser cette erreur et vous recontacter si besoin.
        Vous pouvez cliquer sur "Retour à l'accueil" juste en dessous pour revenir sur le site.
      </Text>
      <code className={'mt-25'} style={{ maxWidth: 600 }} zIndex={1000}>
        {error.toString()} - {version}
      </code>
      <Button
        mt={10}
        zIndex={100}
        onClick={() => {
          window.location.href = 'https://my.meilleursbiens.com';
        }}
      >
        Retour à MeilleursBiens
      </Button>
    </div>
  );
}
