import { MBCoreTenant, MBCoreTenantFeatureAPIResponse, MBProTeam, MBProTeamInvite } from '@meilleursbiens/types';

import MBProAPI from '../../../MBApi';
import { MBApi } from '../../../index';

export default class MBCoreTenantsAPI {
  static current() {
    return MBProAPI.url('/v1/core/tenants/current')
      .needCache(15, 'MBCoreTenantsAPI.current')
      .needAuth()
      .get<MBCoreTenant>();
  }

  static listFeatures() {
    return MBApi.url('/v1/core/tenants/features')
      .needAuth()
      .needCache(15, 'MBCoreTenantsAPI.listFeatures')
      .get<MBCoreTenantFeatureAPIResponse>();
  }
}
