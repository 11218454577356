import { MBCoreAIPromptCategory } from '@meilleursbiens/types';

import { MBApi } from '../../../index';

export default class MBCoreAIPromptsAPI {
  static list() {
    return MBApi.url('/v1/core/ai/categories/list').needAuth().needCache(60).get<MBCoreAIPromptCategory[]>();
  }

  static execute(id: number, params: any) {
    return MBApi.url('/v1/core/ai/prompt/execute/' + id)
      .needAuth()
      .parameters({
        variables: {
          ...params,
          from: 'mymb',
        },
      })
      .post<string>();
  }

  static executeStream(id: number, params: any, onChunk: (chunk: string) => void, onEnd: (error: number) => void) {
    MBApi.url('/v1/core/ai/prompt/execute-stream/' + id)
      .needAuth()
      .parameters({
        variables: {
          ...params,
          from: 'mymb',
        },
      })
      .streamOpenAI(onChunk, onEnd);
  }
}
