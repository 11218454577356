import * as React from 'react';

import {
  HTMLChakraProps,
  Spacer,
  SystemProps,
  ThemingProps,
  Tooltip,
  TooltipProps,
  chakra,
  forwardRef,
  omitThemingProps,
  useMultiStyleConfig,
} from '@chakra-ui/react';
import { cx, dataAttr } from '@chakra-ui/utils';

import Badge, { BadgeProps } from '../../Components/Badge/Badge';
import { useSidebarContext } from './Sidebar.hook';
import { NavItemStylesProvider, useNavItemStyles } from './SidebarNav.context';

export type NavItemLabelProps = HTMLChakraProps<'span'>;

export const NavItemLabel = forwardRef<NavItemLabelProps, 'span'>(({ children, ...props }, ref) => {
  const styles = useNavItemStyles();
  return (
    <chakra.span
      ref={ref}
      __css={styles.label}
      {...props}
      className={cx('mb_ui_layouts_nav_item__label', props.className)}
    >
      {children}
    </chakra.span>
  );
});

NavItemLabel.displayName = 'NavItemLabel';

const NavItemIcon: React.FC<HTMLChakraProps<'span'>> = (props) => {
  const styles = useNavItemStyles();

  const { className, children, ...rest } = props;

  const child = React.Children.only(children);

  const clone = React.isValidElement<HTMLChakraProps<any>>(child)
    ? React.cloneElement(child, {
        focusable: 'false',
        'aria-hidden': true,
      })
    : null;

  return (
    <chakra.span
      {...rest}
      className={cx('mb_ui_layouts_nav_item__icon', props.className)}
      __css={{
        flexShrink: 0,
        ...styles.icon,
      }}
    >
      {clone}
    </chakra.span>
  );
};

NavItemIcon.displayName = 'NavItemIcon';

export interface NavItemProps extends HTMLChakraProps<'a'>, ThemingProps<'SuiNavItem'> {
  /**
   * The href attribute of the nav item,
   * will be wrapped in a `Link`, if `linkComponent` is configured in SaasProvider.
   */
  href?: string;
  /**
   * Icon to be displayed in the nav item
   */
  icon?:
    | React.ForwardRefExoticComponent<
        React.PropsWithoutRef<React.SVGProps<SVGSVGElement>> & {
          title?: string;
          titleId?: string;
        } & React.RefAttributes<SVGSVGElement>
      >
    | React.ReactElement;
  badge?: string | number;
  badgeProps?: Omit<BadgeProps, 'children'>;
  /**
   * Inset of the item, used for nested items
   */
  inset?: SystemProps['paddingLeft'];
  /**
   * Props to be passed to the tooltip
   * @see Docs https://chakra-ui.com/docs/overlay/tooltip
   */
  tooltipProps?: Omit<TooltipProps, 'children'>;
  /**
   * If `true`, the nav item will be active
   */
  isActive?: boolean;
  isDisabled?: boolean;
}

/**
 * Navigation item used in the sidebar.
 *
 * @see Docs https://saas-ui.dev/docs/components/layout/sidebar
 */
export const NavItem = forwardRef<NavItemProps, 'a'>((props, ref) => {
  const {
    href = '#',
    icon,
    inset,
    className,
    tooltipProps,
    isActive,
    isDisabled,
    children,
    ...rest
  } = omitThemingProps(props);
  const { onClose, variant: sidebarVariant } = useSidebarContext() || {};
  const isCompact = sidebarVariant === 'compact';

  const styles = useMultiStyleConfig('NavItem', props);

  let label = children;
  let tooltipLabel = tooltipProps?.label;
  if (typeof label === 'string') {
    if (!tooltipLabel && isCompact) {
      tooltipLabel = label;
    }
    label = <NavItemLabel>{label}</NavItemLabel>;
  }

  let as;
  if (href && href !== '#') {
    as = 'a';
  }

  const link = (
    <chakra.a
      as={'a'}
      aria-current={isActive ? 'page' : undefined}
      {...rest}
      ref={ref}
      {...(!isDisabled && { href })}
      className="mb_ui_layouts_nav_item__link"
      data-active={dataAttr(isActive)}
      data-disabled={dataAttr(isDisabled)}
      __css={styles.link}
    >
      <chakra.span
        __css={{
          ...styles.inner,
          pl: inset,
        }}
        className="mb_ui_layouts_nav_item__inner"
      >
        {icon && (
          <NavItemIcon>
            {React.isValidElement(icon)
              ? icon
              : // @ts-ignore
                React.createElement(icon, {
                  color: 'currentColor',
                  width: 45,
                  height: 45,
                  strokeWidth: 2,
                })}
          </NavItemIcon>
        )}
        {label}
        {props.badge && (
          <>
            <Spacer />
            <Badge {...props.badgeProps}>{props.badge}</Badge>
          </>
        )}
      </chakra.span>
    </chakra.a>
  );

  return (
    <NavItemStylesProvider value={styles}>
      <Tooltip label={tooltipLabel} placement="right" openDelay={400} {...tooltipProps}>
        <chakra.div
          __css={styles.item}
          onClick={onClose}
          data-compact={dataAttr(isCompact)}
          className={cx('mb_ui_layouts_nav_item', className)}
        >
          {link}
        </chakra.div>
      </Tooltip>
    </NavItemStylesProvider>
  );
});

NavItem.displayName = 'NavItem';
