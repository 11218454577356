import React from 'react';

import { lazyRetry } from '@meilleursbiens/utils';

const MBEstimationProListScreen = React.lazy(() =>
  lazyRetry(() => import('../screens/MBEstimation/Pro/MBEstimationProListScreen'), 'MBEstimationProListScreen')
);
const EstimationProViewScreen = React.lazy(() =>
  lazyRetry(() => import('../screens/MBEstimation/Pro/EstimationProViewScreen'), 'EstimationProViewScreen')
);
const EstimationLeadsScreen = React.lazy(() =>
  lazyRetry(() => import('../screens/MBEstimation/Pro/EstimationLeadsScreen'), 'EstimationLeadsScreen')
);
const EstimationProCreateScreen = React.lazy(() =>
  lazyRetry(() => import('../screens/MBEstimation/Pro/EstimationProCreateScreen'), 'EstimationProCreateScreen')
);
const AdminEstimationLeadsListScreen = React.lazy(() =>
  lazyRetry(
    () => import('../screens/MBEstimation/Admin/Leads/AdminEstimationLeadsListScreen'),
    'AdminEstimationLeadsListScreen'
  )
);
const MBEstimationWidgetScreen = React.lazy(() =>
  lazyRetry(() => import('../screens/MBEstimation/Widget/MBEstimationWidgetScreen'), 'MBEstimationWidgetScreen')
);
const MBEstimationAdminListScreen = React.lazy(() =>
  lazyRetry(() => import('../screens/MBEstimation/Admin/MBEstimationAdminListScreen'), 'MBEstimationAdminListScreen')
);

export default {
  ESTIMATION_WIDGET: {
    url: '/estimation/widget',
    component: MBEstimationWidgetScreen,
    routeType: 'public',
  },
  ESTIMATION_LIST: {
    url: '/pro/estimations',
    component: MBEstimationProListScreen,
    routeType: 'private.validated',
  },
  ESTIMATION_LEADS_LIST: {
    url: '/pro/estimations/leads',
    component: EstimationLeadsScreen,
    routeType: 'private.validated',
  },
  ESTIMATION_VIEW: {
    url: '/pro/estimation/view/:id',
    component: EstimationProViewScreen,
    routeType: 'private.validated',
  },
  ESTIMATION_CREATE: {
    url: '/pro/estimation/create',
    component: EstimationProCreateScreen,
    routeType: 'private.unstyled',
  },
  ADMIN_ESTIMATION_LIST: {
    url: '/admin/estimations',
    component: MBEstimationAdminListScreen,
    routeType: 'admin',
  },
  ADMIN_ESTIMATION_LEADS_LIST: {
    url: '/admin/estimations/leads',
    component: AdminEstimationLeadsListScreen,
    routeType: 'admin',
  },
};
