import React, { useEffect, useState } from 'react';

import {
  Avatar,
  Badge,
  Box,
  Button,
  Divider,
  HStack,
  Skeleton,
  Text,
  VStack,
  useColorModeValue,
} from '@chakra-ui/react';
import { MBCoreProAbonoPermissionAPI, MBCoreTeamAPI } from '@meilleursbiens/api';
import { MBProTeam, MBProUser } from '@meilleursbiens/types';
import { ToastUtils } from '@meilleursbiens/utils';

import { TeamsSearchInviteAgentInput } from '../TeamsSearchInviteAgentInput';

interface TeamsInvitationFormProps {
  team: MBProTeam;
  onTeamUpdated: () => void;
}

export default function TeamsInvitationForm({ team, onTeamUpdated }: TeamsInvitationFormProps) {
  const [isLoading, setLoading] = useState(false);
  const [isLoadingInvite, setLoadingInvite] = useState(false);
  const [isLoadingDelete, setLoadingDelete] = useState(false);
  const [selectedAgent, setSelectedAgent] = useState<MBProUser | undefined>(undefined);
  const [quota, setQuota] = useState<number>(1);

  const borderColor = useColorModeValue('gray.50', 'gray.800');

  const _getQuota = () => {
    MBCoreProAbonoPermissionAPI.quota()
      .then((response) => {
        setQuota(response.quota);
      })
      .catch((e) => ToastUtils.showError(e));
  };

  const _sendInvitation = (user: MBProUser) => {
    setLoadingInvite(true);
    MBCoreTeamAPI.inviteUser(team.id, user.id)
      .then(() => {
        onTeamUpdated();
        ToastUtils.showSuccess("L'invitation a bien été envoyée, l'agent recevra un email pour rejoindre l'équipe.");
        setSelectedAgent(undefined);
      })
      .catch((e) => console.error(e))
      .finally(() => setLoadingInvite(false));
  };

  const _deleteFromTeam = (user: MBProUser) => {
    setLoadingDelete(true);
    MBCoreTeamAPI.deleteUserFromTeam(team.id, user.id)
      .then(() => {
        onTeamUpdated();
        ToastUtils.showSuccess("L'agent n'est désormais plus membre de l'équipe.");
        setSelectedAgent(undefined);
      })
      .catch((e) => ToastUtils.showError(e))
      .finally(() => setLoadingDelete(false));
  };

  useEffect(() => {
    _getQuota();
  }, []);

  useEffect(() => {
    _getQuota();
  }, [team]);

  return (
    <Box w={'100%'}>
      <Text fontSize={12} fontWeight={500} mb={2} color={'muted'}>
        Rechercher et inviter un agent dans votre équipe
      </Text>
      <TeamsSearchInviteAgentInput
        isLoadingProp={isLoadingInvite}
        onSelectUser={(user) => {
          if (user) setSelectedAgent(user);
        }}
        onConfirm={(user) => _sendInvitation(user)}
      />

      <Divider mt={3} mb={3} />

      <HStack mb={2}>
        <Text fontSize={14} fontWeight={600}>
          Liste des membres de l'équipe
        </Text>
        <Text color="muted" fontWeight={600} fontSize={13}>
          ({team?.users.length}/{quota})
        </Text>
      </HStack>

      {isLoading && (
        <>
          <Skeleton height="50px" mb={2} />
          <Skeleton height="50px" mb={2} />
          <Skeleton height="50px" mb={2} />
        </>
      )}

      {team && (
        <>
          {team.users.map((user) => (
            <Box
              key={user.id}
              display={'flex'}
              alignItems={'center'}
              justifyContent={'space-between'}
              h={'50px'}
              borderBottomWidth={1}
              borderBottomColor={borderColor}
            >
              <HStack>
                <Avatar size={'sm'} name={user.firstName + ' ' + user.name} src={user.profile_picture} />
                <VStack alignItems={'flex-start'}>
                  <Text fontSize={12} fontWeight={'bold'} style={{ marginBottom: 0 }}>
                    {user.firstName + ' ' + user.name}
                    {team.owner_id === user.id && (
                      <Badge ml={1} fontSize={'10px'}>
                        Propriétaire
                      </Badge>
                    )}
                  </Text>
                  <Text fontSize={10} color={'muted'} style={{ marginTop: 0 }}>
                    {user.email}
                  </Text>
                </VStack>
              </HStack>
              {team.owner_id !== user.id && (
                <Button
                  size={'sm'}
                  variant={'ghost'}
                  fontSize={12}
                  isLoading={isLoadingDelete}
                  onClick={() => _deleteFromTeam(user)}
                >
                  Retirer de l'équipe
                </Button>
              )}
            </Box>
          ))}
        </>
      )}
    </Box>
  );
}
