import React, { Component, Fragment } from 'react';

import { Modal, ModalBody, ModalContent, ModalHeader, ModalOverlay } from '@chakra-ui/react';

import AccountV2FormComponent from '../Forms/Auth/account/AccountV2FormComponent';

export default class V2ModalComponent extends Component {
  state = {
    tva: false,
    step: 0,
  };

  render() {
    return (
      <Modal
        isOpen={this.props.isOpen}
        size={'xl'}
        closeOnEsc={false}
        closeOnOverlayClick={false}
        onClose={() => this.props.onClose()}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <span className="tag is-primary mr-5 mb-5">IMPORTANT</span>
            <br />
            <h5 className={'title is-5 is-font-title has-text-weight-bold'}>
              Renseignements pour le <mark className="is-font-title">versement de vos honoraires</mark>
            </h5>
          </ModalHeader>
          <ModalBody>
            <Fragment>
              <p
                style={{
                  fontSize: 13,
                  padding: 6,
                  border: '1px solid #bb2030',
                }}
                className="mb-20"
              >
                Pour vous régler vos commissions, il est nécessaire de renseigner votre numéro IBAN et numéro de TVA (si
                vous y êtes assujetti).
              </p>

              <AccountV2FormComponent
                onClose={() => {
                  this.props.onClose();
                }}
              />
            </Fragment>
          </ModalBody>
        </ModalContent>
      </Modal>
    );
  }
}
