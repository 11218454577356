import React, { ReactNode, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';

import { useKeyPress } from '@meilleursbiens/hooks';
import { NeoPropertyPicturesUtils } from '@meilleursbiens/neo';
import { MBNeoPropertiesType, MBProContact, MBProUser } from '@meilleursbiens/types';
import { TextDate } from '@meilleursbiens/ui';

import { CloseIcon, SearchIcon } from '@chakra-ui/icons';
import {
  Avatar,
  Badge,
  Box,
  Divider,
  Fade,
  Flex,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Spinner,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
  useColorModeValue,
} from '@chakra-ui/react';

import moment from 'moment';
import { useDebounce } from 'use-debounce';

import MBProAdminSearchAPI from '../../../../api/requests/MBPro/Admin/MBProAdminSearchAPI';
import { Routes } from '../../../../routes/routes';
import StatusUtils from '../../../../utils/StatusUtils';

interface SearchWindowItemProps {
  avatarCustom?: ReactNode;
  avatarUrl: string;
  avatarText?: string;
  name: ReactNode;
  subtitle: ReactNode;
  url: string;
  isSelected: boolean;
  onClose: () => void;
}

interface SearchWindowTabProps {
  name: string;
  key: string;
  url: (id: number) => string;
  component: (item: any, index: number) => ReactNode;
}

export default function AdminSearchWindowComponent(props) {
  const textInputRef = React.useRef(null);

  const navigate = useNavigate();

  const arrowRightPressed = useKeyPress('ArrowRight');
  const arrowLeftPressed = useKeyPress('ArrowLeft');
  const arrowDownPressed = useKeyPress('ArrowDown');
  const arrowUpPressed = useKeyPress('ArrowUp');
  const enterPressed = useKeyPress('Enter');

  const [isLoading, setIsLoading] = useState(false);

  const [tabIndex, setTabIndex] = useState(0);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const [search, setSearch] = useState('');
  const [suggestions, setSuggestions] = useState(undefined);
  const [value] = useDebounce(search, 500);

  const MAPPING_TABS: SearchWindowTabProps[] = [
    {
      name: 'Agents',
      key: 'users',
      url: (id: number) => Routes.ADMIN_AGENTS_VIEW.url.replace(':id', id.toString()),
      component: (item: MBProUser, index: number) => {
        const status = StatusUtils.userStatus(item);
        return (
          <SearchWindowItem
            avatarUrl={item.profile_picture}
            name={
              <>
                <Text noOfLines={1} fontWeight={600} fontSize={14} style={{ marginBottom: 0 }}>
                  {item.firstName} {item.name}
                </Text>
                <Badge fontSize={9} px={1} colorScheme={status.colorScheme} variant="subtle">
                  {status.name}
                </Badge>
              </>
            }
            subtitle={
              <HStack>
                <Text noOfLines={1} fontWeight={400} fontSize={12} style={{ marginTop: 0 }} color={'muted'}>
                  Dernière activité
                </Text>
                <TextDate
                  noOfLines={1}
                  fontWeight={400}
                  fontSize={12}
                  style={{ marginTop: 0 }}
                  color={'muted'}
                  defaultFormat={'ago'}
                  date={moment(item.last_activity_at).toDate()}
                />
              </HStack>
            }
            url={Routes.ADMIN_AGENTS_VIEW.url.replace(':id', item.id.toString())}
            isSelected={index === selectedIndex}
            onClose={props.onClose}
          />
        );
      },
    },
    {
      name: 'Biens',
      key: 'properties',
      url: (id: number) => Routes.NEO_PROPERTIES_VIEW.url.replace(':id', id.toString()),
      component: (item: MBNeoPropertiesType, index: number) => {
        const picture = NeoPropertyPicturesUtils.getMainPicture(item, 'cache');
        return (
          <SearchWindowItem
            avatarCustom={
              <img
                src={picture}
                style={{
                  borderRadius: 8,
                  height: 50,
                  width: 75,
                  objectFit: 'cover',
                }}
              />
            }
            name={
              <Text noOfLines={1} fontWeight={600} fontSize={14} maxW={'300px'} style={{ marginBottom: 0 }}>
                {item.title}
              </Text>
            }
            subtitle={
              <HStack>
                <Text noOfLines={1} fontWeight={400} fontSize={12} style={{ marginTop: 0 }} color={'muted'}>
                  {item.user && item.user.firstName} {item.user && item.user.name}
                </Text>

                <Divider orientation="vertical" h={3} />
                <Text noOfLines={1} fontWeight={400} fontSize={12} style={{ marginTop: 0 }} color={'muted'}>
                  {item.city} ({item.postal_code})
                </Text>
              </HStack>
            }
            url={Routes.ADMIN_AGENTS_VIEW.url.replace(':id', item.id)}
            isSelected={index === selectedIndex}
            avatarUrl={''}
            onClose={props.onClose}
          />
        );
      },
    },
    {
      name: 'Contacts',
      key: 'contacts',
      url: (id: number) => Routes.CONTACTS_VIEW.url.replace(':id', id.toString()),
      component: (item: MBProContact, index: number) => {
        return (
          <SearchWindowItem
            avatarText={item.first_name + ' ' + item.name}
            name={
              <Text noOfLines={1} fontWeight={600} fontSize={14} style={{ marginBottom: 0 }}>
                {item.first_name} {item.name}
              </Text>
            }
            subtitle={
              <HStack>
                <Text noOfLines={1} fontWeight={400} fontSize={12} style={{ marginTop: 0 }} color={'muted'}>
                  {item.email}
                </Text>
                <Divider orientation="vertical" h={3} />
                <Text noOfLines={1} fontWeight={400} fontSize={12} style={{ marginTop: 0 }} color={'muted'}>
                  {item.phone}
                </Text>
              </HStack>
            }
            url={Routes.ADMIN_AGENTS_VIEW.url.replace(':id', item.id.toString())}
            isSelected={index === selectedIndex}
            avatarUrl={''}
            onClose={props.onClose}
          />
        );
      },
    },
  ];

  useEffect(() => {
    // @ts-ignore
    textInputRef.current.focus();
  }, []);

  useEffect(() => {
    if (props.value != '') {
      setSearch(props.value);
      _fetchAPI(props.value);
    } else {
      setIsLoading(false);
      setSuggestions(undefined);
    }
  }, [props.value]);

  useEffect(() => {
    if (value != '') {
      _fetchAPI(value);
    } else {
      setIsLoading(false);
      setSuggestions(undefined);
    }
  }, [value]);

  useEffect(() => {
    if (arrowRightPressed) {
      if (tabIndex < MAPPING_TABS.length - 1) {
        setTabIndex(tabIndex + 1);
        setSelectedIndex(0);
      }
    }
    if (arrowLeftPressed) {
      if (tabIndex > 0) {
        setTabIndex(tabIndex - 1);
        setSelectedIndex(0);
      }
    }
    if (arrowDownPressed) {
      setSelectedIndex(selectedIndex + 1);
    }
    if (arrowUpPressed) {
      setSelectedIndex(selectedIndex - 1);
    }
    if (enterPressed) {
      const selectedTab = MAPPING_TABS[tabIndex];
      if (!suggestions) return;
      const selectedItem = suggestions[selectedTab.key][selectedIndex];
      navigate(selectedTab.url(selectedItem.id));
      props.onClose();
    }
  }, [arrowRightPressed, arrowUpPressed, arrowDownPressed, arrowLeftPressed, enterPressed]);

  const _fetchAPI = (searchValue) => {
    if (searchValue == '') return;
    MBProAdminSearchAPI.search(searchValue)
      .then((response) => {
        setIsLoading(false);
        // @ts-ignore
        setSuggestions(response.suggestions);
      })
      .catch((error) => {
        setIsLoading(false);
        setSuggestions(undefined);
      });
  };

  return (
    <div className="search-window animated fadeIn duration-faster">
      <a className="close-icon" onClick={() => props.onClose()}>
        <CloseIcon boxSize={'1.5em'} color={'gray.400'} />
      </a>

      <Stack
        width={'500px'}
        background={useColorModeValue('rgba(255, 255, 255, 1)', '#222222')}
        paddingY={3}
        paddingX={3}
        borderRadius={15}
        boxShadow="lg"
      >
        <InputGroup width={'100%'}>
          <InputLeftElement pointerEvents="none" children={<SearchIcon color="gray.300" />} />
          <Input
            type="text"
            placeholder="Rechercher quelque chose..."
            color={useColorModeValue('#000', '#fff')}
            _placeholder={{ color: 'gray.300' }}
            focusBorderColor={'transparent'}
            border={'none'}
            boxShadow={'none'}
            ref={textInputRef}
            value={search}
            autoFocus={true}
            onChange={(e) => {
              setIsLoading(true);
              setSearch(e.target.value);
            }}
          />
          <Fade in={isLoading}>
            <InputRightElement pointerEvents="none" children={<Spinner size={'sm'} />} />
          </Fade>
        </InputGroup>
        <Divider />

        <Tabs size={'sm'} colorScheme={'red'} index={tabIndex} onChange={(i) => setTabIndex(i)}>
          <TabList>
            {MAPPING_TABS.map((tab, index) => (
              <Tab key={index}>
                {tab.name}
                {/* @ts-ignore */}
                <Badge ml={2} p={0} px={1} fontSize={'9px'}>
                  {suggestions && suggestions[tab.key].length}
                  {!suggestions && '0'}
                </Badge>
              </Tab>
            ))}
          </TabList>

          <TabPanels>
            {MAPPING_TABS.map((tab, index) => (
              <TabPanel p={0}>
                <Stack w={'100%'} mt={0}>
                  {suggestions !== undefined && (
                    <VStack gap={0}>
                      {/* @ts-ignore */}
                      {suggestions[tab.key].length == 0 && (
                        <Text fontSize={12} color={'muted'} my={'30px'} mt={'40px'}>
                          Aucun résultat trouvé avec cette recherche
                        </Text>
                      )}
                      {/* @ts-ignore */}
                      {suggestions[tab.key].map((item, index) => tab.component(item, index))}
                    </VStack>
                  )}
                </Stack>
              </TabPanel>
            ))}
          </TabPanels>
        </Tabs>
      </Stack>
    </div>
  );
}

function SearchWindowItem({
  avatarUrl,
  avatarText = '',
  name,
  subtitle,
  url,
  isSelected = false,
  avatarCustom,
  onClose,
}: SearchWindowItemProps) {
  return (
    <Box
      py={2}
      px={2}
      bg={isSelected ? useColorModeValue('gray.50', 'gray.800') : 'transparent'}
      borderBottom={'1px solid ' + useColorModeValue('gray.50', 'gray.900')}
      w={'100%'}
      borderRadius={'8px'}
      as={Link}
      to={url}
      replace={true}
      _hover={{ background: '#F5F5F5' }}
      onClick={onClose}
    >
      <Flex direction={'row'} align={'center'} justify={'space-between'} w={'100%'}>
        <HStack spacing={3}>
          {avatarCustom && avatarCustom}
          {!avatarCustom && <Avatar src={avatarUrl} name={avatarText} />}
          <Stack gap={0}>
            <HStack style={{ marginBottom: 0 }}>{name}</HStack>

            {subtitle}
          </Stack>
        </HStack>
      </Flex>
    </Box>
  );
}
