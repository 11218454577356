import { ChakraTheme, extendTheme, withDefaultColorScheme } from '@chakra-ui/react';

import { baseTheme } from '../base';
import components from './components';
import colors from './foundations/colors';
import semanticTokens from './foundations/semantic-tokens';
import shadows from './foundations/shadows';
import sizes from './foundations/sizes';
import { fontSizes, fonts, textStyles } from './foundations/typography';
import styles from './styles';

const config = {
  cssVarPrefix: 'mb-ui',
  initialColorMode: 'system',
  useSystemColorMode: true,
};

export const theme = extendTheme(
  {
    config,
    colors,
    fonts,
    fontSizes,
    textStyles,
    sizes,
    styles,
    components,
    shadows,
    semanticTokens,
  },
  withDefaultColorScheme({
    colorScheme: 'primary',
    components: ['Radio', 'Switch', 'Checkbox'],
  }),
  baseTheme
) as ChakraTheme;
