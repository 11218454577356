import React from 'react';

import { Box, BoxProps, forwardRef, useColorMode } from '@chakra-ui/react';

export interface CardProps extends BoxProps {
  children: React.ReactNode;
}

const Card = forwardRef<CardProps, 'div'>((props: CardProps, ref) => {
  const { colorMode } = useColorMode();

  return (
    <Box
      ref={ref}
      w={'100%'}
      borderWidth={1}
      borderColor={colorMode === 'light' ? 'gray.100' : 'gray.700'}
      bg={colorMode === 'dark' ? 'gray.900' : 'white'}
      px={5}
      py={5}
      borderRadius={8}
      boxShadow={'sm'}
      {...props}
    >
      {props.children}
    </Box>
  );
});

export default Card;
