import React, { createElement } from 'react';

import { Button, ButtonProps, IconButton as IButton, forwardRef, useColorModeValue, useToken } from '@chakra-ui/react';
import { useTeamPermission } from '@meilleursbiens/permissions';

type OmittedProps = 'leftIcon' | 'rightIcon' | 'loadingText' | 'iconSpacing' | 'spinnerPlacement';

type BaseButtonProps = Omit<ButtonProps, OmittedProps>;

export interface IconButtonProps extends BaseButtonProps {
  icon: React.ForwardRefExoticComponent<
    React.PropsWithoutRef<React.SVGProps<SVGSVGElement>> & {
      title?: string;
      titleId?: string;
    } & React.RefAttributes<SVGSVGElement>
  >;
  customIcon?: React.ReactElement;
  cooldown?: number;
  isRound?: boolean;
  'aria-label': string;
  permissions?: string[];
  iconColor?: string;
}

const IconButton = forwardRef<IconButtonProps, 'button'>((props: IconButtonProps, ref) => {
  const { icon, children, isRound, 'aria-label': ariaLabel, ...rest } = props;

  const { hasPermissions } = useTeamPermission(props.permissions != undefined);

  const [isCooldown, setIsCooldown] = React.useState(false);

  const [white, gray600, gray50, red500] = useToken('colors', ['white', 'gray.600', 'gray.200', 'red.500']);

  const variant = props.variant == 'primary' ? white : props.variant == 'tertiary' ? red500 : gray600;

  const iconColor = props.iconColor ? props.iconColor : useColorModeValue(variant, gray50);

  const sizeToPx = () => {
    switch (props.size) {
      case 'xs':
        return 13;
      case 'sm':
        return 16;
      case 'md':
        return 18;
      case 'lg':
        return 22;
      case 'xl':
        return 27;
      default:
        return 15;
    }
  };

  const pxSize = sizeToPx();

  const element = icon || children;
  let _children = createElement(element as any, {
    'aria-hidden': true,
    focusable: false,
    color: iconColor,
    width: pxSize,
    height: pxSize,
    strokeWidth: 2,
  });

  const isDisabledPermissions = props.permissions ? !hasPermissions(props.permissions) : false;
  const isDisabled = props.isDisabled || isDisabledPermissions;

  if (props.cooldown) {
    return (
      <IButton
        isDisabled={isCooldown || isDisabled}
        onClick={(e) => {
          setIsCooldown(true);
          if (typeof props.onClick === 'function') props.onClick(e);
          setTimeout(() => {
            setIsCooldown(false);
          }, (props.cooldown || 5) * 1000);
        }}
        padding="0"
        borderRadius={isRound ? 'full' : undefined}
        ref={ref}
        aria-label={ariaLabel}
        {...rest}
        icon={_children}
      />
    );
  }

  return (
    <IButton
      padding="0"
      borderRadius={isRound ? 'full' : undefined}
      ref={ref}
      aria-label={ariaLabel}
      {...rest}
      isDisabled={isDisabled}
      icon={_children}
    />
  );
});

export default IconButton;
