import { AuthConstant } from '@meilleursbiens/constants';
import { AxiosAPIResponse, OpenAIChatCompletionChunk } from '@meilleursbiens/types';
import { DevUtils } from '@meilleursbiens/utils';

import Axios, { AxiosError } from 'axios';
import { buildWebStorage, setupCache } from 'axios-cache-interceptor';

import * as Sentry from '@sentry/react';

export default class MBProAPI {
  _axios: any;
  _url: string;
  _headers: any = {};
  _parameters: object = {};
  _needAPIKey = false;
  _formData: FormData;
  _needCache = false;
  _cacheID: string | null = null;
  _cacheTTL = 60;
  _cacheUpdate: object | null = {};
  _isSSR = false;

  /**
   * Create an request easily to the MeilleursBiens API
   *
   * @param url API Endpoint
   * @param needAPIKey
   * @param customUrl
   * @param curl
   */
  constructor(url: string, needAPIKey = false, customUrl = false, curl = '', isSSR = false) {
    this._axios = Axios.create({
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (isSSR) {
      this._axios = Axios.create({
        headers: {
          'Content-Type': 'application/json',
        },
      });
    } else {
      this._axios = setupCache(this._axios, {
        storage: buildWebStorage(sessionStorage, 'mb-cache:'),
        debug: console.log,
        interpretHeader: false,
      });
    }
    this._axios.interceptors.response.use(
      async (response: any) => {
        try {
          if (response.data.error) {
            if (response.data.message.includes('Votre session a expiré')) {
              window.location.href = '/auth/refresh';
            } else if (response.data.message.includes('Vous n\'avez pas accès à cette fonctionnalité')) {
              // On ignore
            } else {
              Sentry.captureException(new Error(response.data.message), {
                extra: response.data,
              });
            }
          }
        } catch (e) {
          Sentry.captureException(e);
        }
        return response;
      },
      (error: AxiosError) => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          Sentry.captureException(error.response.data);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          Sentry.captureException(error.message);
        }
        Sentry.captureException(error);
        return Promise.reject(error);
      }
    );
    this._headers = {
      'Content-Type': 'application/json',
    };
    this._parameters = {};
    this._needAPIKey = false;
    this._formData = new FormData();
    this._isSSR = isSSR;

    const isLocalhost = false;

    if (isLocalhost) {
      this._headers['X-MeilleursBiens-Mobile'] = 'true';
    }

    if (url === '') throw new Error('Please specify an url to api endpoint.');
    this._url = DevUtils.getApiURL(isSSR) + url;
    if (customUrl) this._url = curl;
    this._needAPIKey = needAPIKey;
  }

  /**
   * Create a Request staticly.
   *
   * @param url API endpoint
   * @param needAPIKey
   * @returns {MBProAPI}
   */
  static url(url: string, needAPIKey = false) {
    return new MBProAPI(url, needAPIKey);
  }

  static ssr(url: string, needAPIKey = false) {
    return new MBProAPI(url, needAPIKey, false, '', true);
  }

  static customUrl(url: string, needAPIKey = false) {
    return new MBProAPI(url, needAPIKey, true, url);
  }

  contentJson() {
    this._headers['Content-Type'] = 'application/json';
    return this;
  }

  contentURL() {
    this._headers['Content-Type'] = 'application/x-www-form-urlencoded';
    return this;
  }

  contentFormData() {
    this._headers['Content-Type'] = 'multipart/form-data';
    return this;
  }

  /**
   * Add headers to the request.
   *
   * @param object Request headers in object format.
   */
  headers(object: object) {
    this._headers = { ...this._headers, ...object };
    return this;
  }

  _csrf(csrf: string) {
    this._headers = { ...this._headers, 'X-CSRF-Token': csrf };
    return this;
  }

  needAuth() {
    if (this._isSSR) throw new Error('Cannot use needAuth in SSR');

    const jwt = localStorage.getItem(AuthConstant.JWT_TOKEN_KEY);
    const user_id = localStorage.getItem(AuthConstant.MB_ID_KEY);

    this._headers = {
      ...this._headers,
      [AuthConstant.HEADERS.AUTHORIZATION]: 'Bearer ' + jwt,
      [AuthConstant.HEADERS.MB_ID]: user_id,
    };

    return this;
  }

  needRefreshAuth() {
    if (this._isSSR) throw new Error('Cannot use needAuth in SSR');

    const jwt = localStorage.getItem(AuthConstant.JWT_TOKEN_KEY);
    const refresh_token = localStorage.getItem(AuthConstant.JWT_REFRESH_TOKEN_KEY);
    const user_id = localStorage.getItem(AuthConstant.MB_ID_KEY);

    this._headers = {
      ...this._headers,
      [AuthConstant.HEADERS.AUTHORIZATION]: 'Bearer ' + jwt,
      [AuthConstant.HEADERS.REFRESH_TOKEN]: 'Bearer ' + refresh_token,
      [AuthConstant.HEADERS.MB_ID]: user_id,
    };

    return this;
  }

  static refreshAuth() {
    return new Promise((resolve, reject) => {
      this.url('/v1/mbpro/auth/refresh')
        .parameters({
          redirectTo: '',
        })
        .needRefreshAuth()
        .post()
        .then((r: any) => {
          if (r.data.error) {
            reject(r.data.message);
          } else {
            localStorage.setItem(AuthConstant.JWT_TOKEN_KEY, r.jwt_token);
            localStorage.setItem(AuthConstant.JWT_REFRESH_TOKEN_KEY, r.refresh_token);
            localStorage.setItem(AuthConstant.MB_ID_KEY, r.user.id);
            localStorage.setItem(AuthConstant.CRISP_SIGNATURE, r.crisp_auth_token);
            resolve(r);
          }
        })
        .catch((e) => {
          reject(e.message);
        });
    });
  }

  /**
   * Add parameters to the request.
   *
   * @param object Request headers in object format.
   */
  parameters(object: object) {
    this._parameters = { ...this._parameters, ...object };
    return this;
  }

  formData(formData: FormData) {
    this._formData = formData;
    return this;
  }

  /**
   * Add cache to the request.
   */
  needCache(ttl = 60, id: string | null = null, update: object | null = null) {
    if (this._isSSR) throw new Error('Cannot use needAuth in SSR');

    this._needCache = true;

    this._cacheID = id;
    this._cacheTTL = ttl;
    this._cacheUpdate = update;

    return this;
  }

  /**
   * Execute post request.
   *
   * @returns {Promise<APIResponse<T>>}
   */
  post<T>(): Promise<T> {
    const request: any = { headers: { ...this._headers } };

    if (!this._needCache) {
      request.cache = false;
    } else {
      request.cache = {
        ttl: this._cacheTTL * 1000,
      };

      if (this._cacheID) {
        request.cache.id = this._cacheID;
      }

      if (this._cacheUpdate) {
        request.cache.update = this._cacheUpdate;
      }
    }

    return new Promise((resolve, reject) => {
      this._axios
        .post(this._url, JSON.stringify(this._parameters), request)
        .then((response: AxiosAPIResponse<T>) => {
          if (response.data.error) {
            reject(response.data.message);
          } else {
            resolve(response.data.data);
          }
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  }

  /**
   * Execute post request.
   *
   * @returns {Promise<APIResponse<T>>}
   */
  postUpload<T>(): Promise<T> {
    const request: any = { headers: { ...this._headers } };

    if (!this._needCache) {
      request.cache = false;
    } else {
      request.cache = {
        ttl: this._cacheTTL * 1000,
      };

      if (this._cacheID) {
        request.cache.id = this._cacheID;
      }

      if (this._cacheUpdate) {
        request.cache.update = this._cacheUpdate;
      }
    }

    return new Promise((resolve, reject) => {
      this._axios
        .post(this._url, this._formData, request)
        .then((response: AxiosAPIResponse<T>) => {
          if (response.data.error) {
            reject(response.data.message);
          } else {
            resolve(response.data.data);
          }
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  }

  /**
   * Execute post request.
   *
   * @returns {Promise<APIResponse<T>>}
   */
  put<T>(): Promise<T> {
    const request: any = { headers: { ...this._headers } };

    if (!this._needCache) {
      request.cache = false;
    } else {
      request.cache = {
        ttl: this._cacheTTL * 1000,
      };

      if (this._cacheID) {
        request.cache.id = this._cacheID;
      }

      if (this._cacheUpdate) {
        request.cache.update = this._cacheUpdate;
      }
    }

    return new Promise((resolve, reject) => {
      this._axios
        .put(this._url, JSON.stringify(this._parameters), request)
        .then((response: AxiosAPIResponse<T>) => {
          if (response.data.error) {
            reject(response.data.message);
          } else {
            resolve(response.data.data);
          }
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  }

  putUpload<T>(): Promise<T> {
    const request: any = { headers: { ...this._headers } };

    if (!this._needCache) {
      request.cache = false;
    } else {
      request.cache = {
        ttl: this._cacheTTL * 1000,
      };

      if (this._cacheID) {
        request.cache.id = this._cacheID;
      }

      if (this._cacheUpdate) {
        request.cache.update = this._cacheUpdate;
      }
    }

    return new Promise((resolve, reject) => {
      this._axios
        .put(this._url, this._formData, request)
        .then((response: AxiosAPIResponse<T>) => {
          if (response.data.error) {
            reject(response.data.message);
          } else {
            resolve(response.data.data);
          }
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  }

  /**
   * Execute patch request.
   *
   * @returns {Promise<APIResponse<T>>}
   */
  patch<T>(): Promise<T> {
    const request: any = { headers: { ...this._headers } };

    if (!this._needCache) {
      request.cache = false;
    } else {
      request.cache = {
        ttl: this._cacheTTL * 1000,
      };

      if (this._cacheID) {
        request.cache.id = this._cacheID;
      }

      if (this._cacheUpdate) {
        request.cache.update = this._cacheUpdate;
      }
    }

    return new Promise((resolve, reject) => {
      this._axios
        .patch(this._url, JSON.stringify(this._parameters), request)
        .then((response: AxiosAPIResponse<T>) => {
          if (response.data.error) {
            reject(response.data.message);
          } else {
            resolve(response.data.data);
          }
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  }

  patchUpload<T>(): Promise<T> {
    const request: any = { headers: { ...this._headers } };

    if (!this._needCache) {
      request.cache = false;
    } else {
      request.cache = {
        ttl: this._cacheTTL * 1000,
      };

      if (this._cacheID) {
        request.cache.id = this._cacheID;
      }

      if (this._cacheUpdate) {
        request.cache.update = this._cacheUpdate;
      }
    }

    return new Promise((resolve, reject) => {
      this._axios
        .put(this._url, this._formData, request)
        .then((response: AxiosAPIResponse<T>) => {
          if (response.data.error) {
            reject(response.data.message);
          } else {
            resolve(response.data.data);
          }
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  }

  /**
   * Execute delete request.
   *
   * @returns {Promise<AxiosResponse<T>>}
   */
  delete<T>(): Promise<T> {
    const request: any = { headers: { ...this._headers } };

    if (!this._needCache) {
      request.cache = false;
    } else {
      request.cache = {
        ttl: this._cacheTTL * 1000,
      };

      if (this._cacheID) {
        request.cache.id = this._cacheID;
      }

      if (this._cacheUpdate) {
        request.cache.update = this._cacheUpdate;
      }
    }

    return new Promise((resolve, reject) => {
      this._axios
        .delete(this._url, request)
        .then((response: AxiosAPIResponse<T>) => {
          if (response.data.error) {
            reject(response.data.message);
          } else {
            resolve(response.data.data);
          }
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  }

  /**
   * Execute get request.
   *
   * @returns {Promise<any>}
   * @throws {Error}
   */
  get<T>(): Promise<T> {
    const params = this._parameters;
    const headers = this._headers;

    const request: any = { params, headers };

    if (!this._needCache) {
      request.cache = false;
    } else {
      request.cache = {
        ttl: this._cacheTTL * 1000,
      };

      if (this._cacheID) {
        request.cache.id = this._cacheID;
      }

      if (this._cacheUpdate) {
        request.cache.update = this._cacheUpdate;
      }
    }

    return new Promise((resolve, reject) => {
      this._axios
        .get(this._url, request)
        .then((response: AxiosAPIResponse<any>) => {
          if (response.data.error) {
            reject(response.data.message);
          } else {
            resolve(response.data.data);
          }
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  }

  async streamOpenAI(onChunk: (chunk: string) => void, onEnd: (beforeTimeStamp: number) => void) {
    const beforeTimestamp = Date.now();

    const request: any = { headers: { ...this._headers } };

    const response = await fetch(this._url, {
      headers: request.headers,
      method: 'POST',
      body: JSON.stringify(this._parameters),
    });

    if (!response.ok) {
      throw new Error(`La réponse est invalide : ${response.status} - ${response.statusText}`);
    }

    if (!response.body) {
      throw new Error('Aucune réponse du serveur');
    }

    const content = '';
    const role = '';

    const textDecoder = new TextDecoder('utf-8');

    for await (const newData of response.body as unknown as NodeJS.ReadableStream) {
      // Decode the data
      const decodedData = textDecoder.decode(newData as Buffer);
      // Split the data into lines to process
      const lines = decodedData.split(/(\n){2}/);
      // Parse the lines into chat completion chunks
      const chunks: OpenAIChatCompletionChunk[] = lines
        // Remove 'data:' prefix off each line
        .map((line) => line.replace(/(\n)?^data:\s*/, '').trim())
        // Remove empty lines and "[DONE]"
        .filter((line) => line !== '' && line !== '[DONE]')
        // Parse JSON string
        .map((line) => JSON.parse(line));

      // Process each chunk and send an update to the registered handler.
      for (const chunk of chunks) {
        // Avoid empty line after single backtick
        const contentChunk: string = (chunk.content ?? '').replace(/^`\s*/, '`');
        onChunk(contentChunk);
      }
    }

    onEnd(beforeTimestamp);

    return { content, role };
  }
}
