import { useEffect, useState } from 'react';

import { MBCorePermissionsTeamAPI } from '@meilleursbiens/api';
import { MBCorePermission } from '@meilleursbiens/types';

export default function useTeamPermission(fetchOnMount: boolean = true) {
  const [isLoadingPermissions, setIsLoadingPermissions] = useState(true);
  const [permissions, setPermissions] = useState<string[]>([]);

  const _fetchTeamPermission = () => {
    MBCorePermissionsTeamAPI.current()
      .then((res) => {
        setPermissions(res.map((p: MBCorePermission) => p.name));
      })
      .finally(() => setIsLoadingPermissions(false))
      .catch((err) => {
        console.error(err);
        setPermissions([]);
      });
  };

  const hasPermission = (permission: string) => {
    return permissions.includes(permission);
  };

  const hasPermissions = (permissionsArg: string[]) => {
    return permissionsArg.every((permission) => hasPermission(permission));
  };

  const hasAnyPermissions = (permissionsArg: string[]) => {
    return permissionsArg.some((permission) => hasPermission(permission));
  };

  useEffect(() => {
    if (fetchOnMount) _fetchTeamPermission();
  }, []);

  return {
    isLoadingPermissions,
    hasPermission,
    hasPermissions,
    hasAnyPermissions,
  };
}
