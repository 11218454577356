import React from 'react';

import { MenuItem as ChakraMenuItem, MenuItemProps, forwardRef, useColorModeValue, useToken } from '@chakra-ui/react';
import { useTeamPermission } from '@meilleursbiens/permissions';

export interface IMenuItemProps extends Omit<MenuItemProps, 'icon'> {
  icon?: React.ForwardRefExoticComponent<
    React.PropsWithoutRef<React.SVGProps<SVGSVGElement>> & {
      title?: string;
      titleId?: string;
    } & React.RefAttributes<SVGSVGElement>
  >;
  customLeftIcon?: React.ReactElement;
  permissions?: string[];
}

const MenuItem = forwardRef<IMenuItemProps, 'button'>((props: IMenuItemProps, ref) => {
  const [gray600, gray50] = useToken('colors', ['gray.500', 'gray.200']);

  const { hasPermissions } = useTeamPermission(props.permissions != undefined);

  const iconColor = useColorModeValue(gray600, gray50);

  const isDisabledPermissions = props.permissions ? !hasPermissions(props.permissions) : false;
  const isDisabled = props.isDisabled || isDisabledPermissions;

  const isReactElement = (element: any): element is React.ReactElement => {
    return React.isValidElement(element);
  };

  return (
    <ChakraMenuItem
      ref={ref}
      {...props}
      isDisabled={isDisabled}
      icon={
        props.icon ? (
          React.createElement(props.icon, {
            color: 'currentColor',
            width: 18,
            height: 18,
            strokeWidth: 2,
          })
        ) : props.customLeftIcon ? (
          props.customLeftIcon
        ) : (
          <></>
        )
      }
    >
      {props.children}
    </ChakraMenuItem>
  );
});

export default MenuItem;
