import { MBNeoPropertiesType } from '@meilleursbiens/types';

/**
 * Utilitaires pour les images des biens de Néo
 */
export default class NeoPropertyPicturesUtils {
  /**
   * Retourne l'image principale d'un bien
   * @param property Bien
   * @param typeOfPicture Type d'image
   */
  static getMainPicture(property: MBNeoPropertiesType, typeOfPicture: 'principal' | 'cache' | 'compressed'): string {
    if (!property.pictures) return 'https://mb-portail.s3.eu-west-3.amazonaws.com/images/no-picture.jpg';

    if (property.pictures.length > 0) {
      let firstPicture = property.pictures[0];

      property.pictures.map((picture) => {
        if (firstPicture.order > picture.order) {
          firstPicture = picture;
        }
      });

      const mainPicture = firstPicture;

      switch (typeOfPicture) {
        case 'principal':
          return mainPicture.file;
        case 'cache':
          return mainPicture.file_cache;
        case 'compressed':
          return mainPicture.file_compressed;
      }
    } else {
      return 'https://mb-portail.s3.eu-west-3.amazonaws.com/images/no-picture.jpg';
    }
  }

  /**
   * Retourne l'image d'un bien par son index
   * @param property Bien
   * @param index Index de l'image
   * @param type Type d'image
   */
  static getPictureByIndex(
    property: MBNeoPropertiesType,
    index: number,
    type: 'principal' | 'cache' | 'compressed'
  ): string {
    if (!property.pictures) return 'https://mb-portail.s3.eu-west-3.amazonaws.com/images/no-picture.jpg';

    const mainPicture = property.pictures[index];
    if (!mainPicture) return 'https://mb-portail.s3.eu-west-3.amazonaws.com/images/no-picture.jpg';

    if (mainPicture) {
      switch (type) {
        case 'principal':
          return mainPicture.file;
        case 'cache':
          return mainPicture.file_cache;
        case 'compressed':
          return mainPicture.file_compressed;
      }
    } else {
      return 'https://mb-portail.s3.eu-west-3.amazonaws.com/images/no-picture.jpg';
    }
  }
}
