import React from 'react';
import { createRoot } from 'react-dom/client';

import 'regenerator-runtime/runtime';

import App from './App';
import Store from './Store';
import './styles/app.scss';

const container = document.getElementById('root');
// @ts-ignore
const root = createRoot(container);
root.render(<App store={Store} />);
