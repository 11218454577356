













import React, { useEffect } from 'react';

import { InfiniteScrollRef, use100vh } from '@meilleursbiens/hooks';
import { IconButton, Toolbar, ToolbarGroup } from '@meilleursbiens/ui';

import { Box, Container, Flex, Spacer, Tab, TabList, Tabs, Text, useMediaQuery } from '@chakra-ui/react';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';

export interface AdminLayoutComponentTabsProps {
  title: string;
  badge?: React.ReactNode;
  icon?: React.ForwardRefExoticComponent<
    React.PropsWithoutRef<React.SVGProps<SVGSVGElement>> & {
      title?: string;
      titleId?: string;
    } & React.RefAttributes<SVGSVGElement>
  >;
  component: React.ReactNode;
  isDisabled?: boolean;
}

export interface AdminLayoutComponentProps {
  title: string;
  description: string;
  layout?: 'default' | 'full';
  rightActions?: React.ReactNode;
  tabs?: AdminLayoutComponentTabsProps[];
  subBarActions?: React.ReactNode;
  children?: React.ReactNode;
  isMobile?: boolean;
  onBack?: () => void;
  containerRef?: React.RefObject<HTMLDivElement> | InfiniteScrollRef<any>;
  onTabChange?: (index: number) => void;
}

export default function AdminLayoutComponent(props: AdminLayoutComponentProps) {
  const [tabIndex, setTabIndex] = React.useState(0);

  const fullHeight = use100vh();
  const [isLargerThan980] = useMediaQuery('(min-width: 980px)');

  const layout = props.layout ? props.layout : 'default';
  const hasSubBar = props.tabs || props.subBarActions;
  const computeContentMaxHeight = () => {
    const height = fullHeight ? fullHeight + 'px' : '100vh';
    let toRemove = 58;
    if (hasSubBar) {
      toRemove += 57;
    }
    return `calc(${height} - ${toRemove}px)`;
  };

  useEffect(() => {
    if (props.onTabChange) props.onTabChange(tabIndex);
  }, [tabIndex]);

  return (
    <Flex position={'relative'} w={'100%'} maxW={'100%'} flex={1} direction={'column'}>
      <Toolbar maxH={'58px'} h={'58px'} className={'scroll-is-too-small'} position={'sticky'}>
        <Flex align={'center'}>
          {isLargerThan980 && (
            <>
              {props.onBack && (
                <IconButton
                  aria-label={'Retour'}
                  icon={ArrowLeftIcon}
                  onClick={props.onBack}
                  variant={'unstyled'}
                ></IconButton>
              )}
            </>
          )}
          <Box maxW={['100%', '500px']} ml={!isLargerThan980 ? '40px' : 0}>
            <Text fontSize={'15px'} fontWeight={600} letterSpacing={'-0.04em'} noOfLines={1}>
              {props.title}
            </Text>
            {isLargerThan980 && (
              <Text fontSize={'xs'} fontWeight={500} color={'muted'}>
                {props.description}
              </Text>
            )}
          </Box>
        </Flex>
        <Spacer />
        <ToolbarGroup>{props.rightActions && props.rightActions}</ToolbarGroup>
      </Toolbar>

      {hasSubBar ? (
        <Toolbar h={'57px'} className={'scroll-is-too-small'}>
          {props.tabs && (
            <ToolbarGroup>
              <Tabs
                defaultIndex={tabIndex}
                size={'sm'}
                isLazy={true}
                isManual={true}
                onChange={(index) => setTabIndex(index)}
              >
                <TabList>
                  {props.tabs.map((tab) => (
                    <Tab key={tab.title} isDisabled={tab.isDisabled || false}>
                      {tab.icon && (
                        <Box mr={2} as={'span'}>
                          {React.createElement(tab.icon, {
                            width: '15px',
                            height: '15px',
                            color: 'currentColor',
                            strokeWidth: 2,
                          })}
                        </Box>
                      )}
                      {tab.title}
                      {tab.badge !== undefined && (
                        <Text color={'red.500'} ml={1}>
                          {tab.badge}
                        </Text>
                      )}
                    </Tab>
                  ))}
                </TabList>
              </Tabs>
            </ToolbarGroup>
          )}
          {props.subBarActions && props.subBarActions}
        </Toolbar>
      ) : null}

      <Container
        {...(props.containerRef ? { ref: props.containerRef } : {})}
        position={'relative'}
        pt={layout === 'default' ? 3 : 0}
        px={layout === 'default' ? 4 : 0}
        maxW={'100%'}
        w={'100%'}
        maxH={computeContentMaxHeight()}
        overflow={'auto'}
        h={computeContentMaxHeight()}
      >
        {props.tabs && props.tabs[tabIndex].component}
        {!props.tabs && props.children}
      </Container>
    </Flex>
  );
}
