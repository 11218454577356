import React from 'react';

import { Input, InputGroup, InputLeftAddon, Select } from '@chakra-ui/react';

import { CountriesConstant as countries } from '../../../../../../constants/src';

export type CountryType = (typeof countries)[number];

export const getCountryByIso = (code: CountryType[2]) => countries.find((c) => c[2] === code) as CountryType;

export const removeMask = (value: string) => value.replace(/\D/g, '');
export const getMaskDigit = (value: string, mask?: string) => {
  const v = removeMask(value);
  if (!mask) return v;

  const numberOfDigits = mask.match(/\./g)?.length;

  return v.substring(0, numberOfDigits);
};

export const applyMask = (value = '', mask?: string, forceZero?: boolean) => {
  if (!mask || !value) return value;
  const flatValue = removeMask(value).split('');

  const replaceDots = mask.replace(/\./g, () => flatValue.shift() || '');
  const replaceIfFirstCharIsZero = replaceDots.replace(/^0/, () => flatValue.shift() || '');

  return (/^.*\d/.exec(replaceIfFirstCharIsZero) || [])[0] || '';
};

export const isE164Compliant = (value: string) => /^\+[1-9]\d{1,14}$/.test(value);

export interface PhoneNumber {
  raw: string;
  formatted: string;
  country: CountryType;
}

export const splitPhoneNumber = (value: string): PhoneNumber | undefined => {
  if (!isE164Compliant(value)) {
    console.log('[react-telephone] phone number should follow E.164');
    return;
  }

  const dial = removeMask(value).substring(0, 6);

  const [country] = countries.filter(
    (c) => dial.startsWith(c[3]) && (c[6] ? c[6].some((a: string) => dial.startsWith(`${c[3]}${a}`)) : true)
  );

  return {
    raw: value,
    country: country,
    formatted: country ? applyMask(replaceDialCode(value, country[3], ''), country[4]) : value,
  };
};

export const replaceDialCode = (value: string, dialCode: string, replacer: string) =>
  value.replace('+' + dialCode, replacer);

const DEFAULT_PHONE_NUMBER = {
  raw: '',
  formatted: '',
  country: countries[0],
};

const PhoneContext = React.createContext<[PhoneNumber, (pN: PhoneNumber) => void]>([
  DEFAULT_PHONE_NUMBER,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  () => {},
]);

export interface PhoneInputProps extends Omit<React.ComponentPropsWithRef<'input'>, 'value' | 'defaultValue'> {
  value?: string;
  defaultValue?: string;
  defaultCountry?: CountryType[2];
}

const usePhoneContext = () => React.useContext(PhoneContext);

const DISPLAY_NAME = 'Phone';

export interface PhoneProps extends Omit<React.ComponentPropsWithRef<'input'>, 'value' | 'defaultValue'> {
  value?: string;
  defaultValue?: string;
  defaultCountry?: CountryType[2];
}

export const _Phone = React.forwardRef<HTMLInputElement, PhoneProps>(
  ({ className, style, children, defaultCountry, value, ...props }, ref) => {
    const _ref = React.useRef<HTMLInputElement | null>(null);
    const _defaultValue = props.defaultValue || value;
    const defaultPhoneNumber =
      (_defaultValue
        ? splitPhoneNumber(_defaultValue)
        : defaultCountry && {
            raw: '',
            formatted: '',
            country: getCountryByIso(defaultCountry),
          }) || DEFAULT_PHONE_NUMBER;

    const [_value, setValue] = React.useState<PhoneNumber>(defaultPhoneNumber);

    const handleChange = (phoneNumber: PhoneNumber) => {
      setValue(phoneNumber);

      if (_ref.current != null) {
        // @ts-ignore
        Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'value').set.call(
          _ref.current,
          phoneNumber.raw
        );

        _ref.current.dispatchEvent(new Event('input', { bubbles: true }));
      }
    };

    return (
      <PhoneContext.Provider value={[_value, handleChange]}>
        <InputGroup>
          <InputLeftAddon
            p={0}
            bg={'transparent'}
            border={'none'}
            _dark={{
              bg: 'transparent',
            }}
            children={children}
          />
          <input
            type="tel"
            placeholder="phone number"
            aria-hidden="true"
            style={{ display: 'none' }}
            {...props}
            ref={(r) => {
              if (typeof ref === 'function') ref(r);
              _ref.current = r;
            }}
            defaultValue={defaultPhoneNumber.raw}
          />
        </InputGroup>
      </PhoneContext.Provider>
    );
  }
);

// @ts-ignore
_Phone.displayName = DISPLAY_NAME;

const Country = React.forwardRef<HTMLSelectElement, { variant?: any } & React.ComponentPropsWithRef<'select'>>(
  (props, ref) => {
    const [_value, setValue] = usePhoneContext();

    return (
      <>
        {/** @ts-ignore */}
        <Select
          maxW={'120px'}
          ref={ref}
          colorScheme={'gray'}
          fontSize={12}
          {...props}
          borderRightRadius={0}
          value={_value.country[2]}
          onChange={(e) => {
            props.onChange && props.onChange(e);
            const country = getCountryByIso(e.target.value as CountryType[2]);

            const raw = _value.raw
              ? replaceDialCode(_value.raw, _value.country[3], '+' + country[3])
              : '+' + country[3];

            setValue({
              formatted: applyMask(_value.formatted, country[4]),
              raw,
              country,
            });
          }}
        >
          {countries.map((country) => (
            <option value={country[2]} key={country[2]}>
              {country[0]}&nbsp;(+{country[3]})
            </option>
          ))}
        </Select>
      </>
    );
  }
);

// @ts-ignore
Country.displayName = DISPLAY_NAME + '.Country';

const _Number = React.forwardRef<HTMLInputElement, { variant?: any } & React.ComponentPropsWithRef<'input'>>(
  (props, ref) => {
    const [_value, setValue] = usePhoneContext();

    return (
      <>
        {/** @ts-ignore */}
        <Input
          ref={ref}
          {...props}
          placeholder={
            /\d+/.test(props.placeholder || '') ? applyMask(props.placeholder, _value.country[4]) : props.placeholder
          }
          type="tel"
          borderLeftRadius={0}
          value={_value.formatted}
          onChange={(e) => {
            props.onChange?.(e);

            setValue(
              Object.assign({}, _value, {
                raw: '+' + _value.country[3] + getMaskDigit(e.target.value, _value.country[4]),
                formatted: applyMask(e.target.value, _value.country[4]),
              })
            );
          }}
        />
      </>
    );
  }
);

// @ts-ignore
_Number.displayName = DISPLAY_NAME + '.Number';

export const Phone = Object.assign(_Phone, { Country, Number: _Number });
