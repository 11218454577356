import React from 'react';

import { Flex, omitThemingProps } from '@chakra-ui/react';
import { HTMLChakraProps } from '@chakra-ui/system';
import { cx } from '@chakra-ui/utils';

import { AppShellProvider, useAppShell } from './AppShell.context';

export interface AppShellProps extends HTMLChakraProps<'div'> {
  navbar?: React.ReactNode;
  sidebar?: React.ReactNode;
  aside?: React.ReactNode;
  footer?: React.ReactNode;
  children: React.ReactNode;
}

const AppShell = (props: AppShellProps) => {
  const { navbar, sidebar, aside, footer, children, ...containerProps } = props;

  const isSidebar = React.isValidElement(sidebar);

  const context = useAppShell({
    toggleBreakpoint: isSidebar ? (sidebar as any)?.props.toggleBreakpoint : undefined,
  });

  return (
    <AppShellProvider value={context}>
      <Flex flexDirection={'column'} className={cx('mb_ui_layouts_app_shell', props.className)}>
        {navbar}
        <Flex flex={1} minH={0} minW={0} className={'mb_ui_layouts_app_shell_inner'}>
          {sidebar}
          <Flex flex={1} flexDirection={'row'} minW={0} className={'mb_ui_layouts_app_shell_main'}>
            {children}
          </Flex>
          {aside}
        </Flex>
        {footer}
      </Flex>
    </AppShellProvider>
  );
};

export default AppShell;
