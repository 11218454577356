import { useEffect, useState } from 'react';

import { MBCoreTenantsAPI } from '@meilleursbiens/api';
import { useSessionStorage } from '@meilleursbiens/hooks';
import { MBCoreTenant } from '@meilleursbiens/types';

export default function useTenant() {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [tenant, setTenant] = useSessionStorage<Partial<MBCoreTenant>>('mb_tenant_details', {
    name: 'Chargement...',
    domain: 'Chargement...',
  });

  const fetchTenant = async () => {
    MBCoreTenantsAPI.current()
      .then((response) => {
        setTenant(response);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (!tenant.id) setIsLoading(true);

    fetchTenant();
  }, []);

  return {
    isLoading,
    fetchTenant,
    tenant,
  };
}
