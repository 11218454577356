import { useState } from 'react';

import { ResponsiveValue, UseBreakpointOptions, useBreakpointValue, useTheme } from '@chakra-ui/react';

export type Breakpoints = false | 'base' | 'sm' | 'md' | 'lg' | 'xl' | '2xl';
export const getBreakpoints = (breakpoint: Breakpoints = 'lg') => {
  return breakpoint
    ? {
        base: true,
        [breakpoint]: false,
      }
    : { base: false };
};

const normalize = (variant: any, toArray?: (value: any) => any) => {
  if (Array.isArray(variant)) return variant;
  else if (typeof variant === 'object') return toArray?.(variant);
  if (variant != null) return [variant];
  return [];
};

export const useResponsiveValue = (value: ResponsiveValue<any>, options?: UseBreakpointOptions) => {
  const theme = useTheme();
  const normalized = normalize(value, theme.__breakpoints?.toArrayValue);
  return useBreakpointValue(normalized, options);
};

export function useSessionStorage(key: string, initialValue: any) {
  const [storedValue, setStoredValue] = useState(() => {
    if (typeof window === 'undefined') {
      return initialValue;
    }
    try {
      const item = window.sessionStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      return initialValue;
    }
  });

  const setValue = (value: any) => {
    try {
      const valueToStore = value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);

      if (typeof window !== 'undefined') {
        window.sessionStorage.setItem(key, JSON.stringify(valueToStore));
      }
    } catch (error) {
      console.log(error);
    }
  };
  return [storedValue, setValue];
}
