import MBProAPI from '../../../MBProAPI';

export default class MBProMandatAPI {
  static createMandat(mandat: File | Blob, file: File | Blob, mandatFile: File | Blob) {
    const formData = new FormData();
    formData.append('mandat', mandat);
    formData.append('file', file);
    if (mandatFile != null && mandatFile !== undefined) formData.append('mandatFile', mandatFile);

    return new Promise((resolve, reject) => {
      MBProAPI.url('/v1/mbpro/mandat')
        .needAuth()
        .contentFormData()
        .formData(formData)
        .postUpload()
        .then((r) => {
          if (r.data.error) {
            reject(r.data.message);
          } else {
            resolve(r.data.data);
          }
        })
        .catch((e) => {
          reject(e.message);
        });
    });
  }
}
