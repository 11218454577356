import React from 'react';

import { Flex, Heading, Text } from '@chakra-ui/react';
import { ExclamationCircleIcon } from '@heroicons/react/24/outline';

export interface ErrorPageProps {
  title?: string;
  description?: string;
  actions?: React.ReactNode;
  icon?: React.ReactNode;
  iconProps?: React.ComponentProps<typeof ExclamationCircleIcon>;
  isFullPage?: boolean;
  headingProps?: React.ComponentProps<typeof Heading>;
  textProps?: React.ComponentProps<typeof Text>;
  containerProps?: React.ComponentProps<typeof Flex>;
}

const ErrorPage = (props: ErrorPageProps) => {
  return (
    <Flex
      height={props.isFullPage ? '100vh' : '100%'}
      w={props.isFullPage ? '100vh' : '100%'}
      direction={'column'}
      justify={'center'}
      align={'center'}
      {...props.containerProps}
    >
      {props.icon && props.icon}
      {!props.icon && <ExclamationCircleIcon width={50} {...props.iconProps} />}
      <Heading
        as={'h1'}
        maxW={'450px'}
        textAlign={'center'}
        fontSize={'20px'}
        fontWeight={600}
        mt={5}
        mb={2}
        {...props.headingProps}
      >
        {props.title || "Une erreur s'est produite"}
      </Heading>
      <Text fontSize={'sm'} maxW={'450px'} textAlign={'center'} color={'muted'} mb={6} {...props.textProps}>
        {props.description || 'Nous avons été informés du problème et nous le corrigerons dès que possible.'}
      </Text>
      {props.actions && props.actions}
    </Flex>
  );
};

export default ErrorPage;
