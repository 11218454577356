import React from 'react';
import { useNavigate } from 'react-router-dom';

import { NeoPropertyPicturesUtils } from '@meilleursbiens/neo';
import { MBNeoPropertiesType, MBProClient, MBProTransaction } from '@meilleursbiens/types';
import { Logo, Persona } from '@meilleursbiens/ui';
import { S3Utils, ToastUtils } from '@meilleursbiens/utils';

import { UseDisclosureReturn } from '@chakra-ui/hooks/dist/use-disclosure';
import { Box, Flex, HStack, Image, Kbd, Modal, ModalContent, ModalOverlay, Progress, Text } from '@chakra-ui/react';

import { Command } from 'cmdk';
import { useDebounce } from 'use-debounce';

import MBProSearchAPI from '../../../../api/requests/MBPro/MBProSearchAPI';
import { Routes } from '../../../../routes/routes';
import ImageUtils from '../../../../utils/ImageUtils';

export interface AccountSearchCommandBarComponentProps {
  disclosure: UseDisclosureReturn;
}

export default function AccountSearchCommandBarComponent(props: AccountSearchCommandBarComponentProps) {
  const navigate = useNavigate();

  const inputRef = React.useRef<HTMLInputElement>(null);

  const [isLoading, setIsLoading] = React.useState(false);

  const [result, setResult] = React.useState<{
    properties: MBNeoPropertiesType[];
    clients: MBProClient[];
    transactions: MBProTransaction[];
    transactions_location: any[];
    documents: any[];
    marketplace: any[];
  }>({
    properties: [],
    clients: [],
    transactions: [],
    transactions_location: [],
    documents: [],
    marketplace: [],
  });

  const [value, setValue] = React.useState('');
  const [searchValue] = useDebounce(value, 200);

  React.useEffect(() => {
    if (!searchValue) return;
    if (searchValue.length > 2) {
      _fetchAPI(searchValue);
    } else {
      setResult({
        properties: [],
        clients: [],
        transactions: [],
        transactions_location: [],
        documents: [],
        marketplace: [],
      });
    }
  }, [searchValue]);

  const _fetchAPI = async (value) => {
    setIsLoading(true);

    MBProSearchAPI.search(value)
      .then((res: any) => {
        setResult(res.suggestions);
      })
      .catch((err) => {
        ToastUtils.showError(err);
      })
      .finally(() => setIsLoading(false));
  };

  const _onSelect = (value, isExternal = false) => {
    props.disclosure.onClose();
    if (isExternal) {
      window.open(value, '_blank');
      return;
    }
    navigate(value);
  };

  const isEmpty =
    result.properties.length === 0 &&
    result.clients.length === 0 &&
    result.transactions.length === 0 &&
    result.transactions_location.length === 0 &&
    result.documents.length === 0 &&
    result.marketplace.length === 0;

  return (
    <Modal isOpen={props.disclosure.isOpen} onClose={props.disclosure.onClose}>
      <ModalOverlay />
      <ModalContent>
        <Box className={'mb_ui_cmdk'}>
          <Command shouldFilter={false} loop>
            <Box cmdk-mb-ui-top-shine="" />

            <Command.Input
              ref={inputRef}
              autoFocus
              placeholder="Rechercher sur mon espace..."
              value={value}
              onValueChange={(v) => setValue(v)}
            />

            {!isLoading && <hr cmdk-mb-ui-loader="" />}
            {isLoading && <Progress mt={'12px'} mb={'12px'} h={'2px'} size="xs" isIndeterminate />}

            <Command.List>
              {isEmpty && !isLoading && (
                <Command.Empty>
                  <Text color={'muted'}>Aucun résultat</Text>
                </Command.Empty>
              )}

              {result.properties.length > 0 && (
                <Command.Group heading="Annonces">
                  {result.properties.map((property) => (
                    <Command.Item
                      key={property.id}
                      value={'property-' + property.id.toString()}
                      onSelect={() => _onSelect(Routes.NEO_PROPERTIES_VIEW.url.replace(':id', property.id))}
                    >
                      <Flex>
                        <HStack gap={3}>
                          <Image
                            src={NeoPropertyPicturesUtils.getMainPicture(property, 'compressed')}
                            style={{
                              width: '70px',
                              height: '50px',
                              borderRadius: '8px',
                              objectFit: 'cover',
                            }}
                          />
                          <Box>
                            <Text noOfLines={1}>{property.title}</Text>
                            <Text noOfLines={1} color={'muted'} fontSize={10}>
                              {property.address}, {property.city} ({property.postal_code})
                            </Text>
                          </Box>
                        </HStack>
                      </Flex>
                    </Command.Item>
                  ))}
                </Command.Group>
              )}

              {result.clients.length > 0 && (
                <Command.Group heading="Clients">
                  {result.clients.map((client) => (
                    <Command.Item
                      key={client.id}
                      value={'client-' + client.id.toString()}
                      onSelect={() => _onSelect(Routes.CONTACTS_VIEW.url.replace(':id', client.id.toString()))}
                    >
                      <Flex>
                        <Persona
                          size={'sm'}
                          name={`${client.first_name} ${client.name}`}
                          secondaryText={'Accéder au client'}
                        />
                      </Flex>
                    </Command.Item>
                  ))}
                </Command.Group>
              )}

              {result.transactions.length > 0 && (
                <Command.Group heading="Ventes">
                  {result.transactions.map((transaction) => (
                    <Command.Item
                      key={transaction.id}
                      value={'transaction-' + transaction.id.toString()}
                      onSelect={(value) =>
                        _onSelect(Routes.TRANSACTIONS_VIEW.url.replace(':id', transaction.id.toString()))
                      }
                    >
                      <Flex>
                        <Persona
                          size={'sm'}
                          name={`${transaction.name}`}
                          secondaryText={`${transaction.address?.street || ''}, ${transaction.address?.city || ''} (${
                            transaction.address?.zip || ''
                          })`}
                        />
                      </Flex>
                    </Command.Item>
                  ))}
                </Command.Group>
              )}

              {result.transactions_location.length > 0 && (
                <Command.Group heading="Locations">
                  {result.transactions_location.map((transaction) => (
                    <Command.Item
                      key={transaction.id}
                      value={'transaction-' + transaction.id.toString()}
                      onSelect={(value) =>
                        _onSelect(Routes.TRANSACTIONS_LOCATION_VIEW.url.replace(':id', transaction.id.toString()))
                      }
                    >
                      <Flex>
                        <Persona
                          size={'sm'}
                          name={`${transaction.description}`}
                          secondaryText={`${transaction.adresse}, ${transaction.city} (${transaction.postal})`}
                        />
                      </Flex>
                    </Command.Item>
                  ))}
                </Command.Group>
              )}

              {result.documents.length > 0 && (
                <Command.Group heading="Documents">
                  {result.documents.map((doc) => (
                    <Command.Item
                      key={doc.id}
                      value={'doc-' + doc.id.toString()}
                      onSelect={(value) => _onSelect(S3Utils.url(doc.file), true)}
                    >
                      <Flex>
                        <HStack gap={3}>
                          <Image
                            src={ImageUtils.fileExtensionLogo(doc.file)}
                            style={{
                              width: '50px',
                              height: '50px',
                              borderRadius: '8px',
                              objectFit: 'cover',
                            }}
                          />
                          <Box>
                            <Text noOfLines={1}>{doc.name}</Text>
                            <Text noOfLines={1} color={'muted'} fontSize={10}>
                              Cliquez pour ouvrir le document
                            </Text>
                          </Box>
                        </HStack>
                      </Flex>
                    </Command.Item>
                  ))}
                </Command.Group>
              )}

              {result.marketplace.length > 0 && (
                <Command.Group heading="Boutique">
                  {result.marketplace.map((offer) => (
                    <Command.Item
                      key={offer.id}
                      value={'offer-' + offer.id.toString()}
                      onSelect={(value) => _onSelect(Routes.MARKETPLACE.url)}
                    >
                      <Flex>
                        <HStack gap={3}>
                          <Image
                            src={ImageUtils.fileExtensionLogo(offer.image)}
                            style={{
                              width: '70px',
                              height: '50px',
                              borderRadius: '8px',
                              objectFit: 'cover',
                            }}
                          />
                          <Box>
                            <Text noOfLines={1}>{offer.name}</Text>
                            <Text noOfLines={1} color={'muted'} fontSize={10}>
                              Cliquez pour ouvrir l'offre
                            </Text>
                          </Box>
                        </HStack>
                      </Flex>
                    </Command.Item>
                  ))}
                </Command.Group>
              )}
            </Command.List>
            <div cmdk-mb-ui-footer="">
              <Logo />

              <button cmdk-mb-ui-open-trigger="">
                <Text color={'muted'}>Ouvrir la recherche</Text>
                <Kbd>⌘</Kbd> + <Kbd>K</Kbd>
              </button>
            </div>
          </Command>
        </Box>
      </ModalContent>
    </Modal>
  );
}
