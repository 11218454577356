import MBProAPI from '../../../MBProAPI';

export default class MBProAdminSearchAPI {
  static search(search: string) {
    return new Promise((resolve, reject) => {
      MBProAPI.url('/v1/mbpro/admin/search')
        .parameters({
          search,
        })
        .needAuth()
        .post()
        .then((r) => {
          if (r.data.error) {
            reject(r.data.message);
          } else {
            resolve(r.data.data);
          }
        })
        .catch((e) => {
          reject(e.message);
        });
    });
  }
}
