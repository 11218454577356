import { ServicesConstant } from '@meilleursbiens/constants';

import DevUtils from './DevUtils';

/**
 * Utilitaires S3
 */
export default class S3Utils {
  /**
   * Permet de récupérer l'emplacement URL de l'image dans le bucket S3
   * @param file URL du fichier
   */
  static url(file: string): string {
    const isDev = DevUtils.isDev();
    return (isDev ? ServicesConstant.AWS.S3_STAGING : ServicesConstant.AWS.S3) + file;
  }

  /**
   * Permet de récupérer l'emplacement URL de l'image dans le bucket S3 Portail
   * @param file URL du fichier
   */
  static urlPortail(file: string): string {
    return ServicesConstant.AWS.S3_PORTAIL + file;
  }
}
