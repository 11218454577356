import React from 'react';

import { SearchIcon } from '@chakra-ui/icons';
import {
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Kbd,
  useColorModeValue,
} from '@chakra-ui/react';

export default function AccountSearchBarComponent(props) {
  return (
    <InputGroup
      className={props.isSidebar ? 'sidebar-search-input' : 'navbar-search-input'}
      size={'sm'}
      maxW={'275px'}
      mb={2}
      marginLeft={props.isSidebar ? 0 : 10}
      onClick={(e) => props.onSearch()}
    >
      <InputLeftElement
        pointerEvents="none"
        zIndex={2}
        children={<SearchIcon color={props.isSidebar ? useColorModeValue('#000', 'gray.200') : 'white'} />}
      />
      <Input type="text" placeholder={'Rechercher...'} borderRadius={8} fontSize="12px" variant={'outline'} />
      <InputRightElement
        pointerEvents="none"
        zIndex={2}
        style={{ marginRight: '10px' }}
        children={
          <HStack gap={1}>
            <Kbd>⌘</Kbd>
            <Kbd>k</Kbd>
          </HStack>
        }
      />
    </InputGroup>
  );
}
