import React from 'react';

import { lazyRetry } from '@meilleursbiens/utils';

const NeoPropertiesListScreen = React.lazy(() =>
  lazyRetry(() => import('../screens/MBNeo/Pro/NeoPropertiesListScreen'), 'NeoPropertiesListScreen')
);
const NeoPropertiesListV2Screen = React.lazy(() =>
  lazyRetry(() => import('../screens/MBNeo/Pro/NeoPropertiesListV2Screen'), 'NeoPropertiesListV2Screen')
);
const NeoPropertiesViewScreen = React.lazy(() =>
  lazyRetry(() => import('../screens/MBNeo/Pro/NeoPropertiesViewScreen'), 'NeoPropertiesViewScreen')
);
const NeoPropertiesCreateScreen = React.lazy(() =>
  lazyRetry(() => import('../screens/MBNeo/Pro/NeoPropertiesCreateScreen'), 'NeoPropertiesCreateScreen')
);
const NeoDiffusionSummaryScreen = React.lazy(() =>
  lazyRetry(() => import('../screens/MBNeo/Pro/Diffusion/NeoDiffusionSummaryScreen'), 'NeoDiffusionSummaryScreen')
);
const NeoDiffusionChartScreen = React.lazy(() =>
  lazyRetry(() => import('../screens/MBNeo/Pro/Diffusion/NeoDiffusionChartScreen'), 'NeoDiffusionChartScreen')
);
const AdminNeoPlatformsListScreen = React.lazy(() =>
  lazyRetry(() => import('../screens/MBNeo/Admin/AdminNeoPlatformsListScreen'), 'NeoPropertiesListScreen')
);
const AdminNeoPropertiesViewScreen = React.lazy(() =>
  lazyRetry(
    () => import('../screens/MBNeo/Admin/Properties/AdminNeoPropertiesViewScreen'),
    'AdminNeoPropertiesViewScreen'
  )
);
const AdminNeoPropertiesListScreen = React.lazy(() =>
  lazyRetry(() => import('../screens/MBNeo/Admin/AdminNeoPropertiesListScreen'), 'AdminNeoPropertiesListScreen')
);

export default {
  NEO_PROPERTIES_LIST: {
    url: '/pro/properties',
    component: NeoPropertiesListScreen,
    routeType: 'private.validated',
  },
  NEO_PROPERTIES_LIST_V2: {
    url: '/pro/properties/beta',
    component: NeoPropertiesListV2Screen,
    routeType: 'private.validated',
  },
  NEO_PROPERTIES_VIEW: {
    url: '/pro/properties/view/:id',
    component: NeoPropertiesViewScreen,
    routeType: 'private.validated',
  },
  NEO_PROPERTIES_CREATE: {
    url: '/pro/properties/create',
    component: NeoPropertiesCreateScreen,
    routeType: 'private.unstyled',
  },
  NEO_DIFFUSION_SUMMARY: {
    url: '/pro/diffusion/summary/:id',
    component: NeoDiffusionSummaryScreen,
    routeType: 'private.validated',
  },
  NEO_DIFFUSION_CHART: {
    url: '/pro/diffusion/chart/:id',
    component: NeoDiffusionChartScreen,
    routeType: 'private.validated',
  },
  NEO_ADMIN_PROPERTIES_LIST: {
    url: '/admin/neo/properties',
    component: AdminNeoPropertiesListScreen,
    routeType: 'admin',
  },
  NEO_ADMIN_PROPERTIES_VIEW: {
    url: '/admin/neo/properties/:id',
    component: AdminNeoPropertiesViewScreen,
    routeType: 'admin',
  },
  NEO_ADMIN_PLATFORMS: {
    url: '/admin/neo/platforms',
    component: AdminNeoPlatformsListScreen,
    routeType: 'admin',
  },
};
