import { MBProUserSignature } from '@meilleursbiens/types';

import { MBApi } from '../../../../index';

export default class MBProAdminSignatureAPI {
  static listAvailable(user_id: number) {
    return MBApi.url('/v1/mbpro/admin/agent/signatures/' + user_id)
      .needAuth()
      .get<MBProUserSignature[]>();
  }

  static askSign(user_id: number, title: string, message: string, file_name: string, file: File) {
    let formData = new FormData();
    formData.append('file', file);
    formData.append('title', title);
    formData.append('message', message);
    formData.append('file_name', file_name);

    return MBApi.url('/v1/mbpro/admin/agent/signatures/' + user_id + '/sign')
      .needAuth()
      .formData(formData)
      .contentFormData()
      .postUpload();
  }

  static askDocument(id: number, title: string, message: string, file_name: string) {
    return MBApi.url('/v1/mbpro/admin/agent/signatures/' + id + '/document')
      .needAuth()
      .parameters({
        title: title,
        message: message,
        file_name: file_name,
      })
      .post();
  }

  static deleteAsk(id: number) {
    return MBApi.url('/v1/mbpro/admin/agent/signatures/' + id)
      .needAuth()
      .delete();
  }
}
