import { ServicesConstant } from '@meilleursbiens/constants';

import StringUtils from './StringUtils';

export default class ImageUtils {
  static getFileName(extension: string): string {
    const fileName = this.getFileNameV1(extension);
    const split = fileName.split('.');
    if (split.length === 0) {
      return fileName;
    }
    return fileName.split('.')[0].toUpperCase() + '.png';
  }

  static getFileNameV1(extension: string): string {
    try {
      switch (extension) {
        case 'pdf':
          return 'pdf.png';
        case 'png':
          return 'png.png';
        case 'docx':
          return 'pdf.png';
        case 'doc':
          return 'pdf.png';
        case 'docm':
          return 'pdf.png';
        case 'dotx':
          return 'pdf.png';
        case 'docb':
          return 'pdf.png';
        case 'jpg':
          return 'jpg.png';
        case 'jpeg':
          return 'jpg.png';
        case 'csv':
          return 'csv.png';
        case 'exe':
          return 'exe.png';
        case 'xls':
          return 'xsl.png';
        case 'xlt':
          return 'xsl.png';
        case 'xlm':
          return 'xsl.png';
        case 'xlsx':
          return 'xsl.png';
        case 'xltx':
          return 'xsl.png';
        case 'xltm':
          return 'xsl.png';
        case 'ppt':
          return 'ppt.png';
        case 'pot':
          return 'ppt.png';
        case 'pts':
          return 'ppt.png';
        case 'zip':
          return 'zip.png';
        case 'rar':
          return 'zip.png';
        case 'tar':
          return 'zip.png';
        default:
          return 'pdf.png';
      }
    } catch (e) {
      return 'pdf.png';
    }
  }

  static fileExtensionLogo(file: string): string {
    const extension = StringUtils.getFileExtension(file);

    return this.getUrlFileIcon() + this.getFileName(extension);
  }

  static getUrlFileIcon(): string {
    return 'https://mb-portail.s3.eu-west-3.amazonaws.com/files_type/';
  }

  static fileTypeS3Icon(type: string): string {
    switch (type) {
      case 'dir':
        return 'folder';
      case 'file':
        return 'picture_as_pdf';
      default:
        return 'picture_as_pdf';
    }
  }

  static miniSiteImages = [
    ServicesConstant.AWS.S3_PORTAIL + 'images/backgrounds/bg_10.png',
    ServicesConstant.AWS.S3_PORTAIL + 'images/backgrounds/bg_11.png',
    ServicesConstant.AWS.S3_PORTAIL + 'images/backgrounds/bg_12.png',
    ServicesConstant.AWS.S3_PORTAIL + 'images/backgrounds/bg_13.png',
    ServicesConstant.AWS.S3_PORTAIL + 'images/backgrounds/bg_14.png',
    ServicesConstant.AWS.S3_PORTAIL + 'images/backgrounds/bg_15.png',
    ServicesConstant.AWS.S3_PORTAIL + 'images/backgrounds/bg_16.png',
    ServicesConstant.AWS.S3_PORTAIL + 'images/backgrounds/bg_17.png',
    ServicesConstant.AWS.S3_PORTAIL + 'images/backgrounds/bg_18.png',
  ];

  static iaRoomMBImages = [
    {
      value: 'modern',
      url: ServicesConstant.AWS.S3_PORTAIL + 'images/ia-generation-covers/morden.webp',
      label: 'Moderne',
    },
    {
      value: 'minimalist',
      url: ServicesConstant.AWS.S3_PORTAIL + 'images/ia-generation-covers/minimalist.webp',
      label: 'Minimaliste',
    },
    {
      value: 'professional',
      url: ServicesConstant.AWS.S3_PORTAIL + 'images/ia-generation-covers/professional.webp',
      label: 'Professionnel',
    },
    {
      value: 'vintage',
      url: ServicesConstant.AWS.S3_PORTAIL + 'images/ia-generation-covers/vintage.webp',
      label: 'Vintage',
    },
    {
      value: 'industrial',
      url: ServicesConstant.AWS.S3_PORTAIL + 'images/ia-generation-covers/industrial.webp',
      label: 'Industriel',
    },
    {
      value: 'neoclassic',
      url: ServicesConstant.AWS.S3_PORTAIL + 'images/ia-generation-covers/neoclassic.webp',
      label: 'Néo-classique',
    },
    {
      value: 'coastal',
      url: ServicesConstant.AWS.S3_PORTAIL + 'images/ia-generation-covers/coastal.webp',
      label: 'Littorale',
    },
    {
      value: 'tropical',
      url: ServicesConstant.AWS.S3_PORTAIL + 'images/ia-generation-covers/tropical.webp',
      label: 'Tropicale',
    },
  ];
}
