import React from 'react';

import { lazyRetry } from '@meilleursbiens/utils';

const MBSupportCrispWidgetScreenProps = React.lazy(() =>
  lazyRetry(() => import('../screens/MBSupport/MBSupportCrispWidgetScreen'), 'MBSupportCrispWidgetScreenProps')
);

const MBProAccountSupportScreen = React.lazy(() =>
  lazyRetry(() => import('../screens/MBSupport/MBProAccountSupportScreen'), 'MBProAccountSupportScreen')
);

const MBSupportAdminTicketsScreen = React.lazy(() =>
  lazyRetry(() => import('../screens/MBSupport/admin/MBSupportAdminTicketsScreen'), 'MBSupportAdminTicketsScreen')
);

export default {
  SUPPORT_PRO_LIST: {
    url: '/pro/support',
    component: MBProAccountSupportScreen,
    routeType: 'private.validated',
  },
  SUPPORT_ADMIN_PRO_LIST: {
    url: '/admin/support',
    component: MBSupportAdminTicketsScreen,
    routeType: 'admin',
  },
  SUPPORT_CRISP_WIDGET: {
    url: '/pro/support/crisp-widget',
    component: MBSupportCrispWidgetScreenProps,
    routeType: 'public',
  },
};
