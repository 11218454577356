import React from 'react';

import { StarIcon } from '@chakra-ui/icons';
import { Avatar, AvatarBadge, Box, HStack, Text, forwardRef, useColorModeValue } from '@chakra-ui/react';
import { MBProUser } from '@meilleursbiens/types';

import { PersonaProps } from '../Persona/Persona';

export interface PersonaUserProps extends Partial<PersonaProps> {
  user: MBProUser;
}

const PersonaUser = forwardRef<PersonaUserProps, 'div'>((props: PersonaUserProps) => {
  const sizeToFontSize = {
    xs: 11,
    sm: 13,
    md: 16,
    lg: 18,
    xl: 20,
    '2xl': 24,
  };

  function Badge() {
    if (props.presence === 'online') {
      return <AvatarBadge boxSize="1.25em" bg="green.500" />;
    }
    if (props.presence === 'offline') {
      return <AvatarBadge boxSize="1.25em" bg="gray.500" />;
    }
    if (props.presence === 'away') {
      return <AvatarBadge boxSize="1.25em" bg="yellow.500" />;
    }
    if (props.presence === 'busy') {
      return;
    }
    if (props.presence === 'star') {
      return (
        <AvatarBadge boxSize="1.25em" bg="yellow.400">
          <StarIcon w={'0.7em'} color="yellow.50" />
        </AvatarBadge>
      );
    }
    return <></>;
  }

  if (props.user == undefined) return <></>;

  return (
    <HStack
      position={'relative'}
      spacing={2}
      onClick={() => {
        if (props.onClick) {
          props.onClick();
        }
      }}
      data-tag="allowRowEvents"
      {...props}
    >
      <Avatar src={props.user.profile_picture || props.avatarUrl} size={props.size} {...props.avatarProps}>
        <Badge />
      </Avatar>
      {props.user || props.secondaryText ? (
        <Box>
          <HStack>
            <Text
              noOfLines={1}
              fontSize={sizeToFontSize[props.size || 'md']}
              fontWeight={600}
              lineHeight={'1em'}
              color={useColorModeValue('gray.900', 'white')}
              {...props.nameProps}
            >
              {props.user.firstName} {props.user.name}
            </Text>
            {props.nameRightElement && props.nameRightElement}
          </HStack>
          {props.secondaryText && (
            <HStack spacing={1}>
              <Text noOfLines={1} color={'muted'} fontSize={sizeToFontSize[props.size || 'md'] - 2}>
                {props.secondaryText}
              </Text>
              {props.secondaryTextRightElement && props.secondaryTextRightElement}
            </HStack>
          )}
        </Box>
      ) : null}
    </HStack>
  );
});

export default PersonaUser;
