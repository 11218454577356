import * as types from '../../actions/ActionTypes';

const initialState = {
  modalMandat: false,
};

export default function modals(state = initialState, action = {}) {
  switch (action.type) {
    case types.MODAL_OPEN_MANDAT:
      return {
        ...state,
        modalMandat: true,
      };
    case types.MODAL_CLOSE_MANDAT:
      return {
        ...state,
        modalMandat: false,
      };
    default:
      return state;
  }
}
