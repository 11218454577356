import React from 'react';
import SignatureCanvas from 'react-signature-canvas';

import {
  Badge,
  Box,
  Center,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
  UseDisclosureReturn,
} from '@chakra-ui/react';
import { MBProSignatureAPI } from '@meilleursbiens/api';
import { MBProUserSignature } from '@meilleursbiens/types';
import { PDFViewer, SignPad } from '@meilleursbiens/ui';
import { ToastUtils } from '@meilleursbiens/utils';

interface MBProUsersSignatureUIModalProps {
  signature: MBProUserSignature;
  onComplete: (signature: MBProUserSignature) => void;
  disclosure: UseDisclosureReturn;
}
export default function MBProUsersSignatureUIModal(props: MBProUsersSignatureUIModalProps) {
  const { signature, onComplete } = props;

  const { isOpen, onOpen, onClose } = props.disclosure;

  const [isLoading, setIsLoading] = React.useState(false);

  const signDocument = (signature_file: File) => {
    setIsLoading(true);
    MBProSignatureAPI.signDocument(signature.id, signature_file)
      .then((response) => {
        onComplete(signature);
        onClose();
      })
      .catch((error) => {
        ToastUtils.showError(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Modal size={isLoading ? 'md' : '2xl'} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <HStack>
            <Badge colorScheme={'purple'}>Signature</Badge>
            <Text>Signature en ligne</Text>
          </HStack>
        </ModalHeader>
        <ModalCloseButton />
        {!isLoading && (
          <>
            <ModalBody>
              <Text color={'muted'} fontSize={'sm'}>
                Lisez le document ci-dessous, une fois que vous êtes prêt, signer dans la case ci-dessous pour signer le
                document
              </Text>

              <Box h={'400px'} mb={4}>
                <PDFViewer src={signature.file_url} />
              </Box>
            </ModalBody>

            <ModalFooter mt={8}>
              <SignPad onComplete={signDocument} />
            </ModalFooter>
          </>
        )}
        {isLoading && (
          <>
            <ModalBody>
              <Center py={'150px'} gap={4}>
                <Spinner size={'md'} />
                <Text color={'muted'} textAlign={'center'}>
                  Signature du document en-cours...
                </Text>
              </Center>
            </ModalBody>
          </>
        )}
      </ModalContent>
    </Modal>
  );
}
