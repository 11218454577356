import React from 'react';
import { useDropzone } from 'react-dropzone';
import SignatureCanvas from 'react-signature-canvas';

import {
  Badge,
  Box,
  Center,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Stack,
  Text,
  UseDisclosureReturn,
  VStack,
  useColorModeValue,
} from '@chakra-ui/react';
import { CloudArrowDownIcon as CloudUploadIcon } from '@heroicons/react/24/outline';
import { MBProSignatureAPI } from '@meilleursbiens/api';
import { MBProUserSignature } from '@meilleursbiens/types';
import { PDFViewer, SignPad } from '@meilleursbiens/ui';
import { ToastUtils } from '@meilleursbiens/utils';

interface MBProUsersSignatureUploadUIModalProps {
  signature: MBProUserSignature;
  onComplete: (signature: MBProUserSignature) => void;
  disclosure: UseDisclosureReturn;
}
export default function MBProUsersSignatureUploadUIModal(props: MBProUsersSignatureUploadUIModalProps) {
  const { signature, onComplete } = props;

  const { isOpen, onOpen, onClose } = props.disclosure;

  const [isLoading, setIsLoading] = React.useState(false);

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: { 'image/*': ['.png', '.jpeg', '.jpg', '.pdf'] },
    multiple: false,
    autoFocus: true,
    maxFiles: 1,
    maxSize: 1024 * 1024 * 10,
    onDropAccepted: (files, event) => {
      if (!isLoading) {
        uploadDocument(files[0]);
      }
    },
    onDropRejected: (files, event) => {
      let maxCount = 0;
      files.map((file, i) => {
        if (file.errors[0].code === 'file-invalid-type') {
          ToastUtils.showError(
            'Le format du document n°' + (i + 1) + ' est invalide ! (Formats acceptés: png, jpeg, jpg)'
          );
          maxCount++;
        } else if (file.errors[0].code === 'file-too-large') {
          ToastUtils.showError('La taille du document n°' + (i + 1) + ' est trop grand ! (Taille max: 10Mo)');
          maxCount++;
        } else if (file.errors[0].code === 'too-many-files') {
          ToastUtils.showError('Vous ne pouvez pas ajouter plus de 30 documents');
          maxCount++;
        }
      });
    },
  });

  const uploadDocument = (signature_file: File) => {
    setIsLoading(true);
    MBProSignatureAPI.uploadDocument(signature.id, signature_file)
      .then((response) => {
        onComplete(signature);
        onClose();
      })
      .catch((error) => {
        ToastUtils.showError(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Modal size={'md'} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <HStack>
            <Badge colorScheme={'purple'}>Envoi d'un document</Badge>
            <Text>{signature.title}</Text>
          </HStack>
        </ModalHeader>
        <ModalCloseButton />
        {!isLoading && (
          <>
            <ModalBody>
              <Text color={'muted'} fontSize={'sm'}>
                {signature.message}
              </Text>

              <Stack
                {...getRootProps({
                  className: 'dropzone dropzone-register ' + (isLoading && 'is-loading'),
                })}
                mt={2}
                mb={4}
              >
                <input {...getInputProps()} />
                <VStack>
                  <CloudUploadIcon width={60} />
                  <Text style={{ fontSize: 17, marginTop: 10 }} fontWeight={500}>
                    Glissez votre document / Cliquez pour choisir
                  </Text>
                  <Text style={{ marginTop: 3, fontSize: 11 }} color={useColorModeValue('gray.400', '#E7E7E7')}>
                    Taille max: 10Mo | Formats acceptés : pdf, jpg, jpeg, png
                  </Text>
                </VStack>
              </Stack>
            </ModalBody>
          </>
        )}
        {isLoading && (
          <>
            <ModalBody>
              <Center py={'150px'} gap={4}>
                <Spinner size={'md'} />
                <Text color={'muted'} textAlign={'center'}>
                  Envoi du document en-cours...
                </Text>
              </Center>
            </ModalBody>
          </>
        )}
      </ModalContent>
    </Modal>
  );
}
