import React from 'react';

import TextInput, { TextInputProps } from '../TextInput/TextInput';

export interface NumberInputProps extends TextInputProps {
  min?: number;
  max?: number;
  step?: number;
}

const NumberInput = (props: NumberInputProps) => {
  return <TextInput type="number" {...props} />;
};

export default NumberInput;
