import React, { useCallback, useState } from 'react';

import PermissionContext from './PermissionContext';

export interface UserPayload {
  id: string;
  permissions: string[];
}

const LOCAL_STORAGE_KEY_USER = 'meilleursbiens-permissions';

// @ts-ignore
const PermissionProvider: React.FunctionComponent = ({ children }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const updateUser = (newUser: UserPayload) => {
    localStorage.setItem(LOCAL_STORAGE_KEY_USER, JSON.stringify(newUser));
  };

  const isAuthorized = useCallback(async (permissionNames?: string[]): Promise<boolean> => {
    let hasAuthorization = false;
    const userLocalStorage: any = localStorage.getItem(LOCAL_STORAGE_KEY_USER);
    const storedUser = JSON.parse(userLocalStorage);

    setIsLoading(true);
    if (storedUser) {
      hasAuthorization = CheckUserHasRolesOrPermissions(storedUser, permissionNames);
    }
    setIsLoading(false);

    return hasAuthorization;
  }, []);

  const isAuthorizedSync = useCallback((permissionNames?: string[]) => {
    let hasAuthorization = false;
    const userLocalStorage: any = localStorage.getItem(LOCAL_STORAGE_KEY_USER);
    const storedUser = JSON.parse(userLocalStorage);

    setIsLoading(true);
    if (storedUser) {
      hasAuthorization = CheckUserHasRolesOrPermissions(storedUser, permissionNames);
    }
    setIsLoading(false);

    return hasAuthorization;
  }, []);

  const CheckUserHasRolesOrPermissions = (storedUser: UserPayload, permissionNames?: string[]) => {
    let hasPermissions = false;

    if (storedUser.permissions && permissionNames && storedUser.permissions.length > 0) {
      const userPermissions = storedUser.permissions;

      const intersection = userPermissions.filter((permission) => permissionNames.includes(permission));
      hasPermissions = intersection.length > 0;
    }

    return hasPermissions;
  };

  return (
    <PermissionContext.Provider
      value={{
        // @ts-ignore
        setUser: updateUser,
        isAuthorized,
        isAuthorizedSync,
        isLoading,
      }}
    >
      {children}
    </PermissionContext.Provider>
  );
};

export default PermissionProvider;
