import MBProAPI from '../../../MBProAPI';

export default class MBProAccountAPI {
  static account() {
    return new Promise((resolve, reject) => {
      MBProAPI.url('/v1/mbpro/auth/account')
        .needAuth()
        .get()
        .then((r) => {
          if (r.data.error) {
            reject(r.data.message);
          } else {
            resolve(r.data.data);
          }
        })
        .catch((e) => {
          reject(e.message);
        });
    });
  }

  static editAccount(values: object) {
    return new Promise((resolve, reject) => {
      MBProAPI.url('/v1/mbpro/auth/account')
        .parameters(values)
        .needAuth()
        .post()
        .then((r) => {
          if (r.data.error) {
            reject(r.data.message);
          } else {
            resolve(r.data.data);
          }
        })
        .catch((e) => {
          reject(e.message);
        });
    });
  }

  static editAccountV2(values: any, isTva: boolean = false) {
    let formData = new FormData();
    formData.append('rib', values.rib);
    if (isTva) {
      formData.append('numero_tva', values.numero_tva);
      formData.append('justificatif_tva', values.justificatif_tva);
    }

    return new Promise((resolve, reject) => {
      MBProAPI.url('/v1/mbpro/auth/account/V2')
        .contentFormData()
        .formData(formData)
        .needAuth()
        .postUpload()
        .then((r) => {
          if (r.data.error) {
            reject(r.data.message);
          } else {
            resolve(r.data.data);
          }
        })
        .catch((e) => {
          reject(e.message);
        });
    });
  }

  static editProfile(values: object) {
    return new Promise((resolve, reject) => {
      MBProAPI.url('/v1/mbpro/auth/account/profil')
        .parameters({
          values,
        })
        .needAuth()
        .post()
        .then((r) => {
          if (r.data.error) {
            reject(r.data.message);
          } else {
            resolve(r.data.data);
          }
        })
        .catch((e) => {
          reject(e.message);
        });
    });
  }

  static listSecurityLogs() {
    return new Promise((resolve, reject) => {
      MBProAPI.url('/v1/mbpro/auth/account/security-logs')
        .needAuth()
        .get()
        .then((r) => {
          if (r.data.error) {
            reject(r.data.message);
          } else {
            resolve(r.data.data);
          }
        })
        .catch((e) => {
          reject(e.message);
        });
    });
  }

  static addParrainage(values: object) {
    return new Promise((resolve, reject) => {
      MBProAPI.url('/v1/mbpro/auth/account/parrainage')
        .needAuth()
        .parameters({
          ...values,
        })
        .post()
        .then((r) => {
          if (r.data.error) {
            reject(r.data.message);
          } else {
            resolve(r.data.data);
          }
        })
        .catch((e) => {
          reject(e.message);
        });
    });
  }

  static twoFactorAuthSecret() {
    return new Promise((resolve, reject) => {
      MBProAPI.url('/v1/mbpro/auth/2fa')
        .needAuth()
        .get()
        .then((r) => {
          if (r.data.error) {
            reject(r.data.message);
          } else {
            resolve(r.data.data);
          }
        })
        .catch((e) => {
          reject(e.message);
        });
    });
  }

  static enableTwoFactorAuth(secret: string, code: string | number) {
    return new Promise((resolve, reject) => {
      MBProAPI.url('/v1/mbpro/auth/2fa/enable')
        .needAuth()
        .parameters({
          secret,
          code,
        })
        .post()
        .then((r) => {
          if (r.data.error) {
            reject(r.data.message);
          } else {
            resolve(r.data.data);
          }
        })
        .catch((e) => {
          reject(e.message);
        });
    });
  }

  static disableTwoFactorAuth() {
    return new Promise((resolve, reject) => {
      MBProAPI.url('/v1/mbpro/auth/2fa/disable')
        .needAuth()
        .post()
        .then((r) => {
          if (r.data.error) {
            reject(r.data.message);
          } else {
            resolve(r.data.data);
          }
        })
        .catch((e) => {
          reject(e.message);
        });
    });
  }
}
