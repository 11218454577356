import React from 'react';

import { useColorModeValue } from '@chakra-ui/react';

import logoSquare from '../images/logo-square.svg';
import logoWhiteV2 from '../images/logo-white-v2.svg';
import logoBackOffice from '../images/logo_back_office.svg';
import logoBlack from '../images/logo_black.svg';
import logoWhite from '../images/logo_blanc.svg';
import logoRed from '../images/logo_red_v2.svg';
import logoRedNoText from '../images/logos/logo_red_no_text_v2.svg';
import { useLogo } from '../utils/hooks/useLogo';

interface LogoProps {
  isSmall?: boolean;
  isBackOffice?: boolean;
  isModern?: boolean;
  isModernWhite?: boolean;
  isSquare?: boolean;
  white?: boolean;
  height?: number;
}

export default function Logo({
  isSmall = false,
  isBackOffice = false,
  isModern = false,
  isModernWhite = false,
  white = false,
  height = 20,
  isSquare = false,
}: LogoProps) {
  const logo = useLogo();

  // todo réactiver
  if (logo && false) {
    return <img src={logo} style={{ height: height ? height : 45 }} className="logo-black" alt="Logo" />;
  }

  if (isSquare) {
    return (
      <img
        src={logoSquare}
        style={{ height: height ? height : 45 }}
        className="logo-black"
        alt="Logo de MeilleursBiens"
      />
    );
  }

  if (isSmall) {
    return (
      <img
        src={logoRedNoText}
        style={{ height: height ? height : 45 }}
        className="logo-black"
        alt="Logo de MeilleursBiens"
      />
    );
  }

  if (isBackOffice) {
    return (
      <img
        src={logoBackOffice}
        style={{ height: height ? height : 45 }}
        className="logo-black"
        alt="Logo de MeilleursBiens"
      />
    );
  }

  if (isModern) {
    return (
      <img
        src={useColorModeValue(logoRed, logoWhiteV2)}
        style={{ height: height ? height : 45 }}
        className="logo-black"
        alt="Logo de MeilleursBiens"
      />
    );
  }

  if (isModernWhite) {
    return (
      <img
        src={logoWhiteV2}
        style={{ height: height ? height : 45 }}
        className="logo-black"
        alt="Logo de MeilleursBiens"
      />
    );
  }

  if (white) {
    return (
      <img
        src={logoWhite}
        style={{ height: height ? height : 45 }}
        className="logo-black"
        alt="Logo de MeilleursBiens"
      />
    );
  }

  return (
    <img src={logoBlack} style={{ height: height ? height : 45 }} className="logo-black" alt="Logo de MeilleursBiens" />
  );
}
