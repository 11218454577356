import * as React from 'react';

import { useBreakpointValue, useDisclosure } from '@chakra-ui/react';
import { createContext } from '@chakra-ui/react-utils';

import { Breakpoints, getBreakpoints, useSessionStorage } from '../Sidebar/Sidebar.utils';

export const [AppShellProvider, useAppShellContext] = createContext<ReturnType<typeof useAppShell>>({
  strict: false,
  errorMessage: 'AppShell context not available.',
});

export interface UseAppShellProps {
  toggleBreakpoint?: Breakpoints;
}

export const useAppShell = (props: UseAppShellProps) => {
  const [appShellDefaultStatus, setAppShellDefaultStatus] = useSessionStorage('mb_app_shell_default_value', true);

  const disclosure = useDisclosure({
    defaultIsOpen: appShellDefaultStatus,
  });

  const breakpoints = getBreakpoints(props.toggleBreakpoint);

  const isMobile = useBreakpointValue(breakpoints, {
    fallback: props.toggleBreakpoint || 'lg',
  });

  React.useEffect(() => {
    setAppShellDefaultStatus(disclosure.isOpen);
  }, [disclosure.isOpen, setAppShellDefaultStatus]);

  return {
    isSidebarOpen: disclosure.isOpen,
    closeSidebar: disclosure.onClose,
    openSidebar: disclosure.onOpen,
    toggleSidebar: disclosure.onToggle,
    isMobile,
  };
};
