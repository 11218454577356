import React from 'react';

import { Text, TextProps, forwardRef } from '@chakra-ui/react';

import defaultComponentDecorator from './decorators/defaultComponentDecorator';
import defaultHrefDecorator from './decorators/defaultHrefDecorator';
import defaultMatchDecorator from './decorators/defaultMatchDecorator';
import defaultTextDecorator from './decorators/defaultTextDecorator';

export interface TextLinkifyProps extends TextProps {
  children: React.ReactNode;
}

const TextLinkify = forwardRef<TextLinkifyProps, 'p'>((props: TextLinkifyProps, ref) => {
  const parseString = (string: string) => {
    if (string === '') {
      return string;
    }

    const matches = defaultMatchDecorator(string);
    if (!matches) {
      return string;
    }

    const elements = [];
    let lastIndex = 0;
    matches.forEach((match, i) => {
      // Push preceding text if there is any
      if (match.index > lastIndex) {
        elements.push(string.substring(lastIndex, match.index));
      }

      const decoratedHref = defaultHrefDecorator(match.url);
      const decoratedText = defaultTextDecorator(match.text);
      const decoratedComponent = defaultComponentDecorator(decoratedHref, decoratedText, i);
      elements.push(decoratedComponent);

      lastIndex = match.lastIndex;
    });

    if (string.length > lastIndex) {
      elements.push(string.substring(lastIndex));
    }

    return elements.length === 1 ? elements[0] : elements;
  };

  // @ts-ignore
  const parse = (children: any, key = 0) => {
    if (typeof children === 'string') {
      return parseString(children);
    } else if (React.isValidElement(children) && children.type !== 'a' && children.type !== 'button') {
      return React.cloneElement(
        children,
        { key: key },
        // @ts-ignore
        parse(children.props.children)
      );
    } else if (Array.isArray(children)) {
      return children.map((child, i) => parse(child, i));
    }

    return children;
  };

  return <Text {...props}>{parse(props.children)}</Text>;
});

export default TextLinkify;
