import { MODAL_CLOSE_MANDAT, MODAL_OPEN_MANDAT } from '../ActionTypes';

export const openMandatModal = (data) => ({
  type: MODAL_OPEN_MANDAT,
  payload: data,
});
export const closeMandatModal = (data) => ({
  type: MODAL_CLOSE_MANDAT,
  payload: data,
});
