import { MBProTeam, MBProTeamInvite, WatermarkPosition } from '@meilleursbiens/types';

import { MBApi } from '../../../index';

export default class MBProTeamsAPI {
  static getTeam(id: number) {
    return MBApi.url('/v1/mbpro/auth/teams/' + id)
      .needAuth()
      .get<{
        team: MBProTeam;
        isAdmin: boolean;
      }>();
  }

  static getTeams() {
    return MBApi.url('/v1/mbpro/auth/teams').needAuth().get<{
      current_team: MBProTeam | null;
      teams: MBProTeam[];
      current_team_members: number;
    }>();
  }

  static uploadProfilePicture(id: number, profilePicture: any) {
    const formData = new FormData();
    formData.append('file', profilePicture);

    return MBApi.url('/v1/mbpro/auth/teams/profile_picture/' + id)
      .needAuth()
      .contentFormData()
      .formData(formData)
      .postUpload<{
        team: MBProTeam;
      }>();
  }

  static uploadLogo(id: number, logo: any) {
    const formData = new FormData();
    formData.append('file', logo);

    return MBApi.url('/v1/mbpro/auth/teams/logo/' + id)
      .needAuth()
      .contentFormData()
      .formData(formData)
      .postUpload<{
        team: MBProTeam;
      }>();
  }

  static editTeam(id: number, key: string, value: any) {
    return MBApi.url('/v1/mbpro/auth/teams/' + id)
      .needAuth()
      .parameters({
        key,
        value,
      })
      .patch<{
        team: MBProTeam;
      }>();
  }

  static switchTeam(teamId: number) {
    return MBApi.url('/v1/mbpro/auth/teams/switch/' + teamId)
      .needAuth()
      .patch<{
        team: MBProTeam;
      }>();
  }

  static inviteUser(teamId: number, userId: number) {
    return MBApi.url('/v1/mbpro/auth/teams/invites/' + teamId + '/' + userId)
      .needAuth()
      .put<{}>();
  }

  static deleteUserFromTeam(teamId: number, userId: number) {
    return MBApi.url('/v1/mbpro/auth/teams/invites/' + teamId + '/' + userId)
      .needAuth()
      .delete<{}>();
  }

  static listInvitations() {
    return MBApi.url('/v1/mbpro/auth/teams/invites').needAuth().get<{
      invites: MBProTeamInvite[];
    }>();
  }

  static updateWatermark(teamId: number, watermark_logo: File, watermark_opacity: number, watermark_position: WatermarkPosition) {
    const formData = new FormData();
    formData.append('file', watermark_logo);
    formData.append('opacity', watermark_opacity.toString());
    formData.append('position', watermark_position);

    return MBApi.url('/v1/mbpro/auth/teams/watermark/' + teamId)
      .contentFormData()
      .formData(formData)
      .needAuth()
      .postUpload<MBProTeam>();
  }

  static deleteWatermark(teamId: number) {
    return MBApi.url('/v1/mbpro/auth/teams/watermark/' + teamId)
      .needAuth()
      .delete<MBProTeam>();
  }
}
