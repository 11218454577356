import { anatomy } from '@chakra-ui/theme-tools';

export const appShellAnatomy = anatomy('app-shell').parts('container', 'inner', 'main');

export const sidebarAnatomy = anatomy('sidebar').parts('container', 'overlay', 'section', 'toggleWrapper', 'toggle');

export const navGroupAnatomy = anatomy('nav-group').parts('container', 'title', 'icon', 'content');

export const navItemAnatomy = anatomy('nav-item').parts('item', 'link', 'inner', 'icon', 'label');

export const toolbarItem = anatomy('toolbar').parts('container', 'inner');

export const toolbarGroupAnatomy = anatomy('nav-group').parts('container');
