import { MBProTransaction, MBProUser } from '@meilleursbiens/types';

import { MBApi } from '../../../index';

export default class MBCoreSearchAPI {
  static search(search: string) {
    return MBApi.url('/v1/mbpro/home/search')
      .parameters({
        search,
      })
      .needAuth()
      .post<{
        suggestions: {
          marketplace: any[];
          documents: any[];
          transactions: MBProTransaction[];
          transactions_locations: any[];
        };
      }>();
  }

  static searchAgent(search: string) {
    return MBApi.url('/v1/mbpro/auth/teams/invites/search-agent')
      .parameters({
        search,
      })
      .needAuth()
      .post<{
        users: MBProUser[];
      }>();
  }
}
