import React, { Suspense } from 'react';
import { Navigate } from 'react-router';
import { Routes as RRoutes, Route } from 'react-router-dom';

import { LoadingPage } from '@meilleursbiens/ui';

import AccountSkeletonLayoutComponent from '../components/Layout/Account/AccountSkeletonLayoutComponent';
import AdminSkeletonLayoutComponent from '../components/Layout/Admin/AdminSkeletonLayoutComponent';
import NotFound from '../components/NotFound';
import AdminRoute from '../middlewares/AdminRoute';
import PrivateValidatedRoute from '../middlewares/PrivateValidatedRoute';
import TestScreen from '../screens/TestScreen';
import admin from './admin.routes';
import auth from './auth.routes';
import estimation from './estimation.routes';
import harp from './harp.routes';
import neo from './neo.routes';
import pro from './pro.routes';
import support from './support.routes';

/**
 * Liste des routes de l'application
 */
export const Routes = {
  ...auth,
  ...neo,
  ...estimation,
  ...pro,
  ...support,
  ...admin,
  ...harp,
} as const satisfies Record<string, RouteDetailType>;

export function getRoutesForRouter() {
  return (
    <RRoutes>
      <Route path="/" element={<Navigate to="/auth/login" />} />

      {Object.values(Routes).map((route, index) => {
        if (route.routeType === 'admin')
          return (
            <Route
              key={'admin-route-' + index}
              path={route.url}
              // @ts-ignore
              exact={true}
              element={
                <AdminRoute>
                  <AdminSkeletonLayoutComponent>
                    <Suspense fallback={<LoadingPage />}>{React.createElement(route.component)}</Suspense>
                  </AdminSkeletonLayoutComponent>
                </AdminRoute>
              }
            />
          );
      })}

      {Object.values(Routes).map((route, index) => {
        if (route.routeType === 'private.validated')
          return (
            <Route
              key={'pro-route-' + index}
              path={route.url}
              // @ts-ignore
              exact={true}
              element={
                <PrivateValidatedRoute>
                  <AccountSkeletonLayoutComponent>
                    <Suspense fallback={<LoadingPage />}>{React.createElement(route.component)}</Suspense>
                  </AccountSkeletonLayoutComponent>
                </PrivateValidatedRoute>
              }
            />
          );
        if (route.routeType === 'private.unstyled')
          return (
            <Route
              key={'pro-route-' + index}
              path={route.url}
              // @ts-ignore
              exact={true}
              element={
                <PrivateValidatedRoute>
                  <Suspense fallback={<LoadingPage />}>{React.createElement(route.component)}</Suspense>
                </PrivateValidatedRoute>
              }
            />
          );
      })}

      <>
        {Object.values(Routes).map((route, index) => {
          if (route.routeType === 'public')
            return (
              <Route
                key={'route-index'}
                path={route.url}
                // @ts-ignore
                exact={true}
                element={<Suspense fallback={<LoadingPage />}>{React.createElement(route.component)}</Suspense>}
              />
            );
        })}
      </>

      <Route
        path={'/test'}
        element={
          <PrivateValidatedRoute>
            <TestScreen />
          </PrivateValidatedRoute>
        }
      />

      <Route path={'*'} element={<NotFound />} />
    </RRoutes>
  );
}

export interface RouteDetailType {
  component: React.ComponentType<any> | undefined;
  routeType: 'admin' | 'private.validated' | 'private.unstyled' | 'public' | string;
  url: string;
  relativeUrl?: string;
}

export interface RouteType {
  [key: string]: RouteDetailType;
}
