import { MBProAgentInvoice, PaginationAPI } from '@meilleursbiens/types';

import { MBApi } from '../../../../index';

export default class MBProDocumentsRCPAPI {
  static upload(file: File) {
    let formData = new FormData();
    formData.append('file', file);

    return MBApi.url('/v1/mbpro/documents/rcp/upload').needAuth().contentFormData().formData(formData).postUpload<{}>();
  }
}
