import moment from 'moment';

import MBProAPI from '../../MBProAPI';

export default class MBProDashboardAPI {
  static dashboardRange() {
    return new Promise((resolve, reject) => {
      MBProAPI.url('/v1/mbpro/home/dashboard')
        .parameters({
          rangeStart: moment().startOf('year').toISOString(),
          rangeEnd: moment().endOf('year').toISOString(),
        })
        .needAuth()
        .needCache(60, 'MBProDashboardAPI.dashboardRange')
        .post()
        .then((r) => {
          if (r.data.error) {
            reject(r.data.message);
          } else {
            resolve(r.data.data);
          }
        })
        .catch((e) => {
          reject(e.message);
        });
    });
  }

  static usefullLink() {
    return new Promise((resolve, reject) => {
      MBProAPI.url('/v1/mbpro/home/usefull-link')
        .needAuth()
        .needCache(60)
        .get()
        .then((r) => {
          if (r.data.error) {
            reject(r.data.message);
          } else {
            resolve(r.data.data);
          }
        })
        .catch((e) => {
          reject(e.message);
        });
    });
  }
}
