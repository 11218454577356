import React, { useEffect } from 'react';

import {
  Badge,
  Flex,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { MBProSignatureAPI } from '@meilleursbiens/api';
import { MBProUserSignature } from '@meilleursbiens/types';
import { Button } from '@meilleursbiens/ui';

import { MBProUsersSignatureCardComponent } from './index';

export default function MBProUsersSignatureUIModal() {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [signatures, setSignatures] = React.useState<MBProUserSignature[]>([]);

  const _fetchSignatures = () => {
    MBProSignatureAPI.listAvailable().then((response) => {
      setSignatures(response.signatures);
    });
  };

  useEffect(() => {
    _fetchSignatures();
  }, []);

  useEffect(() => {
    if (signatures.length == 0 || signatures === undefined) {
      return;
    }

    let needToPresent = true;

    const isAlreadyPresentedThisHour = sessionStorage.getItem('mb_pro_users_signature_modal');
    if (isAlreadyPresentedThisHour) {
      const lastPresented = new Date(isAlreadyPresentedThisHour);
      const today = new Date();

      if (today.getTime() - lastPresented.getTime() < 1000 * 60 * 60) {
        return;
      }

      sessionStorage.setItem('mb_pro_users_signature_modal', new Date().toISOString());
      needToPresent = true;
    } else needToPresent = true;

    if (needToPresent) onOpen();
  }, [signatures]);

  const _onComplete = (signature: MBProUserSignature) => {
    const signaturesNew = signatures.filter((s) => s.id !== signature.id);
    setSignatures(signaturesNew);
    if (signaturesNew.length === 0) {
      onClose();
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <HStack>
            <Badge colorScheme={'red'}>IMPORTANT</Badge>
            <Text>Des demandes sont en attentes</Text>
          </HStack>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text mb={4} color={'muted'} fontSize={'sm'}>
            Une signature ou des documents vous sont demandés, pour pouvoir continuer merci de bien vouloir traiter ces
            demandes :
          </Text>

          {signatures.map((signature) => {
            return (
              <MBProUsersSignatureCardComponent
                key={'signature-' + signature.id}
                signature={signature}
                onComplete={_onComplete}
                onCancel={onClose}
              />
            );
          })}

          <Text fontSize={'xs'} color={'muted'} mt={4}>
            Si vous souhaitez traiter ces demandes plus tard, cliquez sur le bouton "Me le rappeler plus tard" :
          </Text>
          <Flex justify={'flex-end'} mb={2}>
            <Button size={'sm'} variant={'outline'} onClick={onClose}>
              Me le rappeler dans 1 heure
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
