import React from 'react';

import { Box, Flex, chakra, useStyleConfig } from '@chakra-ui/react';
import { HTMLChakraProps } from '@chakra-ui/system';
import { cx } from '@chakra-ui/utils';

export interface ToolbarProps extends HTMLChakraProps<'div'> {
  children: React.ReactNode;
}

const Toolbar = (props: ToolbarProps) => {
  const styles = useStyleConfig('Toolbar', props);

  return (
    <chakra.div
      // @ts-ignore
      __css={styles.container}
      {...props}
      className={cx('mb_ui_layouts_toolbar', props.className)}
    ></chakra.div>
  );
};

export default Toolbar;
