import React, { useEffect } from 'react';

import { MBCoreAIPromptsAPI } from '@meilleursbiens/api';
import { useSelection } from '@meilleursbiens/hooks';
import { MBCoreAIPrompt, MBCoreAIPromptCategory } from '@meilleursbiens/types';
import { Badge, Card, TextAreaInput, TextAreaInputProps } from '@meilleursbiens/ui';
import { ToastUtils } from '@meilleursbiens/utils';

import {
  Box,
  Divider,
  HStack,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Slide,
  Text,
  useColorModeValue,
  useDisclosure,
  useOutsideClick,
} from '@chakra-ui/react';
import {
  ArrowUturnLeftIcon,
  ArrowsRightLeftIcon,
  ChevronRightIcon,
  CubeTransparentIcon,
  DocumentTextIcon,
  HomeModernIcon,
  LanguageIcon,
  QuestionMarkCircleIcon,
  UserCircleIcon,
  UsersIcon,
} from '@heroicons/react/24/outline';
import { SparklesIcon } from '@heroicons/react/24/solid';

import AIPromptHeaderComponent from './AIPromptHeaderComponent';
import AIPromptLoadingComponent from './AIPromptLoadingComponent';
import APIPromptRowComponent from './APIPromptRowComponent';
import AIPromptPropertyChooseMenuComponent from './categories/AIPromptPropertyChooseMenuComponent';
import AIPromptContentEditableInputComponent from './form/AIPromptContentEditableInputComponent';

interface AIPromptModalComponentProps extends TextAreaInputProps {
  label?: string;
}

const _categoryToIcon = (category: MBCoreAIPromptCategory) => {
  switch (category.icon) {
    case 'users':
      return <UsersIcon strokeWidth={2} width={20} color={'#3502b0'} />;
    case 'estimation':
      return <CubeTransparentIcon strokeWidth={2} width={20} color={'#3502b0'} />;
    case 'transaction':
      return <ArrowsRightLeftIcon strokeWidth={2} width={20} color={'#3502b0'} />;
    case 'diffusion':
      return <HomeModernIcon strokeWidth={2} width={20} color={'#3502b0'} />;
    case 'client':
      return <UserCircleIcon strokeWidth={2} width={20} color={'#3502b0'} />;
    case 'traduction':
      return <LanguageIcon strokeWidth={2} width={20} color={'#3502b0'} />;
    case 'text':
      return <DocumentTextIcon strokeWidth={2} width={20} color={'#3502b0'} />;
    default:
      return <QuestionMarkCircleIcon strokeWidth={2} width={20} color={'#3502b0'} />;
  }
};

export default function AIPromptTextAreaComponent(props: AIPromptModalComponentProps) {
  const ref = React.useRef();
  const textAreaRef = React.useRef();

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [isTextareaFocused, setIsTextareaFocused] = React.useState<boolean>(true);
  const [categories, setCategories] = React.useState<MBCoreAIPromptCategory[]>([]);

  const [selectedCategory, setSelectedCategory] = React.useState<MBCoreAIPromptCategory | null>(null);
  const [selectedPrompt, setSelectedPrompt] = React.useState<MBCoreAIPrompt | null>(null);

  const [isChooseMenuOpen, setIsChooseMenuOpen] = React.useState<boolean>(false);
  const [chooseMenuType, setChooseMenuType] = React.useState<number>(1);

  const [streamAPI, setStreamAPI] = React.useState<string>('');
  const [html, setHtml] = React.useState<string>('');

  const disclosure = useDisclosure();

  const { currentSelection, isTextSelected, range, setCurrentSelection } = useSelection(textAreaRef);

  useOutsideClick({
    // @ts-ignore
    ref: ref,
    handler: () => (!isLoading ? disclosure.onClose : null),
  });

  const _fetchAPI = async () => {
    MBCoreAIPromptsAPI.list()
      .then((r) => {
        setCategories(r);
      })
      .catch((e) => console.error(e));
  };

  const _generateText = async (prompt: MBCoreAIPrompt, variables: object) => {
    setIsLoading(true);

    let currentResponse = '';
    MBCoreAIPromptsAPI.executeStream(
      prompt.id,
      {
        ...variables,
        text: isTextSelected ? currentSelection : props.values[props.name],
      },
      (chunk: string) => {
        currentResponse += chunk.replace(/<br\s*\/?>/gi, '');
        setStreamAPI(currentResponse);
      },
      (endTime) => {
        ToastUtils.showSuccess("L'IA a terminé de générer le texte");
        setIsLoading(false);
        setSelectedCategory(null);
        setSelectedPrompt(null);
        setIsChooseMenuOpen(false);
        disclosure.onClose();
      }
    );
  };

  const _updateFromStream = (chunk: string) => {
    setStreamAPI(streamAPI + chunk);
  };

  useEffect(() => {
    _fetchAPI();
  }, []);

  useEffect(() => {
    console.log('Update value');
    setHtml(_highlightHtml());
    if (isTextSelected) {
      disclosure.onOpen();
    }
  }, [props.values[props.name], currentSelection, isTextSelected]);

  useEffect(() => {
    const value = props.values[props.name];
    if (value == '/') {
      disclosure.onOpen();
      props.handleChange({
        // @ts-ignore
        target: {
          name: props.name,
          value: '',
        },
      });
    }
  }, [props.values[props.name]]);

  useEffect(() => {
    props.handleChange({
      // @ts-ignore
      target: {
        name: props.name,
        value: streamAPI,
      },
    });
  }, [streamAPI]);

  const _highlightHtml = () => {
    if (!currentSelection) return props.values[props.name];

    const text = props.values[props.name];

    if (text.includes(currentSelection) == false) return text;

    const start = text.indexOf(currentSelection);
    const end = start + currentSelection.length;
    const before = text.slice(0, start);
    const after = text.slice(end);
    const highlighted = text.slice(start, end);

    return `${before}<span style="background-color: #d1c5ff; color: #272727; padding: 1px 2px">${highlighted}</span>${after}`;
  };

  const isEmpty = props.values[props.name] == null || props.values[props.name] == '';

  return (
    <Box
      // @ts-ignore
      ref={ref}
      position={'relative'}
    >
      <Popover
        autoFocus={false}
        strategy={'absolute'}
        placement={'bottom-start'}
        returnFocusOnClose={true}
        isOpen={disclosure.isOpen}
        onClose={disclosure.onClose}
        isLazy={true}
      >
        <PopoverTrigger>
          <Box>
            <Box display={isTextareaFocused ? 'none' : 'block'}>
              {props.label && (
                <Text fontSize={12} fontWeight={500} mb={1}>
                  {props.label}
                </Text>
              )}

              <Box
                as={AIPromptContentEditableInputComponent}
                html={html}
                contentEditable={false}
                onFocus={() => setIsTextareaFocused(true)}
                style={{
                  whiteSpace: 'pre-wrap',
                  position: 'relative',
                  backgroundColor: useColorModeValue('white', 'gray.800'),
                  borderWidth: 1,
                  borderColor: useColorModeValue('gray.100', 'gray.800'),
                  boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.1)',
                  borderRadius: '8px',
                  padding: '7px 14px',
                  fontSize: '14px',
                  minHeight: 80,
                  lineHeight: '1.375',
                  letterSpacing: '-0.01071em',
                  ...(props.style || {}),
                }}
              />
              {props.helperText && (
                <Text fontSize={12} fontWeight={400} color={'muted'}>
                  {props.helperText}
                </Text>
              )}
            </Box>
            <Box display={isTextareaFocused ? 'block' : 'none'}>
              <TextAreaInput {...props} ref={textAreaRef} />
            </Box>
            {isEmpty && !disclosure.isOpen ? (
              <Card position={'absolute'} right={3} top={1} w={'230px'} p={2} zIndex={30}>
                <HStack>
                  <SparklesIcon strokeWidth={2} width={17} color={'#844eff'} />
                  <Text fontSize={'xs'} color={'muted'} fontWeight={500}>
                    Taper / (slash) pour utiliser l'IA
                  </Text>
                </HStack>
              </Card>
            ) : (
              <></>
            )}
          </Box>
        </PopoverTrigger>
        <PopoverContent>
          <PopoverBody p={0} onClick={() => setIsTextareaFocused(false)}>
            <AIPromptHeaderComponent />
            <Divider />
            {isLoading && <AIPromptLoadingComponent />}
            {!isLoading && (
              <Box position={'relative'} minH={'200px'} maxH={'200px'} overflowY={'scroll'} overflowX={'hidden'}>
                <Slide
                  direction="left"
                  in={selectedCategory == null && !isChooseMenuOpen}
                  style={{ position: 'absolute' }}
                >
                  {categories.map((category) => {
                    if (!isEmpty && category.is_available_select == 0) return null;
                    if (isEmpty && category.is_available_select == 1) return null;
                    if (!category.prompts) return null;
                    if (category.prompts.length == 0) return null;
                    return (
                      <AIPromptCategoryComponent
                        key={'ai-category-' + category.id}
                        category={category}
                        onClick={() => setSelectedCategory(category)}
                      />
                    );
                  })}
                </Slide>
                <Slide
                  direction={isChooseMenuOpen ? 'left' : 'right'}
                  in={selectedCategory != null && !isChooseMenuOpen}
                  style={{ position: 'absolute' }}
                >
                  <BackPromptComponent onClick={() => setSelectedCategory(null)} />
                  {selectedCategory != null ? (
                    <>
                      {selectedCategory.prompts?.map((prompt) => (
                        <AIPromptComponent
                          key={'ai-prompt-' + prompt.id}
                          prompt={prompt}
                          onClick={() => {
                            if (
                              selectedCategory?.is_available_select == 1 ||
                              selectedCategory?.is_available_select == 2
                            ) {
                              _generateText(prompt, {});
                              return;
                            }
                            setSelectedPrompt(prompt);
                            setIsChooseMenuOpen(true);
                            setChooseMenuType(prompt.category_id);
                          }}
                        />
                      ))}
                    </>
                  ) : null}
                </Slide>
                <Slide direction="right" in={isChooseMenuOpen} style={{ position: 'absolute' }}>
                  <BackPromptComponent onClick={() => setIsChooseMenuOpen(false)} />
                  {chooseMenuType === 4 && (
                    <AIPromptPropertyChooseMenuComponent
                      onClick={(property) => {
                        if (!selectedPrompt) return;
                        _generateText(selectedPrompt, {
                          id: property.id,
                        });
                      }}
                    />
                  )}
                </Slide>
              </Box>
            )}
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Box>
  );
}

function AIPromptCategoryComponent({ category, onClick }: { category: MBCoreAIPromptCategory; onClick: () => void }) {
  return (
    <APIPromptRowComponent
      title={category.title}
      description={category.description}
      leftSide={
        <Box
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
          borderRadius={'lg'}
          style={{
            width: '38px',
            height: '38px',
            minWidth: '38px',
            minHeight: '38px',
          }}
          bg={'purple.100'}
        >
          {_categoryToIcon(category)}
        </Box>
      }
      rightSide={<ChevronRightIcon strokeWidth={2} width={20} color={'#788187'} />}
      onClick={onClick}
    />
  );
}

function BackPromptComponent({ onClick }: { onClick: () => void }) {
  return (
    <APIPromptRowComponent
      title={'Retour en arrière'}
      description={'Retournez à la liste précédente'}
      onClick={onClick}
      leftSide={
        <Box
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
          borderRadius={'lg'}
          w={'25px'}
          h={'25px'}
          bg={'gray.100'}
        >
          <ArrowUturnLeftIcon strokeWidth={2} width={15} color={'#8d8d8d'} />
        </Box>
      }
    />
  );
}

function AIPromptComponent({ prompt, onClick }: { prompt: MBCoreAIPrompt; onClick: () => void }) {
  return (
    <APIPromptRowComponent
      title={prompt.title}
      description={prompt.description}
      rightSide={<ChevronRightIcon strokeWidth={2} width={20} color={'#788187'} />}
      onClick={onClick}
    />
  );
}
