import { StyleFunctionProps } from '@chakra-ui/theme-tools';

export default {
  defaultProps: {
    variant: 'soft-rounded',
  },
  variants: {
    'soft-rounded': (props: StyleFunctionProps) => ({
      tab: {
        borderRadius: '8px',
        fontWeight: 'semibold',
        whiteSpace: 'nowrap',
        color: '#aaadbc',
        px: '0.6em',
        _selected: {
          color: props.colorMode === 'light' ? '#000' : '#fff',
          bg: props.colorMode === 'light' ? '#fff' : '#171717',
          boxShadow: '0 3px 8px 0 rgba(0,0,0,0.08), 0 3px 1px 0 rgba(0,0,0,0.04)',
        },
        _active: {
          color: props.colorMode === 'light' ? '#000' : '#fff',
          bg: props.colorMode === 'light' ? '#fff' : '#171717',
          boxShadow: '0 3px 8px 0 rgba(0,0,0,0.08), 0 3px 1px 0 rgba(0,0,0,0.04)',
        },
        _hover: {
          color: props.colorMode === 'light' ? '#000' : '#fff',
          bg: props.colorMode === 'light' ? '#fff' : '#171717',
          boxShadow: '0 3px 8px 0 rgba(0,0,0,0.08), 0 3px 1px 0 rgba(0,0,0,0.04)',
        },
        _focus: {
          color: props.colorMode === 'light' ? '#000' : '#fff',
          bg: props.colorMode === 'light' ? '#fff' : '#171717',
          boxShadow: '0 3px 8px 0 rgba(0,0,0,0.08), 0 3px 1px 0 rgba(0,0,0,0.04)',
        },
      },
      tablist: {
        bg: props.colorMode === 'light' ? '#f8f8fb' : '#262626',
        borderRadius: '10px',
        px: '3px',
        py: '3px',
        marginTop: 0,
      },
      tabpanel: {
        px: 0,
      },
      tabs: {
        scrollbarWidth: 'none',
        '::-webkit-scrollbar': { display: 'none' },
        '-webkit-overflow-scrolling': 'touch',
        boxShadow: 'inset 0 -2px 0 rgba(0, 0, 0, 0.1)',
        border: '0 none',
        width: '100%',
      },
    }),
    header: (props: StyleFunctionProps) => ({
      tab: {
        fontWeight: 'semibold',
        whiteSpace: 'nowrap',
        color: '#aaadbc',
        px: '1em',
        borderBottomColor: props.colorMode === 'light' ? 'gray.50' : 'gray.600',
        borderBottomWidth: '2px',
        transition: 'all 0.2s ease-in-out',
        _selected: {
          color: props.colorMode === 'light' ? 'gray.800' : 'gray.100',
          borderBottomColor: props.colorMode === 'light' ? 'gray.800' : 'gray.100',
        },
        _active: {
          color: props.colorMode === 'light' ? 'gray.800' : 'gray.100',
          borderBottomColor: props.colorMode === 'light' ? 'gray.800' : 'gray.100',
        },
        _hover: {
          color: props.colorMode === 'light' ? 'gray.600' : 'gray.300',
          borderBottomColor: props.colorMode === 'light' ? 'gray.600' : 'gray.300',
        },
        _focus: {
          color: props.colorMode === 'light' ? 'gray.800' : 'gray.100',
          borderBottomColor: props.colorMode === 'light' ? 'gray.800' : 'gray.100',
        },
      },
      tablist: {
        marginTop: 0,
        borderWidth: 0,
        boxShadow: 'none',
      },
      tabpanel: {
        px: 0,
        borderWidth: 0,
        boxShadow: 'none',
      },
      tabs: {
        scrollbarWidth: 'none',
        '::-webkit-scrollbar': { display: 'none' },
        '-webkit-overflow-scrolling': 'touch',
        borderWidth: 0,
        boxShadow: 'none',
      },
    }),
    outline: (props: StyleFunctionProps) => ({
      tab: {
        color: props.colorMode === 'light' ? 'gray.800' : '#787878',
        fontWeight: 600,
        px: 3,
        py: '5px',
        whiteSpace: 'nowrap',
        fontSize: '13px',
        letterSpacing: '-0.02em',
        borderColor: props.colorMode === 'light' ? '#d0d5dd' : '#2e2e2e',
        boxShadow: 'none',
        _selected: {
          color: props.colorMode === 'light' ? '#000' : '#fff',
          bg: props.colorMode === 'light' ? '#fff' : '#171717',
          fontWeight: 600,
        },
        _active: {
          color: props.colorMode === 'light' ? '#000' : '#fff',
          bg: props.colorMode === 'light' ? '#fff' : '#171717',
        },
        _focus: {
          color: props.colorMode === 'light' ? '#000' : '#fff',
          bg: props.colorMode === 'light' ? '#fff' : '#171717',
        },
        _notLast: {
          borderRight: '1px solid #D0D5DD',
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
          borderColor: props.colorMode === 'light' ? '#d0d5dd' : '#2e2e2e',
        },
        _first: {
          borderTopLeftRadius: 8,
          borderBottomLeftRadius: 8,
          borderColor: props.colorMode === 'light' ? '#d0d5dd' : '#2e2e2e',
        },
        _last: {
          borderTopRightRadius: 8,
          borderBottomRightRadius: 8,
          borderColor: props.colorMode === 'light' ? '#d0d5dd' : '#2e2e2e',
        },
      },
      tablist: {
        borderRadius: 8,
        marginTop: 0,
        shadow: 'none',
        border: '1px solid ' + (props.colorMode === 'light' ? '#d0d5dd' : '#454545'),
      },
      tabpanel: {
        px: 0,
      },
      tabs: {
        scrollbarWidth: 'none',
        '::-webkit-scrollbar': { display: 'none' },
        '-webkit-overflow-scrolling': 'touch',
      },
    }),
  },
  sizes: {
    sm: {
      tab: {
        fontSize: '12px',
      },
      tablist: {
        px: '3px',
        py: '3px',
      },
    },
  },
};
