import { AuthConstant } from '@meilleursbiens/constants';
import { FormbrickUtils } from '@meilleursbiens/utils';

import * as Sentry from '@sentry/react';

export default class LocalStorageUtils {
  static clear() {
    localStorage.removeItem(AuthConstant.JWT_TOKEN_KEY);
    localStorage.removeItem(AuthConstant.JWT_REFRESH_TOKEN_KEY);
    localStorage.removeItem(AuthConstant.MB_ID_KEY);
    localStorage.removeItem(AuthConstant.CRISP_SIGNATURE);
    localStorage.removeItem(AuthConstant.IMPERSONATE_USER_SAVE);
    localStorage.removeItem('meilleursbiens-permissions');
    sessionStorage.clear();

    FormbrickUtils.logoutUser();
    Sentry.setUser(null);
  }
}
