import { createContext } from 'react';

export interface UserPayload {
  id: string;
  permissions?: string[];
}

export interface PermissionAuthContext {
  setUser: (user: UserPayload) => void;
  isAuthorized: (permissionsNames?: string[]) => Promise<boolean>;
  isAuthorizedSync: (permissionsNames?: string[]) => boolean;
  isLoading: boolean;
}

const noUser = (): never => {
  throw new Error('Vous devez définir un utilisateur !');
};

const PermissionContext = createContext<PermissionAuthContext>({
  setUser: noUser,
  isAuthorized: noUser,
  isAuthorizedSync: noUser,
  isLoading: false,
});

export default PermissionContext;
