import {
  AUTH_CLEAR_REGISTER_USER,
  AUTH_LOGIN_USER,
  AUTH_LOGOUT_USER,
  AUTH_REGISTER_USER,
  AUTH_SECRET_DATA,
} from '../ActionTypes';

export const loginUser = (data) => ({ type: AUTH_LOGIN_USER, payload: data });
export const logoutUser = (data) => ({ type: AUTH_LOGOUT_USER, payload: data });
export const registerUser = (data) => ({
  type: AUTH_REGISTER_USER,
  payload: data,
});
export const clearRegisterData = (data) => ({
  type: AUTH_CLEAR_REGISTER_USER,
  payload: data,
});
export const secretData = (data) => ({ type: AUTH_SECRET_DATA, payload: data });
