import { MBCorePermission, MBCorePermissionsRole } from '@meilleursbiens/types';

import { MBApi } from '../../../../index';

export default class MBCorePermissionsTeamAPI {
  static current() {
    return MBApi.url('/v1/core/permissions/team')
      .needAuth()
      .needCache(5, 'MBCorePermissionsAPI.list')
      .get<MBCorePermission[]>();
  }

  static assignTeam(team_id: number, role: string) {
    return MBApi.url('/v1/core/permissions/team/' + team_id)
      .parameters({ role })
      .needAuth()
      .patch<[]>();
  }

  static teamRoles(team_id: number) {
    return MBApi.url('/v1/core/permissions/team/roles/' + team_id)
      .needAuth()
      .get<MBCorePermissionsRole[]>();
  }
}
