import React from 'react';
import { DevUtils } from '@meilleursbiens/utils';

export interface FeatureDateProps {
  day: number;
  month: number;
  year: number;
  hour?: number;
  minute?: number;
  isBefore?: boolean;
  children: React.ReactNode;
}

const FeatureDate = (props: FeatureDateProps) => {
  const isBefore = props.isBefore ?? false;

  const currentDate = new Date();
  const featureDate = new Date(props.year, props.month - 1, props.day, props.hour ?? 0, props.minute ?? 0);

  const isDev = DevUtils.isDev();

  if(isDev) {
    return <>{props.children}</>;
  }

  if (!isBefore && featureDate <= currentDate) {
    return <>{props.children}</>;
  }

  if (isBefore && featureDate > currentDate) {
    return <>{props.children}</>;
  }
}

export default FeatureDate;
