import React from 'react';

import {
  Box,
  BoxProps,
  Collapse,
  Flex,
  HStack,
  Heading,
  Skeleton,
  SkeletonProps,
  Spacer,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';

import Badge, { BadgeProps } from '../Badge/Badge';
import { IconButton } from '../index';

export interface SectionProps extends BoxProps {
  title?: string;
  description?: string;
  actions?: React.ReactNode;
  isLoading?: boolean;
  size?: 'xs' | 'sm' | 'md' | 'lg';
  children?: React.ReactNode;
  noOfLines?: number;
  icon?: React.ForwardRefExoticComponent<
    React.PropsWithoutRef<React.SVGProps<SVGSVGElement>> & {
      title?: string;
      titleId?: string;
    } & React.RefAttributes<SVGSVGElement>
  >;
  iconColor?: string;
  badge?: string;
  badgeProps?: BadgeProps;
  skeletonProps?: SkeletonProps;
  isCollapsible?: boolean;
  headingProps?: BoxProps;
  descriptionProps?: BoxProps;
  defaultIsOpen?: boolean;
}

const Section = (props: SectionProps) => {
  const disclosure = useDisclosure({
    defaultIsOpen: props.defaultIsOpen || true,
  });

  const sizeToPx = () => {
    if (!props.size) return 22;
    switch (props.size) {
      case 'xs':
        return 17;
      case 'sm':
        return 19;
      case 'md':
        return 22;
      case 'lg':
        return 28;
    }
  };

  const sizeToPadding = () => {
    if (!props.size) return 22;
    switch (props.size) {
      case 'xs':
        return 2;
      case 'sm':
        return 3;
      case 'md':
        return 3;
      case 'lg':
        return 3;
    }
  };

  return (
    <Box w={'100%'} {...props}>
      <Flex
        w={'100%'}
        direction={['column', 'row']}
        justify={['flex-start', 'space-between']}
        align={['flex-start', 'center']}
        gap={[3, 1]}
      >
        <Box maxW={props.actions ? '60%' : '100%'}>
          <HStack>
            {props.icon && (
              <Flex
                align={'center'}
                justify={'center'}
                p={sizeToPadding()}
                borderRadius={'100%'}
                bg={props.iconColor || 'primary.500'}
              >
                <props.icon color={'white'} width={sizeToPx() + 'px'} />
              </Flex>
            )}

            <Box>
              {props.title && (
                <HStack>
                  <Heading as={'h2'} fontSize={sizeToPx()} noOfLines={1} fontWeight={600} {...props.headingProps}>
                    {props.title || 'Something went wrong'}
                  </Heading>
                  {props.badge && (
                    <Badge fontSize={sizeToPx() * 0.65} {...props.badgeProps}>
                      {props.badge}
                    </Badge>
                  )}
                </HStack>
              )}
              {props.description && (
                <Text
                  fontSize={sizeToPx() * 0.65}
                  mt={'2px'}
                  color={'muted'}
                  noOfLines={props.noOfLines || 2}
                  {...props.descriptionProps}
                >
                  {props.description || 'We have been notified about the problem and will fix it as soon as possible.'}
                </Text>
              )}
            </Box>
          </HStack>
        </Box>

        <Box>
          {props.actions && props.actions}
          {props.isCollapsible && (
            <IconButton
              icon={disclosure.isOpen ? ChevronDownIcon : ChevronUpIcon}
              variant={'ghost'}
              aria-label={'Ouvrir la section'}
              onClick={disclosure.onToggle}
            />
          )}
        </Box>
      </Flex>
      <Spacer mt={2} mb={2} />
      {props.isLoading && <Skeleton {...props.skeletonProps} />}
      {props.isCollapsible ? (
        <Collapse in={disclosure.isOpen}>{!props.isLoading && props.children && props.children}</Collapse>
      ) : (
        <>{!props.isLoading && props.children && props.children}</>
      )}
    </Box>
  );
};

export default Section;
