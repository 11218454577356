import { MBApi } from '../../../index';

export default class MBCoreProAbonoPermissionAPI {
  static quota() {
    return MBApi.url('/v1/proabono/permissions/mbpro/teams/quota').needAuth().get<{
      permission: boolean;
      quota: number;
    }>();
  }
}
