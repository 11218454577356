import React from 'react';
import { Link } from 'react-router-dom';

import { ErrorPage } from '@meilleursbiens/ui';

import { Button, ButtonGroup } from '@chakra-ui/react';

export default function NotFound() {
  return (
    <ErrorPage
      title={'La page est introuvable'}
      description={"Cette page n'existe pas ou a été déplacée"}
      actions={
        <ButtonGroup mt={6}>
          <Button variant={'tertiary'} as={Link} to={'/'}>
            Retour
          </Button>
          <Button variant={'ghost'} onClick={() => window.location.reload()}>
            Rafraichir la page
          </Button>
        </ButtonGroup>
      }
    />
  );
}
