class PriceUtils {
  static formatPrice(price: number) {
    try {
      const formatter = new Intl.NumberFormat('fr-FR', {
        style: 'currency',
        currency: 'EUR',
        maximumFractionDigits: 0,
      });

      return formatter.format(price);
    } catch (e) {
      return price;
    }
  }

  static formatPriceCompact(price: number) {
    try {
      const formatter = new Intl.NumberFormat('fr-FR', {
        style: 'currency',
        currency: 'EUR',
        maximumFractionDigits: 1,
        notation: 'compact',
      });

      return formatter.format(price);
    } catch (e) {
      return price;
    }
  }

  static formatPriceToThousand(price: number) {
    try {
      const priceRounded = Math.round(price / 1000) * 1000;

      const formatter = new Intl.NumberFormat('fr-FR', {
        style: 'currency',
        currency: 'EUR',
        maximumFractionDigits: 0,
      });

      return formatter.format(priceRounded);
    } catch (e) {
      return price;
    }
  }

  static formatHighNumber(n: number) {
    if (n < 1e3) return this.fixedIfNotZero(n, 2);
    if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + 'K';
    if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + 'M';
    if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + 'B';
    if (n >= 1e12) return +(n / 1e12).toFixed(1) + 'T';

    return this.fixedIfNotZero(n, 2);
  }

  static fixedIfNotZero(x: number, d: number) {
    if (!d) return x.toFixed(d); // don't go wrong if no decimal
    return x.toFixed(d).replace(/\.?0+$/, '');
  }

  static formatPriceWithDecimals(price: number) {
    try {
      const formatter = new Intl.NumberFormat('fr-FR', {
        style: 'currency',
        currency: 'EUR',
        maximumFractionDigits: 2,
      });

      return formatter.format(price);
    } catch (e) {
      return price;
    }
  }
}

export default PriceUtils;
