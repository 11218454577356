import React from 'react';
import { Navigate } from 'react-router';

import { AuthConstant } from '@meilleursbiens/constants';

import Store from '../Store';
import { Routes } from '../routes/routes';

export default ({ children }) => {
  let api_token = localStorage.getItem(AuthConstant.JWT_TOKEN_KEY);
  let isAuthenticated = Store.getState().auth.isAuthenticated;
  let user = Store.getState().auth.user;

  const isAuthenticatedAndAPITokenIsAvailable = api_token && isAuthenticated;
  const isAdmin = user && user.admin === 1;

  if (isAuthenticatedAndAPITokenIsAvailable) {
    if (isAdmin) {
      return children;
    } else {
      return (
        <Navigate
          to={{
            pathname: Routes.PRO_DASHBOARD.url,
          }}
          state={{ from: window.location.pathname }}
          replace={true}
        />
      );
    }
  } else {
    if (api_token) {
      return (
        <Navigate
          to={{
            pathname: Routes.AUTH_REFRESH.url,
          }}
          state={{
            from: window.location.href.replace(window.location.origin, ''),
          }}
          replace={true}
        />
      );
    } else {
      return (
        <Navigate
          to={{
            pathname: Routes.AUTH_LOGIN.url,
          }}
        />
      );
    }
  }
};
