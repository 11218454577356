import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

import { navGroupAnatomy } from '../../anatomy';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(navGroupAnatomy.keys);

// @ts-ignore
const baseStyle = definePartsStyle((props) => {
  return {
    mx: 1,
  };
});

export const toolbarGroupTheme = defineMultiStyleConfig({
  baseStyle,
});
