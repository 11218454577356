import React, { Fragment, useEffect } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';

import { PermissionProvider } from '@meilleursbiens/permissions';
import { theme } from '@meilleursbiens/theme';
import { NetworkStatusIndicator } from '@meilleursbiens/ui';
import { DevUtils } from '@meilleursbiens/utils';

import { ChakraProvider, ColorModeScript } from '@chakra-ui/react';

import * as Sentry from '@sentry/react';

import packageJson from '../../../package.json';
import FormbrickRouteEvent from './components/FormbrickRouteEvent';
import ScrollToTop from './components/ScrollToTop';
import DemoButtonComponent from './components/UI/DemoButton/DemoButtonComponent';
import GoogleAnalyticsRouter from './components/Utils/GoogleAnalyticsRouter';
import { getRoutesForRouter } from './routes/routes';
import ErrorScreen from './screens/ErrorScreen';
import './styles/app.scss';
import GAUtils from './utils/GAUtils';
import StringUtils from './utils/StringUtils';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";

if (!DevUtils.isDev()) {
  Sentry.init({
    dsn: 'https://55cd1ca12c9a4e4ea40d88d2ca1ef9e6@o1284598.ingest.sentry.io/6495465',
    environment: DevUtils.isDev() ? 'development' : 'production',
    release: 'my-mb@' + packageJson.version,
    tracesSampleRate: 0.5,
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1,
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration({
        maskAllText: false,
        maskAllInputs: false,
        blockAllMedia: false,
        networkDetailAllowUrls: ['api.meilleursbiens.com', /^https:\/\/api\.meilleursbiens\.com/],
        networkRequestHeaders: ['Meilleursbiens-User-Id', 'Authorization'],
        networkResponseHeaders: ['Referrer-Policy'],
      }),
    ],
  });
}

GAUtils.init();

export default function App({ store }) {
  useEffect(() => {
    StringUtils.asciiConsole();
    StringUtils.initCrisp();

    //FormbrickUtils.getClient();
  }, []);

  return (
    <ChakraProvider theme={theme}>
      <Sentry.ErrorBoundary
        fallback={({ error, componentStack, resetError }) => (
          <ErrorScreen error={error} version={'v' + packageJson.version} />
        )}
      >
        <Provider store={store}>
          <PermissionProvider>
            <Router>
              <Fragment>
                <FormbrickRouteEvent />
                <ScrollToTop />

                {getRoutesForRouter()}

                <GoogleAnalyticsRouter />
                <DemoButtonComponent />
                <NetworkStatusIndicator />

                <ColorModeScript initialColorMode={'system'} />
              </Fragment>
            </Router>
          </PermissionProvider>
        </Provider>
      </Sentry.ErrorBoundary>
    </ChakraProvider>
  );
}
