import { useEffect } from 'react';
import { useLocation } from 'react-router';

import { FormbrickUtils } from '@meilleursbiens/utils';

function FormbrickRouteEvent() {
  const location = useLocation();

  useEffect(() => {
    FormbrickUtils.routeChange();
  }, [location]);

  return null;
}

export default FormbrickRouteEvent;
