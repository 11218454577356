import { useEffect, useState } from 'react';

import { Image, Skeleton, Stack, VStack } from '@chakra-ui/react';
import { MBNeoPropertyAPI } from '@meilleursbiens/api';
import { NeoPropertyPicturesUtils } from '@meilleursbiens/neo';
import { MBNeoPropertiesType } from '@meilleursbiens/types';
import { ToastUtils } from '@meilleursbiens/utils';

import AIPromptRowComponent from '../APIPromptRowComponent';

interface AIPromptPropertyChooseMenuComponentProps {
  onClick: (property: MBNeoPropertiesType) => void;
}

export default function AIPromptPropertyChooseMenuComponent(props: AIPromptPropertyChooseMenuComponentProps) {
  const [isLoading, setIsLoading] = useState(true);
  const [properties, setProperties] = useState<MBNeoPropertiesType[]>([]);

  const _fetchProperties = async () => {
    setIsLoading(true);
    MBNeoPropertyAPI.list('id', 'all', 'DESC')
      .then((res) => {
        setProperties(res.properties);
      })
      .catch((e) => ToastUtils.showError(e))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    _fetchProperties();
  }, []);

  return (
    <Stack>
      {isLoading && (
        <VStack gap={1}>
          <Skeleton height="55px" w={'100%'} />
          <Skeleton height="55px" w={'100%'} />
          <Skeleton height="55px" w={'100%'} />
          <Skeleton height="55px" w={'100%'} />
        </VStack>
      )}
      {properties.map((property) => {
        const mainPicture = NeoPropertyPicturesUtils.getMainPicture(property, 'compressed');
        return (
          <AIPromptRowComponent
            leftSide={<Image w={'70px'} src={mainPicture} borderRadius={'md'} />}
            title={property.title}
            description={property.description}
            onClick={() => {
              props.onClick(property);
            }}
          />
        );
      })}
    </Stack>
  );
}
