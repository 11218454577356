import React from 'react';

import { Box, Flex, HStack, Heading, Text, useColorModeValue, useDisclosure } from '@chakra-ui/react';
import { ArrowUpOnSquareStackIcon, ChevronRightIcon, DocumentArrowUpIcon, PencilIcon } from '@heroicons/react/24/solid';
import { MBProUserSignature } from '@meilleursbiens/types';
import { Card } from '@meilleursbiens/ui';

import MBProUsersSignatureUIModal from './MBProUsersSignatureSignUIModal';
import { MBProUsersSignatureSignUIModal, MBProUsersSignatureUploadUIModal } from './index';

interface MBProUsersSignatureCardComponentProps {
  signature: MBProUserSignature;
  onComplete: (signature: MBProUserSignature) => void;
  onCancel: () => void;
}

export default function MBProUsersSignatureCardComponent(props: MBProUsersSignatureCardComponentProps) {
  const hoverColor = useColorModeValue('gray.50', 'gray.800');

  const disclosureSignature = useDisclosure();
  const disclosureUpload = useDisclosure();

  const _openModal = () => {
    if (props.signature.is_signature_required) {
      disclosureSignature.onOpen();
    } else if (props.signature.is_document_upload) {
      disclosureUpload.onOpen();
    }
  };

  return (
    <>
      <Card mb={2} p={4} _hover={{ bg: hoverColor, cursor: 'pointer' }} onClick={_openModal}>
        <Flex w={'full'} direction={'row'} align={'center'} justify={'space-between'} gap={4}>
          <HStack spacing={4}>
            {props.signature.is_signature_required && (
              <Box bg={'orange.100'} p={3} borderRadius={'md'}>
                <PencilIcon width={22} color={'#ff9100'} />
              </Box>
            )}
            {props.signature.is_document_upload && (
              <Box bg={'blue.100'} p={3} borderRadius={'md'}>
                <DocumentArrowUpIcon width={22} color={'#0079ff'} />
              </Box>
            )}
            <Box>
              <Heading fontWeight="600" size="sm">
                {props.signature.title}
              </Heading>
              <Text color={'muted'} fontSize={'xs'} noOfLines={2}>
                {props.signature.message}
              </Text>
            </Box>
          </HStack>
          <HStack>
            <ChevronRightIcon width={24} color={'gray'} />
          </HStack>
        </Flex>
      </Card>

      <MBProUsersSignatureSignUIModal
        signature={props.signature}
        onComplete={props.onComplete}
        disclosure={disclosureSignature}
      />
      <MBProUsersSignatureUploadUIModal
        signature={props.signature}
        onComplete={props.onComplete}
        disclosure={disclosureUpload}
      />
    </>
  );
}
