import React, { useEffect } from 'react';

import { AIPromptTextAreaComponent } from '@meilleursbiens/ai';

import { useDisclosure } from '@chakra-ui/react';

import { useFormik } from 'formik';

import AdminLayoutComponent from '../components/Layout/Admin/AdminLayoutComponent';

export default function TestScreen() {
  const disclosure = useDisclosure();

  useEffect(() => {
    disclosure.onOpen();
  }, []);

  const formik = useFormik({
    initialValues: {
      description: '',
    },
    onSubmit: (values) => {
      console.log(values);
    },
  });

  return (
    <AdminLayoutComponent title={'Tests'} description={"Gérez les tests au sein d'une même interface"}>
      <AIPromptTextAreaComponent
        handleChange={formik.handleChange}
        handleBlur={formik.handleBlur}
        name={'description'}
        values={formik.values}
        errors={formik.errors}
        touched={formik.touched}
      />
    </AdminLayoutComponent>
  );
}
