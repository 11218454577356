import React from 'react';

import { Box, Flex, HStack, Text, useColorModeValue } from '@chakra-ui/react';
import { ChevronRightIcon } from '@heroicons/react/24/outline';

interface APIPromptRowComponentProps {
  leftSide?: React.ReactNode;
  title: string;
  description: string;
  rightSide?: React.ReactNode;
  onClick: () => void;
}

export default function AIPromptRowComponent(props: APIPromptRowComponentProps) {
  return (
    <Flex
      as={'a'}
      align={'center'}
      justify={'space-between'}
      w={'100%'}
      cursor={'pointer'}
      borderBottomWidth={1}
      borderColor={useColorModeValue('gray.100', 'gray.800')}
      _hover={{
        bg: useColorModeValue('gray.50', 'gray.800'),
      }}
      px={2}
      py={2}
      gap={3}
      onClick={props.onClick}
    >
      <HStack gap={3}>
        {props.leftSide && props.leftSide}
        <Box>
          <Text fontSize={'sm'} fontWeight={'bold'}>
            {props.title}
          </Text>
          <Text fontSize={'xs'} color={'muted'} noOfLines={2}>
            {props.description}
          </Text>
        </Box>
      </HStack>
      <Box>{props.rightSide && props.rightSide}</Box>
    </Flex>
  );
}
