import React from 'react';

import { Skeleton, Stat, StatHelpText, StatLabel, StatLabelProps, StatNumber, StatNumberProps } from '@chakra-ui/react';

import Card, { CardProps } from '../Card/Card';

export interface StatBoxProps extends Omit<CardProps, 'children'> {
  value?: string | number;
  label?: string;
  helperText?: any;
  isLoading?: boolean;
  mode?: 'label-first' | 'value-first';
  labelProps?: StatLabelProps;
  valueProps?: StatNumberProps;
}

const StatBox = (props: StatBoxProps) => {
  const { mode = 'value-first' } = props;

  if (props.isLoading) {
    return (
      <Card p={3} boxShadow={'none'} {...props}>
        <Stat>
          {mode === 'value-first' && (
            <>
              <Skeleton height={'20px'} width={'50px'} />
              <Skeleton height={'17px'} width={'110px'} mt={2} />
            </>
          )}
          {mode === 'label-first' && (
            <>
              <Skeleton height={'17px'} width={'110px'} mt={2} />
              <Skeleton height={'20px'} width={'50px'} />
            </>
          )}
        </Stat>
      </Card>
    );
  }

  return (
    <Card p={3} boxShadow={'none'} {...props}>
      <Stat>
        {mode === 'value-first' && (
          <>
            <StatNumber fontSize={'17px'} {...props.valueProps}>
              {props.value || 0}
            </StatNumber>
            <StatLabel color={'muted'} fontSize={'xs'} noOfLines={1} {...props.labelProps}>
              {props.label || 'Stats'}
            </StatLabel>
          </>
        )}
        {mode === 'label-first' && (
          <>
            <StatLabel color={'muted'} fontSize={'xs'} noOfLines={1} {...props.labelProps}>
              {props.label || 'Stats'}
            </StatLabel>
            <StatNumber fontSize={'17px'} {...props.valueProps}>
              {props.value || 0}
            </StatNumber>
          </>
        )}

        {props.helperText && <StatHelpText>{props.helperText}</StatHelpText>}
      </Stat>
    </Card>
  );
};

export default StatBox;
