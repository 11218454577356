import { MBProUserSignature } from '@meilleursbiens/types';

import { MBApi } from '../../../../index';

export default class MBProDocumentSignatureAPI {
  static listAvailable() {
    return MBApi.url('/v1/mbpro/signatures').needAuth().get<{
      signatures: MBProUserSignature[];
    }>();
  }

  static uploadDocument(id: number, file: File) {
    let formData = new FormData();
    formData.append('file', file);

    return MBApi.url('/v1/mbpro/signatures/' + id + '/upload')
      .needAuth()
      .formData(formData)
      .contentFormData()
      .postUpload();
  }

  static signDocument(id: number, signature: File) {
    let formData = new FormData();
    formData.append('signature', signature);

    return MBApi.url('/v1/mbpro/signatures/' + id + '/sign')
      .needAuth()
      .formData(formData)
      .contentFormData()
      .postUpload();
  }
}
