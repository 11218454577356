import { useHotkeys } from 'react-hotkeys-hook';

import { ServicesConstant } from '@meilleursbiens/constants';
import { useLocalStorage } from '@meilleursbiens/hooks';

import {
  Box,
  Button,
  FormControl,
  FormLabel,
  HStack,
  Input,
  InputGroup,
  InputLeftAddon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Switch,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { BeakerIcon } from '@heroicons/react/24/solid';

export default function DemoButtonComponent() {
  const [apiUrl, setApiUrl] = useLocalStorage('mb_api_url', ServicesConstant.MEILLEURSBIENS.API_URL_LIVE);

  const { isOpen: isAPIModalOpen, onOpen: onAPIModalOpen, onClose: onAPIModalClose } = useDisclosure();

  useHotkeys(
    'alt+d, option+d',
    (e) => {
      e.preventDefault();
      if (isAPIModalOpen) onAPIModalClose();
      else onAPIModalOpen();
    },
    { keyup: false, keydown: true, enabled: true }
  );

  const isDemoMode = apiUrl !== ServicesConstant.MEILLEURSBIENS.API_URL_LIVE;

  const toggleDemoMode = () => {
    if (isDemoMode) setApiUrl(ServicesConstant.MEILLEURSBIENS.API_URL_LIVE);
    else setApiUrl(ServicesConstant.MEILLEURSBIENS.API_URL_DEMO);
  };

  const _saveSettings = () => {
    onAPIModalClose();

    const apiUrlBackup = apiUrl;
    sessionStorage.clear();
    localStorage.clear();

    setTimeout(() => {
      setApiUrl(apiUrlBackup);
      window.location.reload();
    }, 500);
  };

  return (
    <>
      {isDemoMode && (
        <Box className={'mb-dev-api-url-box'} onClick={onAPIModalOpen}>
          <BeakerIcon width={20} fill={'#50dfa9'} />
          <Text fontSize={10} fontWeight={600}>
            Démonstration
          </Text>
        </Box>
      )}
      <Modal isOpen={isAPIModalOpen} onClose={onAPIModalClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Configuration de l'API</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl display="flex" alignItems="center">
              <FormLabel htmlFor="demo-mode" mb="0">
                Activer le mode de démonstration
              </FormLabel>
              <Switch id="demo-mode" isChecked={isDemoMode} onChange={toggleDemoMode} />
            </FormControl>
            <Text color={'muted'} fontSize={12}>
              Vous pouvez modifier l'URL de l'API
            </Text>
            <HStack mt={2} mb={4}>
              <InputGroup>
                <InputLeftAddon
                  children={
                    <Text fontWeight={600} fontSize={10}>
                      Lien de l'API
                    </Text>
                  }
                />
                <Input
                  type={'url'}
                  isRequired
                  placeholder="api.meilleursbiens.com"
                  value={apiUrl}
                  onChange={(e) => setApiUrl(e.target.value)}
                />
              </InputGroup>
              <Button variant={'primary'} onClick={_saveSettings}>
                Valider
              </Button>
            </HStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
