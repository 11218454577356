import { MBProUser } from '@meilleursbiens/types';

import { MBApi } from '../../../index';

export default class MBAuthAPI {
  static getUser = () => {
    return MBApi.url(`/v1/mbpro/auth/account`).needCache(10).needAuth().get<{
      user: MBProUser;
    }>();
  };
}
