import React, { Component } from 'react';

import { NumberInput } from '@meilleursbiens/ui';
import { ToastUtils } from '@meilleursbiens/utils';

import { Button, Divider } from '@chakra-ui/react';

import { Formik } from 'formik';
import * as Yup from 'yup';

import MBProMandatAPI from '../../../api/requests/MBPro/Transactions/MBProMandatAPI';
import FormFileInputComponent from '../Inputs/FormFileInputComponent';

const ValidationSchema = Yup.object().shape({
  mandat: Yup.number().required('Le numéro de mandat est requis !'),
  file: Yup.mixed()
    .required('Veuillez renseigner votre mandat !')
    .test(
      'fileFormat',
      "Ce type de fichier n'est pas accepté, veuillez utiliser les formats jpg, png ou pdf !",
      (value) => value && ['image/jpg', 'image/jpeg', 'image/png', 'application/pdf'].includes(value.type)
    ),
  mandatFile: Yup.mixed()
    .nullable()
    .test(
      'fileFormat',
      "Ce type de fichier n'est pas accepté, veuillez utiliser les formats jpg, png ou pdf !",
      (value) => (value ? ['image/jpg', 'image/jpeg', 'image/png', 'application/pdf'].includes(value.type) : true)
    ),
});

class TransactionRetourMandatFormComponent extends Component {
  state = {
    loading: false,
    success: false,
  };

  render() {
    return (
      <Formik
        initialValues={{
          mandat: 0,
          file: undefined,
          mandatFile: undefined,
        }}
        onSubmit={(values, actions) => {
          actions.setSubmitting(true);
          this.setState({ loading: true });

          MBProMandatAPI.createMandat(values.mandat, values.file, values.mandatFile)
            .then((r) => {
              actions.setSubmitting(false);
              this.setState({ loading: false, success: true });

              setTimeout(() => {
                this.props.onClose();
              }, 4000);
            })
            .catch((e) => {
              actions.setSubmitting(false);
              this.setState({ loading: false });

              ToastUtils.showError(e);
            });
        }}
        validationSchema={ValidationSchema}
        render={({
          values,
          errors,
          status,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            {this.state.success ? (
              <article className="message is-success mt-10 mb-30">
                <div className="message-header">
                  <p style={{ marginTop: 0, color: '#fff' }}>Merci</p>
                  <button className="delete" aria-label="delete" />
                </div>
                <div className="message-body">Votre mandat a été retourné !</div>
              </article>
            ) : (
              <div>
                {status && status.msg && (
                  <article className="message is-danger mt-10 mb-30">
                    <div className="message-header">
                      <p style={{ marginTop: 0, color: '#fff' }}>Erreur</p>
                      <button className="delete" aria-label="delete" />
                    </div>
                    <div className="message-body">{status.msg}</div>
                  </article>
                )}

                <div className="columns is-multiline">
                  <Divider marginBottom={15} />

                  <div className="column is-12 is-12-mobile">
                    <NumberInput
                      label={'Numéro de mandat'}
                      name={'mandat'}
                      placeholder={'3564'}
                      min={0}
                      step={1}
                      errors={errors}
                      values={values}
                      touched={touched}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                    />
                  </div>

                  <div className="column is-12 is-12-mobile">
                    <FormFileInputComponent
                      label={'Mandat'}
                      element={'file'}
                      errors={errors}
                      values={values}
                      touched={touched}
                      onChangeFile={(e) => {
                        setFieldValue('file', e.currentTarget.files[0]);
                      }}
                      isRequired
                    />
                  </div>
                  <div className="column is-12 is-12-mobile">
                    <FormFileInputComponent
                      label={'DPE'}
                      element={'mandatFile'}
                      errors={errors}
                      values={values}
                      touched={touched}
                      onChangeFile={(e) => {
                        setFieldValue('mandatFile', e.currentTarget.files[0]);
                      }}
                    />
                  </div>
                </div>

                <Divider marginBottom={15} />

                <div className="is-flex" style={{ justifyContent: 'space-between' }}>
                  <Button
                    className="mb-20"
                    isLoading={this.state.loading}
                    onClick={() => this.props.onClose()}
                    isDisabled={isSubmitting}
                  >
                    Fermer
                  </Button>

                  <Button
                    type="submit"
                    style={{ background: '#bb2030', color: 'white' }}
                    className="mb-20"
                    isLoading={this.state.loading}
                    isDisabled={isSubmitting}
                  >
                    Envoyer mon mandat
                  </Button>
                </div>
              </div>
            )}
          </form>
        )}
      />
    );
  }
}

export default TransactionRetourMandatFormComponent;
